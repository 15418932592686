import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from 'react-router-dom';
import { Table, Modal } from 'antd';
import {FormattedMessage, useIntl} from 'react-intl';
import { compNames, getFilterValues } from '../../utils';
import {
	fetchAssets,
} from "../../actions/assets";
import { fetchCompanies } from "../../actions/company";
// import { SearchOutlined } from "@ant-design/icons";

const InspectionReportAsset = ({isModalVisible, handleCancel}) => {
    const history = useHistory();
    const Intl=useIntl();
	const [assets, setAssets] = useState([]);
	// const [companies, setCompanies] = useState([]);

	const [pagination, setPagination] = useState({
		pageSize: 10,
        total:0,
        showSizeChanger: true,
		locale:{ items_per_page: `${Intl.formatMessage({ id: 'page' })}` }, 
        showTotal: (total, range) => `${range[0]}-${range[1]} ${Intl.formatMessage({ id: 'of' })} ${total} ${Intl.formatMessage({ id: 'items' })}`,
        onShowSizeChange: (page, pageSize) => {
            setPagination({
                ...pagination,
                pageSize: pageSize
            })
        }
	});
	const dispatch = useDispatch();

	useEffect(() => {
		async function getAssets() {
			const response = await dispatch(fetchAssets({limit: 1000}));
			const assetData = Object.values(response?.payload?.entities?.assets  || []);
			const companyRes = await dispatch(fetchCompanies({limit: 1000}));
			const companyData = Object.values(
				companyRes.payload.entities.companies
			);
            
			// setCompanies(companyData);
			mapCompanyIdNames(assetData, companyData);
		}
		getAssets();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const mapCompanyIdNames = (assetData, companyData) => {
		// eslint-disable-next-line array-callback-return
		assetData.map((asset) => {
			const assetCompany = companyData.find(
				(company) => company.company_id === asset.company_id
			);
			asset.company_name = assetCompany?.company_name;
			asset.organization_name = assetCompany?.organization_name;
		});
       
		setAssets(assetData);
        setPagination({
            ...pagination,
            total: assetData.length,
        })
	};

	const columns = [
        {
			dataIndex: "organization_name",
			title:<FormattedMessage id="organization"/>,
            key: "organization_name",
			filters: getFilterValues("organization_name", assets),
			// filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
			onFilter: (value, record) => record?.organization_name?.indexOf(value) === 0,
			sorter: {
				compare: (a, b) => {
				return compNames(a.organization_name, b.organization_name);
				},
			},
		},
        {
			dataIndex: "company_name",
			title:<FormattedMessage id="company"/>,
            key: "company_name",
			filters: getFilterValues("company_name", assets),
			// filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
			onFilter: (value, record) => record?.company_name?.indexOf(value) === 0,
			sorter: {
				compare: (a, b) => {
				return compNames(a.company_name, b.company_name);
				},
			},
		},
		{
			dataIndex: "asset_name",
			title:<><FormattedMessage id="asset"/> <FormattedMessage id="Name"/></>,
            key: "asset_name",
			filters: getFilterValues("asset_name", assets),
			// filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
			onFilter: (value, record) => record?.asset_name?.indexOf(value) === 0,
			sorter: {
				compare: (a, b) => {
				return compNames(a.asset_name, b.asset_name);
				},
			},
		},
		{
			dataIndex: "asset_id",
			title:<FormattedMessage id="select"/>,
            key: "asset_id",
			width: 100,
			render: (value, tableMeta, updateValue) => (
				<div
					className="btn-primary"
					onClick={() =>
						addReportToAssset(value)
					}
				>
					<FormattedMessage id="select"/>
				</div>
			),
		},
	];

	const addReportToAssset = (asset_id) => {
		history.push(`/inspection-report/add/${asset_id}`)
	};

	return (
		<Modal title={Intl.formatMessage({id:"Select an Asset to create Inspection Report"})} width={'80vw'} open={isModalVisible} centered footer={null} onCancel={handleCancel}>
			<Table
				className="ant-table-root"
				// scroll={{ x: 0, y: '40vh' }}
				columns={columns}
				dataSource={assets}
				pagination={pagination}
				bordered
				rowKey='asset_id'
				locale={{emptyText: Intl.formatMessage({ id:'no_data'})}}
            />
		</Modal>
	);
};

InspectionReportAsset.propTypes = {
    isModalVisible: PropTypes.bool,
    handleCancel: PropTypes.func,
  };

export default InspectionReportAsset;