import { createBrowserHistory } from 'history'
import { 
    createStore, 
    applyMiddleware, 
    // combineReducers, 
    compose 
} from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import thunk from 'redux-thunk';
// import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './rootReducer'
import { apiAuthorizationMiddleware } from '../middleware/apiAuthorization';
// import checkSession from '../middleware/checkSession';

const history = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const getHistory = () =>history;

const middleware = [
    apiAuthorizationMiddleware,
    apiMiddleware,
    thunk,
   // checkSession,
];

const store = (preloadedState = {}) =>
    createStore(
        rootReducer(history),
        preloadedState,
        composeEnhancers(applyMiddleware(...middleware)),);

export default store;
        
        
        
        
        
        
        

        


        