import React, { useState } from "react";
import axios from "axios";
import { message } from "antd";
import { FormattedMessage } from "react-intl";

import { domainUrl } from "../../../helper";
import "../../Tender/AdditionalInfo/AdditionalInfo.css";
import "../../Tender/EnterTenderDetails/EnterTenderDetails.less";
import { genHeaders, useTender } from "../Tender";

import TextEditor from '../../TextEditor';

const EnterTenderDetails = () => {
  const { tenderDetails, onStepChange: move } = useTender();

  const [title, setTitle] = useState(tenderDetails?.title ?? "");
  const [description, setDescription] = useState(
    tenderDetails?.description ?? ""
  );
  const [visibility, setVisibility] = useState(
    tenderDetails?.visibility ?? false
  );
  const [error, setError] = useState(false);

  const onBack = () => {
    move(-1, { title, description, visibility });
  };

  const onNext = () => {
    if (!title?.trim()) {
      setError(true);
      return;
    }

    axios
      .put(
        `${domainUrl}/tender/v1/tender/${tenderDetails.tender_id}`,
        {
          title,
          description,
          is_visible_to_all: !visibility,
        },
        genHeaders()
      )
      .then((r) => {
        move(1, { title, description, visibility });
      })
      .catch((e) => {
        console.error(e);
        message.error(e?.message ?? "Unable to reach server.");
      });
  };

  const saveDescription = (data) => {
    setDescription(data);
  }

  return (
    <div className="__TenderCreate_TenderDetails__">
      <div className="hedding">
        <h2> <FormattedMessage id='Enter Tender Details' /></h2>
      </div>
      <div className="formGroup">
        <label className="leftCol"> <FormattedMessage id='title'/></label>
        <input
          value={title}
          onChange={({ target }) => {
            setError(false);
            setTitle(target.value);
          }}
          type="text"
          className="formFiled"
        />
      </div>
      <div className="formGroup">
        <label className="leftCol">  <FormattedMessage id='desc'/>  </label>
        <TextEditor textData={description} onChangeData={saveDescription}/>
        
      </div>
      <div className="formGroup">
        <label className="leftCol"></label>
        <div className="visibility">
          <input
            type="checkbox"
            id="visibility"
            checked={visibility}
            onChange={(e) => setVisibility(e.target.checked)}
            title="Only visible to me"
          />
          <label htmlFor="visibility"><FormattedMessage id="Only visible to me"/></label>
        </div>
      </div>
      {error && (
        <p style={{ color: "red", fontWeight: "bold", textAlign: "center" }}>
          <FormattedMessage id="Please set a title for the tender."/>
        </p>
      )}
      <div className="buttonBlock">
        <div onClick={onBack} className="btn cancelBtn">
          <FormattedMessage id='bakc'/> </div>
        <div onClick={onNext} className="btn">
          <FormattedMessage id='next'/> </div>
      </div>
    </div>
  );
};

// Tender.propTypes = {};

export default EnterTenderDetails;
