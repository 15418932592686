/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";

import Protected from '../Protected';
import NotAuthorized from '../../pages/notAuthorized';

const PrivateRoute = ({role, component: Component, ...rest }) => {
  // console.log(sessionStorage,"======private route")
  const isAuthenticated = (sessionStorage.getItem("token") || localStorage.getItem('token')) ? true : false;
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Protected roles={role?.toString()} fallback={NotAuthorized}>
            <Component {...props} />
          </Protected>
        ) : (
          <Redirect
            to={{ pathname: "/login"/*, state: { from: props.location }*/ }}
          />
        )
      }
    />
  );
};
export default PrivateRoute;
