/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { Form, Input, message } from "antd";
import axios from "axios";
import { domainUrl } from "../../../helper";
import { genHeaders, useTender } from "../Tender";
import { FormattedMessage } from "react-intl";
import "../../Tender/AdditionalInfo/AdditionalInfo.css";
import "../../Tender/EnterAssetDetails/EnterAssetDetails.css";
import { MinusCircleOutlined } from "@ant-design/icons";

//TODO : Rewrite component : Instead of one state, use 2 to handle assets and items separately. will become easier to understand then

const EnterAssetDetails = () => {
  const { tenderDetails, onStepChange: move } = useTender();

  const [form] = Form.useForm();
  const [assets, setAssets] = useState([{ key: "asset1", items: [] }]
  );
  const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
     let assetAdded = tenderDetails?.assetsFormTemplate ?? [{ key: "asset1", items: [] }];
     assetAdded?.map(asset => {
       asset.items = !asset.items ? [] : asset.items;
     })
     setAssets(assetAdded)
   },[]);

  const onAddAsset = () => {
    let newAssets = [];
      for (let asset of assets) {
        let key = asset.key;
        let items = asset.items.slice();
        newAssets.push({ key, items });
      }

      const length = assets.length;
      const incrementer =
        length === 0 ? 1 : +assets[length - 1]?.key?.split("asset")[1] + 1;
      newAssets.push({ key: `asset${incrementer}`, items: [] });
    setAssets(newAssets);
  };

  const onRemoveAsset = (index) => {
    setAssets((curr) => curr.filter((_, i) => i !== index));
  };

  const onAddItem = (assetIndex) => {
    let element =  document.getElementById(assets[assetIndex]?.key);
    if(element && element.value === '') {
      message.error("Please enter asset name before adding a item");
        return;
    }
    setAssets((curr) => {
      return curr.map((el, index) => {
        if (index === assetIndex) {
          return {
            ...el,
            items: [...el.items, `${el.key}_item${el.items.length + 1}`],
          };
        }

        return { ...el, items: [...el.items] };
      });
    });
  };

  const onRemoveItem = (itemIndex, assetIndex) => {
    setAssets((curr) =>
      curr.map((el, index) => {
        if (index === assetIndex) {
          return {
            ...el,
            items: el.items.filter((_, i) => i !== itemIndex),
          };
        }

        return { ...el, items: [...el.items] };
      })
    );
  };

  const onBack = () => {
    move(-1, { assetsFormTemplate: assets });
  };

  const onNext = () => {
    let inputValues = assets.map(asset => {return document.getElementById(asset.key)})
    const undefinedAssets = inputValues?.length && inputValues.filter(asset => asset.value === '');

      if (assets.length === 0 || undefinedAssets?.length === assets.length) {
        message.error("Please add an asset to the tender.");
        return;
      }
    form.submit();
  };

  const onFinish = (e) => {
    // console.log(e);

    const keys = Object.keys(e);
    const assets_local = [];
    const items = [];
    let hasAPIFailed = false;
    setIsLoading(true);

    //extract all asset keys;
    for (let key of keys) {
      let asset = key.split("_")[0];

      if (!assets_local.includes(asset)) {
        assets_local.push(asset);
      }
    }

    //map each item to asset
    for (let asset of assets_local) {
      let itemsForAsset = [];
      for (let key of keys) {
        if (
          key.includes(asset) &&
          key.split("_")[1] &&
          !itemsForAsset.includes(key)
        ) {
          itemsForAsset.push(key);
        }
      }
      items.push(itemsForAsset);
    }
    
    try {
      deleteOldAssets();
      if (assets_local?.length) {
        const createAssetCalls = assets_local.map((asset) =>
          axios
            .post(
              `${domainUrl}/tender/v1/tender/${tenderDetails?.tender_id}/asset`,
              {
                line_text: e[asset],
              },
              genHeaders()
            )
            .catch((e) => {
              console.error(e);
              hasAPIFailed = true;
              message.error(`Unable to create asset ${e[asset]}`, 5);
            })
        );

        (async () => {
          const res1 = await Promise.all(createAssetCalls);
          const ids = res1.map((el) => el?.data?.data?.tender_line_id);
          const createItemCalls = [];
          for (let item in items) {
            createItemCalls.push(
              items[item].map((el) =>
                axios.post(
                  `${domainUrl}/tender/v2/tender/asset/${ids[item]}/items`,
                  {
                    item_text: e[el],
                  },
                  genHeaders()
                )
              )
            );
          }
          await Promise.all(createItemCalls).catch((e) => {
            console.error("unable to create item");
            message.error("Unable to create items");
            hasAPIFailed = true;
            console.error(e);
          });
          setIsLoading(false);
        })();
      }
      if (!hasAPIFailed) {
        move(1, { assetsFormTemplate: assets, assetsFormValues: e });
      }
    } catch (e) {
      message.error("Unable to create asset(s).");
      console.error(e);
      setIsLoading(false);
    }
  };

  const deleteOldAssets = async () => {
    const getAllExistingAssets = await axios.get(
      `${domainUrl}/tender/v1/tender/${tenderDetails?.tender_id}/asset`,
      genHeaders()
    );
    await Promise.all(
      getAllExistingAssets.data.data.assets.map((asset) =>
        axios
          .delete(
            `${domainUrl}/tender/v1/tender/asset/${asset.tender_line_id}`,
            genHeaders()
          )
          .catch((e) => {
            if (e?.response?.status === 404) {
            }
          })
      )
    );
  };

  const itemsMapper = (item, itemIndex, assetIndex) => (
    <div key={itemIndex} style={{ paddingLeft: "30px" }} className="formGroup">
      <label className="leftCol">Item name</label>
      <Form.Item name={item}>
        <Input type="text" className="formFiled" />
      </Form.Item>
      <MinusCircleOutlined
        onClick={() => onRemoveItem(itemIndex, assetIndex)}
      />
    </div>
  );

  return (
    <div className="__AssetDetails__">
      <div className="parentBlock2">
        <div className="hedding">
          <h2>  <FormattedMessage id={'asset_details'} /></h2>
        </div>
        <Form
          form={form}
          initialValues={{ ...(tenderDetails?.assetsFormValues ?? {}) }}
          onFinish={onFinish}
        >
          {assets?.map((el, assetIndex) => {
            return<div key={assetIndex} style={{ width: "100%" }}>
              <div className="formGroup">
                <label className="leftCol">  <FormattedMessage id={'enter_asset'} /></label>
                <Form.Item name={el.key}>
                  <Input type="text" className="formFiled" />
                </Form.Item>
                <MinusCircleOutlined
                  onClick={() => onRemoveAsset(assetIndex)}
                />
              </div>
              {el?.items?.map((item, itemIndex) =>
                itemsMapper(item, itemIndex, assetIndex)
              )}
              <div className="formGroup">
                <span onClick={() => onAddItem(assetIndex)} className="addItems"> <FormattedMessage id={'add_item'} /></span>
              </div>
            </div>
          }
          )}
          <div className="formGroup">
            <label className="leftCol"></label>
            <div  onClick={onAddAsset} className="addAssets">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="64.413"
                height="64.413"
                viewBox="0 0 64.413 64.413"
              >
                <g
                  id="Icon_material-add-circle-outline"
                  data-name="Icon material-add-circle-outline"
                  transform="translate(0 0)"
                >
                  <path
                    id="Icon_material-add-circle-outline-2"
                    data-name="Icon material-add-circle-outline"
                    d="M38.427,19.1H31.986V31.986H19.1v6.441H31.986V51.309h6.441V38.427H51.31V31.986H38.427ZM35.207,3A32.206,32.206,0,1,0,67.413,35.206,32.218,32.218,0,0,0,35.207,3Zm0,57.971A25.765,25.765,0,1,1,60.972,35.206,25.8,25.8,0,0,1,35.207,60.971Z"
                    transform="translate(-3 -3)"
                    fill="#bfc5e2"
                  />
                </g>
              </svg>
              <span><FormattedMessage id={'add_asset'} /></span>
            </div>
          </div>
        </Form>
        <div className="buttonBlock">
          <div onClick={isLoading ? null : onBack} className="btn cancelBtn">
          <FormattedMessage id={'back'} />
          </div>
          <div onClick={isLoading ? null : onNext} className="btn">
          <FormattedMessage id={'next'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterAssetDetails;
