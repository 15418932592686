import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
import Header from "../Header";
import Setting from "../Setting";
import SettingHeadingLabel from "../Common/SettingHeadingLabel";
import axios from "axios";
import AddModal from "./AddModal";
// import { useConfirm } from "material-ui-confirm";
// import { apiUrl,domainUrl3,domainUrl } from "../../helper";
import { domainUrl } from "../../helper";
import "./ConsultancyPrices.css";
import { 
  FormattedMessage, 
  // useIntl 
} from "react-intl";
const ConsultancyPrices = (props) => {
  // const intl = useIntl();
  // const confirm = useConfirm();
  const [
    openConsultancyPriceFormModel,
    setOpenConsultancyPriceFormModel,
  ] = useState(false);
  const [consultancyPrice, setConsultancyPrice] = useState();
  const [consultancyPrices, setConsultancyPrices] = useState([]);

  useEffect(() => {
    getAllCompanyPrice();
  }, []);

  const getAllCompanyPrice = () => {
    axios
      .post(
        domainUrl + "/master/settings/getAllPlans",
        {},
        {
          headers: {
            auth: sessionStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        let data = res.data.data;
        setConsultancyPrices(data);
      })
      .catch((err) => {
        setConsultancyPrices([])
        
      });
  };

  const openConsultancyPriceForm = (data) => {
    setOpenConsultancyPriceFormModel(true);
    setConsultancyPrice(data);
  };

  const closeConsultancyPriceForm = () => {
    setOpenConsultancyPriceFormModel(false);
    setConsultancyPrice("");
  };

  const addConsultancyPrice = (data) => {
    axios
      .post(domainUrl + "/master/settings/insertUpdatePlans", data, {
        headers: {
          auth: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        getAllCompanyPrice();
        closeConsultancyPriceForm();
      })
      .catch((err) => {
        
      });
  };

  const deleteConsultancyPrice = (id) => {
    let data = {
      planId: id,
    };
    axios
      .post(domainUrl + "/master/settings/deletePlan", data, {
        headers: {
          auth: sessionStorage.getItem("token"),
        },
      })
      .then((res) => {
        getAllCompanyPrice();
      })
      .catch((err) => {
        
      });
  };
  return (
    <>
      <Header />
      <div className="settingOuter">
        <Setting page="ConsultancyPrices" />
        <div className="tabContainer">
          <h2><FormattedMessage id="consultancy_price"/></h2>
          <div className="tabContentBlock">
            <div className="contentBlock">
              <div className="titleBlock">
                <SettingHeadingLabel
                  className="title1 width-50"
                  label={<FormattedMessage id="plan_name"/>}
                />
                <SettingHeadingLabel
                  className="title1 text-center width-20"
                  label={<FormattedMessage id="price"/>}
                />
                <SettingHeadingLabel
                  className="title1 view01 width-10"
                  label={<FormattedMessage id="view"/>}
                />
                <SettingHeadingLabel
                  className="title2 width-10"
                  label={<FormattedMessage id="edit"/>}
                />
                <SettingHeadingLabel
                  className="title3 width-10"
                  label={<FormattedMessage id="delete"/>}
                />
              </div>
              <ul className="listing">
                
                {consultancyPrices.length && consultancyPrices?.map((data) => (
                  <li key={data.plan_Id}>
                    <div className="width-50">{data.plan_name}</div>
                    <div className="width-20">{data.price} €</div>
                    <div className="width-10">
                      <div className="viewIcon"><FormattedMessage id="view"/></div>
                    </div>
                    <div className="width-10">
                      <div
                        onClick={() => openConsultancyPriceForm(data)}
                        className="editIcon"
                      >
                        Edit
                      </div>
                    </div>
                    <div className="width-10">
                      <div
                        onClick={() => deleteConsultancyPrice(data.plan_Id)}
                        className="deleteIcon"
                      >
                      <FormattedMessage id="delete"/>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="button1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29.701"
                  height="29.701"
                  viewBox="0 0 29.701 29.701"
                >
                  <path
                    fill="#fff"
                    d="M15.85 1A14.85 14.85 0 1 0 30.7 15.85 14.852 14.852 0 0 0 15.85 1zm6.75 16.2h-5.4v5.4h-2.7v-5.4H9.1v-2.7h5.4V9.1h2.7v5.4h5.4z"
                    transform="translate(-1 -1)"
                  />
                </svg>
                <span onClick={() => openConsultancyPriceForm()}><FormattedMessage id="addnew"/></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openConsultancyPriceFormModel && (
        <AddModal
          data={consultancyPrice}
          onClose={closeConsultancyPriceForm}
          onSubmit={addConsultancyPrice}
        />
      )}
    </>
  );
};

ConsultancyPrices.propTypes = {};

export default ConsultancyPrices;
