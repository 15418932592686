import { schema } from "normalizr";
import { CUSTOM_AUTHORIZED_REQUEST } from '../middleware/apiAuthorization';
import {
	handleResponse,
	handleError,
} from "../utils";
import apiUrls  from "../utils/apiUrls";

export const FETCH_TENDER_ASSET_REQUEST = "FETCH_TENDER_ASSET_REQUEST";
export const FETCH_TENDER_ASSET_SUCCESS = "FETCH_TENDER_ASSET_SUCCESS";
export const FETCH_TENDER_ASSET_FAILURE = "FETCH_TENDER_ASSET_FAILURE";

export const CREATE_TENDER_ASSET_REQUEST = "CREATE_TENDER_ASSET_REQUEST";
export const CREATE_TENDER_ASSET_SUCCESS = "CREATE_TENDER_ASSET_SUCCESS";
export const CREATE_TENDER_ASSET_FAILURE = "CREATE_TENDER_ASSET_FAILURE";

export const UPDATE_TENDER_ASSET_REQUEST = "UPDATE_TENDER_ASSET_REQUEST";
export const UPDATE_TENDER_ASSET_SUCCESS = "UPDATE_TENDER_ASSET_SUCCESS";
export const UPDATE_TENDER_ASSET_FAILURE = "UPDATE_TENDER_ASSET_FAILURE";

export const DELETE_TENDER_ASSET_REQUEST = "DELETE_TENDER_ASSET_REQUEST";
export const DELETE_TENDER_ASSET_SUCCESS = "DELETE_TENDER_ASSET_SUCCESS";
export const DELETE_TENDER_ASSET_FAILURE = "DELETE_TENDER_ASSET_FAILURE";

export const CREATE_TENDER_ASSETITEM_REQUEST = "CREATE_TENDER_ASSETITEM_REQUEST";
export const CREATE_TENDER_ASSETITEM_SUCCESS = "CREATE_TENDER_ASSETITEM_SUCCESS";
export const CREATE_TENDER_ASSETITEM_FAILURE = "CREATE_TENDER_ASSETITEM_FAILURE";

export const UPDATE_TENDER_ASSETITEM_REQUEST = "UPDATE_TENDER_ASSETITEM_REQUEST";
export const UPDATE_TENDER_ASSETITEM_SUCCESS = "UPDATE_TENDER_ASSETITEM_SUCCESS";
export const UPDATE_TENDER_ASSETITEM_FAILURE = "UPDATE_TENDER_ASSETITEM_FAILURE";

export const DELETE_TENDER_ASSETITEM_REQUEST = "DELETE_TENDER_ASSETITEM_REQUEST";
export const DELETE_TENDER_ASSETITEM_SUCCESS = "DELETE_TENDER_ASSETITEM_SUCCESS";
export const DELETE_TENDER_ASSETITEM_FAILURE = "DELETE_TENDER_ASSETITEM_FAILURE";

const assetSchema = new schema.Entity("tenderAssets", {}, {
	idAttribute: "tender_line_id",
});

const tenderAssetSchema = new schema.Entity(
	'tenderAssets',
	{ assets: assetSchema},
	{
		idAttribute: 'tender_id',

		mergeStrategy: (entityA, entityB) => ({
			...entityA,
      		...entityB,
		}),
	}
);

/**
 * Returns a redux api middleware action that makes a request to fetch assets in a tender.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const fetchTenderAsset = (tender_id) => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: apiUrls.tenderAssetRequest(tender_id),
			method: "GET",
			types: [
				{ type: FETCH_TENDER_ASSET_REQUEST },
				{
					type: FETCH_TENDER_ASSET_SUCCESS,
					payload: (action, state, res) =>
						handleResponse([assetSchema], action, state, res),
				},
				{ type: FETCH_TENDER_ASSET_FAILURE, payload: handleError },
			],
		}
	}
}

/**
 * Returns a redux api middleware action that makes a request to create assets for a tender.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const createTenderAsset = (data, tender_id) => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: apiUrls.tenderAssetRequest(tender_id),
			method: "POST",
            body: JSON.stringify(data),
			types: [
				{ type: CREATE_TENDER_ASSET_REQUEST },
				{
					type: CREATE_TENDER_ASSET_SUCCESS,
					payload: (action, state, res) =>
						handleResponse([assetSchema], action, state, res),
				},
				{ type: CREATE_TENDER_ASSET_FAILURE, payload: handleError },
			],
		}
	}
}

/**
 * Returns a redux api middleware action that makes a request to edit assets for a tender.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const updateTenderAsset = (data, tender_id) => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: apiUrls.tenderAssetByTenderId(tender_id),
			method: "PATCH",
            body: JSON.stringify(data),
			types: [
				{ type: UPDATE_TENDER_ASSET_REQUEST },
				{
					type: UPDATE_TENDER_ASSET_SUCCESS,
					payload: (action, state, res) =>
						handleResponse([assetSchema], action, state, res),
				},
				{ type: UPDATE_TENDER_ASSET_FAILURE, payload: handleError },
			],
		}
	}
}

/**
 * Returns a redux api middleware action that makes a request to delete assets for a tender.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const deleteTenderAsset = (tender_id) => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: apiUrls.tenderAssetByTenderId(tender_id),
			method: "DELETE",
			types: [
				{ type: DELETE_TENDER_ASSET_REQUEST },
				{
					type: DELETE_TENDER_ASSET_SUCCESS,
					payload: (action, state, res) =>
						handleResponse([assetSchema], action, state, res),
				},
				{ type: DELETE_TENDER_ASSET_FAILURE, payload: handleError },
			],
		}
	}
}

/**
 * Returns a redux api middleware action that makes a request to create assets items for a tender asset.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const createTenderAssetItem = (data, tender_line_item_id) => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: apiUrls.tenderAssetItemBulkRequest(tender_line_item_id),
			method: "POST",
            body: JSON.stringify(data),
			types: [
				{ type: CREATE_TENDER_ASSETITEM_REQUEST },
				{
					type: CREATE_TENDER_ASSETITEM_SUCCESS,
					payload: (action, state, res) =>
						handleResponse([assetSchema], action, state, res),
				},
				{ type: CREATE_TENDER_ASSETITEM_FAILURE, payload: handleError },
			],
		}
	}
}

/**
 * Returns a redux api middleware action that makes a request to edit assets items for a tender asset.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const editTenderAssetItem = (data, tender_line_item_id) => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: apiUrls.tenderAssetItemByLineId(tender_line_item_id),
			method: "PATCH",
            body: JSON.stringify(data),
			types: [
				{ type: UPDATE_TENDER_ASSETITEM_REQUEST },
				{
					type: UPDATE_TENDER_ASSETITEM_SUCCESS,
					payload: (action, state, res) =>
						handleResponse([assetSchema], action, state, res),
				},
				{ type: UPDATE_TENDER_ASSETITEM_FAILURE, payload: handleError },
			],
		}
	}
}

/**
 * Returns a redux api middleware action that makes a request to delete asset items for a tender asset.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const deleteTenderAssetItem = (tender_line_item_id) => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: apiUrls.tenderAssetItemByLineId(tender_line_item_id),
			method: "DELETE",
			types: [
				{ type: DELETE_TENDER_ASSETITEM_REQUEST },
				{
					type: DELETE_TENDER_ASSETITEM_SUCCESS,
					payload: (action, state, res) =>
						handleResponse([tenderAssetSchema], action, state, res),
				},
				{ type: DELETE_TENDER_ASSETITEM_FAILURE, payload: handleError },
			],
		}
	}
}