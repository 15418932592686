/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./ConfirmTender.less";
import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useTender } from "../TenderCreationSteps";
// import { submitPreTender } from "../../../actions/pre-tender";
// import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { FormattedMessage } from "react-intl";

const ConfirmTender = ({updateTenderData}) => {
  // const dispatch = useDispatch();
  const history = useHistory();
  const { 
    tenderDetails, 
    // onStepChange: move, 
    setConfirmTenderPage 
  } = useTender();
  const [error, setError] = useState(tenderDetails.is_published === 0);
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    setConfirmTenderPage(true);
  }, [])

  let content;
  if (isLoading) {
    content = <LoadingOutlined style={{ fontSize: "100px" }} />;
  } else {
    if (error) {
      content = (
        <>
          <CloseOutlined style={{ color: "red", fontSize: "100px" }} />
          <h2><FormattedMessage id="Unable to publish tender."/></h2>
        </>
      );
    } else {
      content = (
        <>
          <CheckOutlined style={{ color: "green", fontSize: "100px" }} />
          <h2><FormattedMessage id="Tender has been published successfully."/></h2>
          <div className="btn" onClick={() => history.push('/')}>
          <FormattedMessage id="go_to_home_page"/>
          </div>
        </>
      );
    }
  }
  return (
    <div className="__ConfirmTender__">
      <div className="parentBlock2">
        <div className="confirm">{content}</div>
      </div>
    </div>
  );
};

export default ConfirmTender;
