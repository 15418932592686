import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { message, Spin } from "antd";
import get from "lodash/get";
import { useSelector } from "react-redux";

import "../AdditionalInfo/AdditionalInfo.css";
import "./ChooseTenderType.css";
import { fetchTenderTypes } from "../../../actions/tender";

import { useTender } from "../TenderCreationSteps";
import { useHistory } from "react-router";
import ToolTip from "../../ToolTip";
import { FormattedMessage, useIntl } from "react-intl";

const ChooseTenderType = ({ createTenderData, updateTenderData }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  let org = useSelector((state) =>
    get(state, `entities.users.${sessionStorage.getItem("user_id")}.entities`)
  );

  let role_id = useSelector((state) =>
    get(
      state,
      `entities.users.${sessionStorage.getItem("user_id")}.roles.role_id`
    )
  );

  const history = useHistory();
  const {
    tenderDetails,
    onStepChange: move,
    modifyData,
    tenderModifiedDetails,
  } = useTender();

  const [tenderTypes, setTenderTypes] = useState([]);
  // const [companyTypes, setCompanyTypes] = useState([]);
  const [selectedTenderType, setSelectedTenderType] = useState(
    tenderModifiedDetails?.tender_type || tenderDetails?.tender_type
  );

  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false); // Loading State to handle Click Disable
  const [otherTender, setOtherTender] = useState(
    tenderModifiedDetails?.tender_type_other || tenderDetails?.tender_type_other
  );
  useEffect(() => {
    const getFieldInfo = async () => {
      const typeResponse = await dispatch(fetchTenderTypes());
      setTenderTypes(Object.values(typeResponse?.payload?.entities?.tenderTypes || []));
    }
    getFieldInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBack = () => {
    move(-1);
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    if (!selectedTenderType) {
      setErrorMessage(<FormattedMessage id="select_tender_type" />);
      return;
    } else if (
      selectedTenderType === "o" &&
      (!otherTender || otherTender === "")
    ) {
      setErrorMessage(<FormattedMessage id="enter_tender_type" />);
      return;
    } else {
      setErrorMessage(null);
    }

    setLoading(true); // Added to Handle Next Click Disable

    try {
      (async () => {
        if (tenderDetails.is_published !== 1) {
          if (tenderDetails?.tender_id === undefined) {
            let dataToPost = {
              tender_type: selectedTenderType,
              tender_type_other: otherTender || "",
              state: "1",
            };
            if (role_id === 2) {
              dataToPost.organization_id = org[0].entity_id;
            }
            const tender_id = await createTenderData(dataToPost);
            if (tender_id) {
              history.push(`tender/${tender_id}`);
              move(1, {
                tender_type: selectedTenderType,
                tender_type_other: otherTender || "",
                tender_id: tender_id,
                state: "1",
              });
            } else {
              message.error("Unable to reach server.");
            }
          } else {
            //update existing tender's company_type & tender_type instead of creating a new one, by using the tender_id
            const status = await updateTenderData({
              tender_type: selectedTenderType,
              tender_type_other: otherTender || "",
              state: "1",
            });

            if (status) {
              move(1, {
                tender_type: selectedTenderType,
                tender_type_other: otherTender || "",
              });
            } else {
              message.error(e?.message ?? "Unable to reach server.");
            }
          }
        } else {
          move(
            1,
            {
              tender_type: selectedTenderType,
              tender_type_other: otherTender || "",
            },
            true
          );
        }
      })();
    } catch (e) {
      message.error(e?.message ?? "An error occurred. Please try again.");
    } finally {
      setLoading(false); // Reset loading state after API call completes
    }
  };

  const publishData = () => {
    move(
      0,
      {
        tender_type: selectedTenderType,
        tender_type_other: otherTender || "",
      },
      true
    );
    modifyData();
  };

  return (
    <div>
      <div className="parentBlock2 parentBlock3">
        <div className="hedding">
          <h2>
            <FormattedMessage id="tender_type" />{" "}
            <ToolTip text_id="tender.choose_tender_type.info" placement="top" />
          </h2>
        </div>

        <form onSubmit={onFormSubmit}>
          <div className="formGroup">
            {tenderTypes?.map((el, index) => { return el.tender_type_value !== 'm' && (
              <div key={index} className="radioBtn">
                <input
                  type="radio"
                  checked={el.tender_type_value === selectedTenderType}
                  onChange={() => {setSelectedTenderType(el.tender_type_value); setErrorMessage('')}}
                  id={el.tender_type_label}
                  name="radio-group"
                />
                <label htmlFor={el.tender_type_label}>
                  {el?.tender_type_label === "repair" ? <FormattedMessage id="repair" /> : el?.tender_type_label === "others" ? <FormattedMessage id="others" /> : el?.tender_type_label}
                </label>
              </div>
            )})}
          </div>
          {selectedTenderType === "o" && (
            <div className="formGroup">
              <label className="leftCol"></label>
              <input
                value={otherTender}
                onChange={({ target }) => {
                  setOtherTender(target.value);
                }}
                type="text"
                className="formFiled"
              />
            </div>
          )}

          {errorMessage && (
            <p
              style={{ color: "red", fontWeight: "bold", textAlign: "center" }}
            >
              {errorMessage}
            </p>
          )}

          <div className="buttonBlock">
            <div className="btn cancelBtn" onClick={onBack}>
              <FormattedMessage id="back" />
            </div>
            {tenderDetails?.is_published === 1 && (
              <div className="btn" onClick={publishData}>
                <FormattedMessage id="publish" />
              </div>
            )}
            <div style={{ position: "relative", display: "inline-block" }}>
              <input
                type="submit"
                value={
                  loading
                    ? intl.formatMessage({ id: " " })
                    : intl.formatMessage({ id: "next" })
                }
                className="btn"
                disabled={loading} // Disable Button on Click
                style={{ border: "none" }}
              />
              {loading && (
                <Spin
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                />
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

// Tender.propTypes = {};

export default ChooseTenderType;
