/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { message } from "antd";
import axios from "axios";

import { domainUrl } from "../../../helper";
import "./ChooseSupplier.less";
import { genHeaders, useTender } from "../Tender";
import { MinusCircleOutlined } from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";

const ChooseSupplier = () => {
  const { tenderDetails, onStepChange: move } = useTender();

  const [suppliers, setSuppliers] = useState([]);
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [emails, setEmails] = useState(tenderDetails?.invites ?? []);

  const [isUploading, setIsUploading] = useState(false);

  const [favSelected, setFavSelected] = useState(false);
  const [allSelected, setAllSelected] = useState(false);

  const [serviceTypes, setServiceTypes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const serviceTypeRef = useRef();
  const countryRef = useRef();
  const cityRef = useRef();
  const emailRef = useRef();
  const intl = useIntl();

  const [selectedSuppliers, setSelectedSuppliers] = useState([]);

  useEffect(() => {
    axios
      .get(domainUrl + "/masterservicenew/master/supplier?limit=100", genHeaders())
      .then((r) => {
        const res = r.data.data.result;
        if (res && res.length) {
          setSuppliers(res);
          setFilteredSuppliers(res);

          setAllSelected(res?.length === tenderDetails?.suppliers.length ? true : false);
          setSelectedSuppliers(tenderDetails?.suppliers ?? []);

          const serviceTypeList = new Set();
          const countryList = new Set();
          const cityList = new Set();

          for (let supplier of res) {
            if (supplier.service_types && supplier.service_types.length) {
              for (let type of supplier.service_types) {
                if (type?.service_type_name) {
                  serviceTypeList.add(type.service_type_name);
                }
              }
            }

            if (supplier?.country_name) countryList.add(supplier.country_name);

            if (supplier?.city_name) cityList.add(supplier.city_name);
          }

          setServiceTypes(Array.from(serviceTypeList));
          setCountries(Array.from(countryList));
          setCities(Array.from(cityList));
        }
      })
      .catch((e) => {
        console.error(e);
        message.error(e?.data?.message ?? "Unable to fetch data from server");
      });
  }, []);

  const onFilterChange = () => {
    const s_type = serviceTypeRef.current.value?.toLowerCase() ?? "";
    const ctry = countryRef.current.value?.toLowerCase() ?? "";
    const city = cityRef.current.value?.toLowerCase() ?? "";

    const filtered = suppliers?.filter(
      ({ service_types, country_name, city_name }) => {
        let includesST;

        if (s_type === "") includesST = true;
        else {
          includesST =
            service_types.filter(
              (el) => el?.service_type_name?.toLowerCase() === s_type
            ).length >= 1;
        }

        const includesCtry = country_name?.toLowerCase().includes(ctry);
        const includesCity = city_name?.toLowerCase().includes(city);
        // return true;
       
        return includesST && includesCtry && includesCity;
      }
    );

    setFilteredSuppliers(filtered);
  };

  const onSupplierSelect = ({ target }) => {
    const id = +target.value;
    setSelectedSuppliers((c) => {
      const isPresent = c.indexOf(id) > -1;
      if (!isPresent) {
        const newSuppliers = selectedSuppliers.slice();
        newSuppliers.push(id);
        return newSuppliers;
      } else if (isPresent) {
        return c.filter((el) => el !== id);
      }
    });
  };

  const onSelectAll = () => {
    let supplierIDs;
    supplierIDs = !allSelected ? filteredSuppliers?.map((el) => +el.supplier_id) ?? [] : [];
    setSelectedSuppliers(supplierIDs);

    setAllSelected(!allSelected);
  };

  const onSelectFav = () => {

    const supplierIDs =
      filteredSuppliers
        ?.filter((el) => el.is_favourite === 1)
        ?.map((el) => +el.supplier_id) ?? [];
         if(favSelected) {
      
    }
    
      setSelectedSuppliers(favSelected ? [] : supplierIDs);

    setFavSelected(!favSelected);
  };

  const onEmailAdd = () => {
    const email = emailRef.current.value;
    if (!email || !emailRef.current.validity.valid) {
      message.error(`${intl.formatMessage({ id:'invalid_email'})}`);
      return;
    }
    if (!emails.includes(email)) {
      setIsUploading(true);
      axios
        .post(
          `${domainUrl}/tender/v1/tender/${tenderDetails?.tender_id}/supplier/invite`,
          { supplier_email: email },
          genHeaders()
        )
        .then(() => {
          setEmails([...emails, email]);
        })
        .catch((e) => {
          console.error(e);
          message.error("Unable to send invitation to supplier");
        })
        .finally(() => {
          setIsUploading(false);
        });
    }
    emailRef.current.value = "";
  };

  const onEmailRemove = (email, index) => {
    axios
      .delete(
        `${domainUrl}/tender/v1/tender/${
          tenderDetails?.tender_id
        }/supplier/invite?supplier_email=${encodeURIComponent(email)}`,
        genHeaders()
      )
      .then(() => {
        setEmails((c) => {
          const newEmails = c.filter((_, i) => i !== index);
          return newEmails;
        });
      })
      .catch((e) => {
        message.error("Unable to cancel invitation");
      });
  };

  const onBack = () => {
    move(-1, { suppliers: selectedSuppliers, invites: emails });
  };

  const onNext = async () => {
    setIsUploading(true);

    try {
      const getExistingSuppliersForTender = await axios.get(
        `${domainUrl}/tender/v2/tender/${tenderDetails?.tender_id}/supplier`,
        genHeaders()
      );

      await Promise.all(
        getExistingSuppliersForTender.data.data.suppliers.map((el) =>
          axios.delete(
            `${domainUrl}/tender/v2/tender/${tenderDetails?.tender_id}/supplier/${el.supplier_id}`,
            genHeaders()
          )
        )
      );

      const calls = [
        axios.post(
          `${domainUrl}/tender/v2/tender/${tenderDetails?.tender_id}/suppliers`,
          selectedSuppliers?.map((el) => ({ supplier_id: el })),
          genHeaders()
        ),
        // ...emails?.map((email) =>
        // axios.post(
        // `${domainUrl}/tender/v1/tender/${tenderDetails?.tender_id}/supplier/invite`,
        // { supplier_email: email },
        // genHeaders()
        // )
        // ),
      ];

      await Promise.all(calls);

      setIsUploading(false);
      move(1, { suppliers: selectedSuppliers, invites: emails });
    } catch (e) {
      setIsUploading(false);
      console.error(e);
      message.error("Unable to add suppliers to tender.");
    }
  };

  return (
    <>
      <div className="__TenderCreate_ChooseSupplier__">
        <div className="hedding">
          <h2><FormattedMessage id={'choose_suppliers'}/></h2>
        </div>
        <div className="flexContainer">
          <div>
            <div className="formGroup">
              <h3 className="subheading"><FormattedMessage id={'filters'}/></h3>
            </div>
            <div className="formGroup">
              <label className="leftCol"><FormattedMessage id={'service_type'}/></label>
              <div className="selectDate">
                <div className="costumSelect">
                  <select
                    ref={serviceTypeRef}
                    name="service_type"
                    onChange={onFilterChange}
                    id="service_type"
                    className="inputFiled"
                  >
                    <option value="">{intl.formatMessage({id: 'all'})}</option>
                    {serviceTypes?.map((el, index) => (
                      <option key={`${el}${index}`} value={el}>
                        {el}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="formGroup">
              <label className="leftCol"><FormattedMessage id={'by_country'}/></label>
              <div className="selectDate">
                <div className="costumSelect">
                  <select
                    ref={countryRef}
                    name="country_name"
                    onChange={onFilterChange}
                    id="country_name"
                    className="inputFiled"
                  >
                    <option value="">{intl.formatMessage({id: 'all'})}</option>
                    {countries?.map((el, index) => (
                      <option key={`${el}${index}`} value={el}>
                        {el}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="formGroup">
              <label className="leftCol"><FormattedMessage id={'by_city'}/></label>
              <div className="selectDate">
                <div className="costumSelect">
                  <select
                    ref={cityRef}
                    name="city_name"
                    onChange={onFilterChange}
                    id="city_name"
                    className="inputFiled"
                  >
                    <option value=""><FormattedMessage id="all"/></option>
                    {cities?.map((el, index) => (
                      <option key={`${el}${index}`} value={el}>
                        {el}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="selectionsection">
            <div className="formGroup">
              <h3 className="subheading"><FormattedMessage id="supplier_for_project"/></h3>
            </div>
            <div className="cardBlock">
              <table className="tableGrid" width="100%" border="0">
                <tbody>
                  <tr>
                    <th align="left" valign="top">
                      Name
                    </th>
                    <th align="left" valign="top">
                      Select
                    </th>
                  </tr>
                  {filteredSuppliers?.map((el, index) => (
                    <tr key={`${el.created_at}${el.business_id}${index}`}>
                      <td align="left" valign="top">
                        {el.supplier_name}
                      </td>
                      <td align="left" valign="top">
                        <div className="radioBtn">
                          <input
                            type="checkbox"
                            id={`${el.business_id}${el.supplier_name}${index}`}
                            value={el.supplier_id}
                            checked={
                              selectedSuppliers?.includes(+el.supplier_id)
                                ? "checked"
                                : null
                            }
                            onChange={onSupplierSelect}
                          />
                          <label
                            htmlFor={`${el.business_id}${el.supplier_name}${index}`}
                          ></label>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="selectionbuttons">
                <div className="btn" onClick={onSelectAll}>
                  {!allSelected ? 'Select All' : 'Unselect All'}
                </div>
                <div className="btn" onClick={onSelectFav}>
                  {!favSelected ? 'Select Favourites' : 'Unselect Favourites'}
                </div>
              </div>
            </div>
            <div className="invitationsection">
              <div className="formGroup">
                <h3 className="subheading">Invite more suppliers</h3>
              </div>
              <div className="invite">
                <input
                  ref={emailRef}
                  type="email"
                  placeholder="Enter Email"
                  className="inputFiled"
                />
                <div onClick={isUploading ? null : onEmailAdd} className="btn">
                  Add
                </div>
              </div>
              {emails?.map((email, index) => (
                <div className="invitecontainer" key={`${email}__${index}`}>
                  <p className="invitedsupplier">{email}</p>
                  <MinusCircleOutlined
                    onClick={() =>
                      isUploading ? null : onEmailRemove(email, index)
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="buttonBlock">
          <div onClick={isUploading ? null : onBack} className="btn cancelBtn">
            Back
          </div>
          <div onClick={isUploading ? null : onNext} className="btn">
            Next
          </div>
        </div>
      </div>
    </>
  );
};

// Tender.propTypes = {};

export default ChooseSupplier;
