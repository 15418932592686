import React from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';

// import PropertyManagerDashboard from '../../components/PropertyManagerDashboard/Dashboard';

import AdminDashboard from "../../components/AdminDashboard/AdminDashboardnew";

const Dashboard = (props) => { 
	let role_id = useSelector(state => get(state, `entities.users.${sessionStorage.getItem('user_id')}.roles.role_id`));

	return (
		<>
			{
				role_id === 1 && 
					<AdminDashboard props={props}/>
				}
			{
				role_id === 2 && 
					<AdminDashboard props={props}/>
			}
		</>
	)
};


export default Dashboard;