import moment from 'moment';

export  const changeDateFormat = (date,language= "")=> {
    if(!date){
      return '';
    }
    switch (language) {
      case 'en':
        return moment(date).format("DD-MM-YYYY");
      case 'fi':
        return moment(date).format("DD.MM.YYYY");
      default:
        return moment(date).format("DD-MM-YYYY");
    }
}