import { normalize } from "normalizr";

/**
 * Helper for handling successful API responses
 *
 * @param {normalizr.schema} schema - Normalizr data schema object to use.
 * @param {object} action - Redux action.
 * @param {object} state - Redux app state.
 * @param {Response} res - fetch API response object.
 */
export const handleResponse = async (schema, action, state, res) => {
	const json = await res.json();
	//refactor
	const dataToNormalize = json.data.result || json.data.documents || json.data.assets || json.data.assetCategories || json.data.companies  || json.data.suppliers || json.data.tender_type || json.data.company_type || json.data;
	return normalize(dataToNormalize, schema);
};
/**
 * Helper for handling successful API responses
 *
 * @param {normalizr.schema} schema - Normalizr data schema object to use.
 * @param {object} action - Redux action.
 * @param {object} state - Redux app state.
 * @param {Response} res - fetch API response object.
 */
export const handleResponseNew = async (schema, action, state, res) => {
	const json = await res.json();
	//refactor
	const dataToNormalize = json.data;
	return dataToNormalize;
};

////<----> TO DO: check response and generalize <------->
export const handleTenderResponse = async (schema, action, state, res) => {
	const json = await res.json();
	const dataToNormalize = json.data;
	return normalize(dataToNormalize, schema);
};
/**
 * Helper for handling successful property API responses
 *
 * @param {normalizr.schema} schema - Normalizr data schema object to use.
 * @param {object} action - Redux action.
 * @param {object} state - Redux app state.
 * @param {Response} res - fetch API response object.
 */
export const handlePropertyResponse = async (schema, action, state, res) => {
	const json = await res.json();
	return normalize(json.data.asset_properties, schema);
};

/**
 * Helper for handling successful property API responses
 *
 * @param {normalizr.schema} schema - Normalizr data schema object to use.
 * @param {object} action - Redux action.
 * @param {object} state - Redux app state.
 * @param {Response} res - fetch API response object.
 */
export const handleDataSuccessResponse = async (schema, action, state, res) => {
	const json = await res.json();
	return normalize(json, schema);
};

/**
 * Helper for handling successful property API responses
 *
 * @param {normalizr.schema} schema - Normalizr data schema object to use.
 * @param {object} action - Redux action.
 * @param {object} state - Redux app state.
 * @param {Response} res - fetch API response object.
 */
 export const handleReducerResponse = async (action, state, res) => {
	const json = await res.json();
	return json;
};

/**
 * Helper for handling successful property API responses
 *
 * @param {normalizr.schema} schema - Normalizr data schema object to use.
 * @param {object} action - Redux action.
 * @param {object} state - Redux app state.
 * @param {Response} res - fetch API response object.
 */
 export const handleBlobResponse = async (action, state, res) => {
	const json = await res.blob();
	return json;
};

/**
 * Helper for handling unsuccessful API responses
 *
 * @param {object} action - Redux action.
 * @param {object} state - Redux app state.
 * @param {Response} res - fetch API response object.
 */
export const handleError = async (action, state, res) => {
	let body;
	try {
		body = await res.json();
	} catch (err) {
		body = null;
	}

	return {
		message: res.message,
		body,
	};
};

export const handleQuery = (params) =>{
	var str = "";
    Object.keys(params).forEach(key => {
        if (str !== "") {
            str += "&";
        }
        str += key + "=" + encodeURIComponent(params[key]);
    });
    return str;
}

export const hasRole = (roles,loggedInRole) => {
	return roles.indexOf(parseInt(loggedInRole)) !== -1 ? true : false;
}

export const getRole = (loggedInRole) => {
	return parseInt(loggedInRole) === 1 ? 'admin' : (parseInt(loggedInRole) === 2 ? 'property manager' : 'supplier');
}

export const compNames = (a,b) => {
	var nameA = a.toUpperCase(); // ignore upper and lowercase
	var nameB = b.toUpperCase(); // ignore upper and lowercase
	if (nameA < nameB) {
	return -1;
	}
	if (nameA > nameB) {
	return 1;
	}
}

export const getUniqueValues = (key, data) => {
	let keyData = data.map(org => {
		return org[key];
	  });
	let uniqueData = [...new Set(keyData)];
    return uniqueData;
}

export const getFilterValues = (key, data) => {
	let keyData = data.map(org => {
		return org[key];
	  });
	let uniqueFilters = [...new Set(keyData)];
	uniqueFilters = uniqueFilters.filter(data => {
		return (data !== undefined && data !== "" && data !== null);
	})
    let filters = uniqueFilters.map(val => {
		return {
			text: val,
			value: val
		};
    })
    return filters;
}

export const groupByKey = (list, key) => {
	return list.reduce((hash, obj) => ({...hash, [obj[key]]:( hash[obj[key]] || [] ).concat(obj)}), {})
}

export const segKeyData = (key,valueKey, data) => {
    let keyArray = data.map(obj => { return {label: obj[key], value: String(obj[valueKey])}});
	keyArray.filter((value, index, self) => self.map(x => x.value).indexOf(value.value) === index)
	return keyArray.sort((a, b) =>a.label.localeCompare(b.label, 'fi', {
		caseFirst: 'upper'
	}));
}

export const validURL = (str) => {
	var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
	  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
	  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
	  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
	  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
	  '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
	return !!pattern.test(str);
	// var a  = document.createElement('a');
	// a.href = str;
	// return (a.host && a.host != window.location.host);
}