import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Header from "../../components/Header";
import SupplierRatingList from '../../components/SupplierRatingList';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        margin: '0 auto',
    },

}));


const SupplierRating = () => {

    const classes = useStyles();
    const theme = useTheme();

    return ( 
      <>
        <Header /> 
        <div className = "mainContainer" >
        <div className="innerContainer">
        <div className={classes.root}>
             <SupplierRatingList value={2} index={1} dir={theme.direction}/>
    </div>
        </div> 
        </div> 
        </>
    );
};
export default SupplierRating;