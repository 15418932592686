import { Tabs } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';

import Header from "../../components/Header";
import UserProfileForm from '../../components/UserProfileForm';
import CompanyDetails from '../companydetails/CompanyDetails'
// import userdetails from "../userdetails/userdetails.css"
import AddDocument from '../documentfile/AddDocument';
import PropertyUserDetails from '../propertyuserdetails/PropertyUserDetails';
const UserProfile = () => {
const {TabPane}=Tabs
const AdminType=sessionStorage.getItem('type_admin')
const intl=useIntl()
const Roleid=sessionStorage.getItem('role_id')

	return (
    <>
      <Header />
      <div className="mainContainer profileContainer">
      	<div className="innerContainer">
          <Tabs defaultActiveKey="1">
            <TabPane tab={intl.formatMessage({id:"userdetails"})} key="2">
              <UserProfileForm />
            </TabPane>
            {Roleid !== 1 &&
              <TabPane tab={Roleid===2?intl.formatMessage({id:"org_details"}):intl.formatMessage({id:"companydetails"})} key="3">
                <CompanyDetails/>
              </TabPane>
            }
            {AdminType===1&&
                <TabPane tab={intl.formatMessage({id:"users"})} key="4">
                <PropertyUserDetails tabview={true}/>
              </TabPane>
            }
            {(Roleid===2||Roleid===3)&&
              <TabPane  tab={intl.formatMessage({id:"AddDocument"})} key="5">
                <AddDocument/>
              </TabPane>
            }
          </Tabs>
        </div>
      </div>
    </>
  );
};
 export default UserProfile;
