import React from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const AddTender = (props) => {
  const history = useHistory();

  return (
    <>
      <div className="card">
        <h2><FormattedMessage id={'tenders'} /></h2>
        <div onClick={() => history.push("/tender")} className="addTenderBtn">
        <svg
              xmlns="http://www.w3.org/2000/svg"
              width="29.701"
              height="29.701"
              viewBox="0 0 29.701 29.701"
            >
              <path
                fill="#fff"
                d="M15.85 1A14.85 14.85 0 1 0 30.7 15.85 14.852 14.852 0 0 0 15.85 1zm6.75 16.2h-5.4v5.4h-2.7v-5.4H9.1v-2.7h5.4V9.1h2.7v5.4h5.4z"
                transform="translate(-1 -1)"
              />
            </svg> <span><FormattedMessage id={'add_new_tender'}/></span>
        </div>
        <div className="viewAll" onClick={() => history.push('/tenders')}><FormattedMessage id={'view_all_tender'}/></div>
      </div>
    </>
  );
};

export default AddTender;
