import { schema } from "normalizr";
import { CUSTOM_AUTHORIZED_REQUEST } from '../middleware/apiAuthorization';
import {
	handleResponse,
	handleError,
} from "../utils";
import apiUrls  from "../utils/apiUrls";

export const FETCH_TENDER_SUPPLIERS_REQUEST = "FETCH_TENDER_SUPPLIERS_REQUEST";
export const FETCH_TENDER_SUPPLIERS_SUCCESS = "FETCH_TENDER_SUPPLIERS_SUCCESS";
export const FETCH_TENDER_SUPPLIERS_FAILURE = "FETCH_TENDER_SUPPLIERS_FAILURE";

export const CREATE_TENDER_SUPPLIERS_REQUEST = "CREATE_TENDER_SUPPLIERS_REQUEST";
export const CREATE_TENDER_SUPPLIERS_SUCCESS = "CREATE_TENDER_SUPPLIERS_SUCCESS";
export const CREATE_TENDER_SUPPLIERS_FAILURE = "CREATE_TENDER_SUPPLIERS_FAILURE";

export const UPDATE_TENDER_SUPPLIERS_REQUEST = "UPDATE_TENDER_SUPPLIERS_REQUEST";
export const UPDATE_TENDER_SUPPLIERS_SUCCESS = "UPDATE_TENDER_SUPPLIERS_SUCCESS";
export const UPDATE_TENDER_SUPPLIERS_FAILURE = "UPDATE_TENDER_SUPPLIERS_FAILURE";

export const DELETE_TENDER_SUPPLIERS_REQUEST = "DELETE_TENDER_SUPPLIERS_REQUEST";
export const DELETE_TENDER_SUPPLIERS_SUCCESS = "DELETE_TENDER_SUPPLIERS_SUCCESS";
export const DELETE_TENDER_SUPPLIERS_FAILURE = "DELETE_TENDER_SUPPLIERS_FAILURE";

const tenderSupplierSchema = new schema.Entity("tenderSuppliers", [], {
	idAttribute: "supplier_id",
});

// const tenderSupplierSchema = new schema.Entity(
// 	'tenderSuppliers',
// 	{ suppliers: supplierSchema},
// 	{
// 		idAttribute: 'tender_id',

// 		mergeStrategy: (entityA, entityB) => ({
// 			...entityA,
//       		...entityB,
// 		}),
// 	}
// );

/**
 * Returns a redux api middleware action that makes a request to fetch suppliers in a tender.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const fetchTenderSupplier = (tender_id) => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: apiUrls.tenderSupplierRequest(tender_id),
			method: "GET",
			types: [
				{ type: FETCH_TENDER_SUPPLIERS_REQUEST },
				{
					type: FETCH_TENDER_SUPPLIERS_SUCCESS,
					payload: (action, state, res) =>
						handleResponse([tenderSupplierSchema], action, state, res),
				},
				{ type: FETCH_TENDER_SUPPLIERS_FAILURE, payload: handleError },
			],
		}
	}
}

/**
 * Returns a redux api middleware action that makes a request to create suppliers for a tender.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const createTenderSupplier = (data, tender_id) => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: apiUrls.tenderSuppliersRequest(tender_id),
			method: "POST",
            body: JSON.stringify(data),
			types: [
				{ type: CREATE_TENDER_SUPPLIERS_REQUEST },
				{
					type: CREATE_TENDER_SUPPLIERS_SUCCESS,
					payload: (action, state, res) =>
						handleResponse([tenderSupplierSchema], action, state, res),
				},
				{ type: CREATE_TENDER_SUPPLIERS_FAILURE, payload: handleError },
			],
		}
	}
}

/**
 * Returns a redux api middleware action that makes a request to delete supplier for a tender.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const deleteTenderSupplier = (tender_id, supplier_id) => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: apiUrls.tenderSupplierBySupplierId(tender_id, supplier_id),
			method: "DELETE",
			types: [
				{ type: DELETE_TENDER_SUPPLIERS_REQUEST },
				{
					type: DELETE_TENDER_SUPPLIERS_SUCCESS,
					payload: (action, state, res) =>
						handleResponse([tenderSupplierSchema], action, state, res),
				},
				{ type: DELETE_TENDER_SUPPLIERS_FAILURE, payload: handleError },
			],
		}
	}
}