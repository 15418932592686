/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from 'lodash/get';
import { message, Table } from 'antd';
import Header from "../Header";
import "../Company/Company.css";
import axios from "axios";
import AddModal from "../Company/AddModal";
import { domainUrl } from "../../helper";
import ConfirmationBox from '../ConfirmationBox';
import { compNames, getFilterValues } from '../../utils';
import { fetchCompanies } from '../../actions/company';
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
const OrganisationCompany = (props) => {
  useEffect(() => {
    getAllCompany();
  }, [props]);
  const dispatch = useDispatch();
  const intl = useIntl();
  const role_id = useSelector(state => get(state, `entities.users.${sessionStorage.getItem('user_id')}.roles.role_id`));
  const login_org = useSelector(state => get(state, `entities.users.${sessionStorage.getItem('user_id')}.entities`));
  const history=useHistory()
  // const companies = useSelector(state => Object.values(get(state, `entities`)));
  const [companies, setCompanies] = useState([]);

  const [openCompanyFormModel, setOpenCompanyFormModel] = useState({
    isOpen: false,
    isReadOnly: false,
  });
  const [company, setCompany] = useState();
  //const [companies, setCompanies] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 10
  });
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [deleteComp, setDeleteComp] = useState({});

  const columns = [
    {
    //   dataIndex: "company_name",
      title:intl.formatMessage({ id:'company'}),
      filters: getFilterValues("company_name", companies),
      render:(val)=>{
          return(
              <span onClick={()=>companyUser(val)}>{val.company_name}</span>
            // <>{console.log(val)}</>
          )
      },
      onFilter: (value, record) => record?.company_name?.indexOf(value) === 0,
      sorter: {
        compare: (a, b) => {
        return compNames(a.company_name, b.company_name);
        },
      },
    },
    {
      dataIndex: "organization_name",
      title:intl.formatMessage({ id:'organisation'}),
      filters: getFilterValues('organization_name', companies),
      onFilter: (value, record) => record?.organization_name?.indexOf(value) === 0,
      sorter: {
        compare: (a, b) => {
          return compNames(a.organization_name, b.organization_name);
        },
      },
    },
    {
      dataIndex: "company_id",
      title:intl.formatMessage({ id:'view'}),
      render: (value) => (
        <div className="viewIcon" onClick={() => openCompanyForm(value, { readOnly: true })}><FormattedMessage id={'view'} /></div>
      ),
    },
    {
      dataIndex: "company_id",
      title:intl.formatMessage({ id:'modify'}),
      render: (value, tableMeta, updateValue) => (
        <div
          className="btn-primary"
          onClick={() => openCompanyForm(value, { readOnly: false })}
        >
         <FormattedMessage id={'modify'} />
        </div>
      ),
    },
    {
      dataIndex: "company_id",
      title:intl.formatMessage({ id:'delete'}),
      render: (value, tableMeta, updateValue) => (
        <div className="deleteIcon" onClick={() => deleteCompany(value)}>
         <FormattedMessage id={'delete'} />
        </div>
      ),
    },
  ];


  const companyUser=(val)=>{
      history.push({
        pathname:`/company/${val.company_id}`
      })
  }
  

  const getAllCompany = async () => {
    let params = {
      limit: 1000,
      organization_id:props?.match?.params?.organisationId
    }
    
    if(role_id === 2) {
      params.organization_id = parseInt(login_org[0].entity_id)
    }
    // await dispatch(fetchCompanies(params));
    const response = await dispatch(fetchCompanies(params));
    setCompanies(Object.values(response.payload.entities.companies))
  };

  const getSelectedCompany = (company_id) => {
    return companies.find((ele) => ele.company_id === company_id);
  };

  const openCompanyForm = (company_id, opts) => {
    let selected = getSelectedCompany(company_id);
    setOpenCompanyFormModel({ isOpen: true, isReadOnly: opts?.readOnly });
    if(role_id === 2) {
      if(!selected)
        selected = {};
      selected.organization_id = parseInt(login_org[0].entity_id)
    }
    setCompany(selected);
  };

  const closeCompanyForm = () => {
    setOpenCompanyFormModel({ isOpen: false });
    setCompany();
  };

  const addCompany = async (data) => {
    const headers = {
      client_code: "ht_front",
      token: sessionStorage.getItem("token"),
      "Content-Type": "application/json",
    };

    if (data?.company_id) {
      //modify current organization;
      const { company_id, ...rest } = data;
      
      try{

      // const res = 
      await axios.put(
        `${domainUrl}/masterservicenew/master/company/${company_id}`,
        { ...rest },
        { headers }
      );
      message.success(intl.formatMessage({ id:'company_updated_success_msg'}));
    }
catch(err) {
  message.error(err?.response?.data?.message === 'Validation error' ? 'A Company with this business Id is already added' : (err?.response?.data?.message || 'Server Error'));
}

      getAllCompany();
      closeCompanyForm();
      return;
    }

    //create new organization
    const body = {
      ...data,
      country_id: +data.country_id,
      city_id: +data.city_id,
    };
    axios.post(
          `${domainUrl}/masterservicenew/master/company`,
          body,
          { headers }
        ).then(res => {
          message.success(intl.formatMessage({ id:'company_added_success_msg'}));
          getAllCompany()
        }).catch(err => {
          message.error(err?.response?.data?.message === 'Validation error' ? 'A Company with this business Id is already added' : (err?.response?.data?.message || 'Server Error'));
        }) 
    
  //   try{

  //   const res = await axios.post(
  //     `${domainUrl}/masterservicenew/master/company`,
  //     body,
  //     { headers }
  //   );
  //   console.log(res);
  // }
  // catch(err) {
  //   console.log('err', err);
  // }

    getAllCompany();
    closeCompanyForm();
  };

  const onChangepagination = (page) => {
    setPagination({
      pageSize: page.pageSize,
    });
  }
  const deleteCompany = (id) => {
    setDeleteComp(id);
    setShowConfirmationBox(true);
  };

  const declineSubmit = () => {
    setShowConfirmationBox(false);
    setDeleteComp({});
  }

  const confirmDelete = async () => {
    // const res = 
    await axios.delete(
      `${domainUrl}/masterservicenew/master/company/${deleteComp}`,
      {
        headers: {
          client_code: "ht_front",
          token: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    getAllCompany();
    setShowConfirmationBox(false);
    setDeleteComp({});
  };

  return (
    <>
      <Header />
      <div className="mainContainer">
        <div className="innerContainer">
        <h2 className="title"> <FormattedMessage id={'companies'} /></h2>
          <Table
              className="ant-table-root"
              // scroll={{ x: 0, y: '40vh' }}
              columns={columns}
              dataSource={companies}
              pagination={pagination}
              onChange={onChangepagination}
              bordered
              rowKey='company_id'
              locale={{emptyText: intl.formatMessage({ id:'no_data'})}}
            />

          <div className="button1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="29.701"
              height="29.701"
              viewBox="0 0 29.701 29.701"
            >
              <path
                fill="#fff"
                d="M15.85 1A14.85 14.85 0 1 0 30.7 15.85 14.852 14.852 0 0 0 15.85 1zm6.75 16.2h-5.4v5.4h-2.7v-5.4H9.1v-2.7h5.4V9.1h2.7v5.4h5.4z"
                transform="translate(-1 -1)"
              />
            </svg>
            <span onClick={() => openCompanyForm(null, { readOnly: false })}>
              {" "}
              <FormattedMessage id={'addnew'} />
            </span>
          </div>
        </div>
      </div>

      {openCompanyFormModel?.isOpen && (
        <AddModal
          readOnly={openCompanyFormModel?.isReadOnly}
          data={company}
          role_id={role_id}
          onClose={closeCompanyForm}
          onSubmit={addCompany}
        />
      )}
      <ConfirmationBox showConfirmationBox={showConfirmationBox} message={<FormattedMessage id={'sure_to_delete_company'} />} confirmSubmit={confirmDelete} declineSubmit={declineSubmit} />
   
    </>
  );
};

export default OrganisationCompany;