/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import Header from "../Header";
import Setting from "../Setting";
import SettingHeadingLabel from "../Common/SettingHeadingLabel";
import "./SMSSetting.css";
import { FormattedMessage, useIntl } from "react-intl";
// import { connect } from "react-redux";
// import {
//   getServiceType,
//   postServiceType,
//   putServiceType,
//   deleteServiceType,
// } from "./redux/ServiceType/serviceType.actions";

const SMSSetting = (props) => {
  const intl = useIntl();
  return (
    <>
      <Header />
      <div className="settingOuter">
        <Setting page="SMSSetting" />
        <div className="tabContainer">
          <h2>  <FormattedMessage id={'sms_settings'} /></h2>
          <div className="tabContentBlock">
            <div className="contentBlock">
              <div className="formSettings">
                <div className="form-group">
                  <label className="form-label"> <FormattedMessage id={'Provided_url'} /></label>
                  <input className="form-control" type="text" />
                </div>
                <div className="form-group">
                  <label className="form-label"><FormattedMessage id={'User_id'} /></label>
                  <input className="form-control" type="text" />
                </div>
                <div className="form-group">
                  <label className="form-label"><FormattedMessage id={'password'} /></label>
                  <input className="form-control" type="text" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

SMSSetting.propTypes = {};

// const mapStateToProps = (state) => {
//     return {
//       count: state.counter.count,
//     };
//   };
//   const mapDispatchToProps = (dispatch) => {
//     return {
//       increaseCounter: () => dispatch(increaseCounter()),
//       decreaseCounter: () => dispatch(decreaseCounter()),
//       getServiceType: (data) => dispatch(getServiceType(data)),
//       postServiceType: (data) => dispatch(postServiceType(data)),
//       putServiceType: (data) => dispatch(putServiceType(data)),
//       deleteServiceType: (data) => dispatch(deleteServiceType(data)),
//     };
//   };

// export default connect(mapStateToProps, mapDispatchToProps)(Setting);
export default SMSSetting;
