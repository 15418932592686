/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import get from 'lodash/get';
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { getBidForTender } from "../../actions/bids";
import { Table, Typography, Divider } from "antd";
import { awsDocumentUrl } from "../../helper";
import FileDisplay from "../../components/FileDisplay";

const TenderBidDetails = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const { tenderId } = useParams();
  const [tenderBid, setTenderBid] = useState([]);
  const [fileList, setFileList] = useState([]);
  const { Text } = Typography;


  const bids = useSelector(state => get(state, `entities.tenderBids`));

  useEffect(() => {
    dispatch(getBidForTender({submitted_by: sessionStorage.getItem('user_id')},tenderId));
  },[]);

  useEffect(() => {
      if(bids) {
    let bidTender = Object.values(bids).find(bid => {
        if(bid.tender_id === parseInt(tenderId)) {
            bid?.lines?.map(item => {
                item.asset_name = (item.asset_name && item.asset_name != null) ? item.asset_name : item.line_text;
            })
        }
        return bid.tender_id === parseInt(tenderId);
    })
    setTenderBid(bidTender)
      }

},[bids]);

const onFileClick = async (file) => {
  try {
    if (file?.document_id) {
      const documentDetails = await axios.get(
        `${awsDocumentUrl}/Prod/v1/document/${file.document_id}`
      );

      let link;
      if ((link = documentDetails?.data?.data?.signed_link)) {
        const win = window.open(link, "_blank");
        win.focus();
      }
    }
  } catch (e) {
    console.error(e);
  }
};
  return (
      <>
      <div className="hedding">
        <h2>Bid Details</h2>
      </div>
    <div className="mainContainer">
            {tenderBid?.lines?.map((o, key) => (
              <div key={key} className="item item-full">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h3>{o?.asset_name}</h3>
                  <span
                    style={{
                      padding: "6px 10px",
                      backgroundColor: "#e0e0e0",
                      borderRadius: 3,
                    }}
                  >
                    {o?.line_amount}&nbsp;&euro;
                  </span>
                </div>
                <ol>
                  {o?.items?.map((i, index) => (
                    <li
                      key={index}
                      style={{
                        margin: "5px 0",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h3 style={{ color: "#6f6f6f" }}>{i?.item_text}</h3>
                      <span
                        style={{
                          padding: "6px 10px",
                          backgroundColor: "#e0e0e0",
                          border: "1px solid black",
                          borderRadius: 3,
                        }}
                      >
                        {i?.amount}&nbsp;&euro;
                      </span>
                    </li>
                  ))}
                </ol>
              </div>
            ))}
            <div className="labelBLock " style={{textAlign: 'right',fontWeight:'bold', fontSize: 17}}><FormattedMessage id={'total'}/>: {tenderBid?.sub_total || 0}&nbsp;&euro;</div>
        </div>

        <div className="mainContainer">
        <div className="titleSummary">
        <div className="titile"><FormattedMessage id="files"/></div>
        </div>

        {
          bids && 
            <FileDisplay entityType={'B'} fileList={fileList} entityId={Object.values(bids || {})?.[0]?.bid_id} hideTitle={false} deleteFiles={false}/>
        }
      </div>
  </>
  );
};

export default TenderBidDetails;
