import React  from 'react';
// import { Button, Tooltip } from 'antd';
// import { PlusCircleFilled } from '@ant-design/icons';
import PreTenderSteps from '../../components/PreTenderSteps';

import Header from "../../components/Header";

const PreTender = () => {

  // const [assetSelectionTable, setAssetSelectionTable] = useState(false);
  // const [searchVault, setSearchVault] = useState(false);
  // const [inspectionReports, setInspectionReports] = useState({});
 
	return (
    <>
    <div className="__Tender__">

      <Header />
      <div className="mainContainer" style={{padding: 30}}>
          <PreTenderSteps />
      </div>
    </div>
    </>
  );
};
 export default PreTender;
