/* eslint-disable import/no-anonymous-default-export */
export default {
  name: "Otsikko",
  closing_date: "Sulkeutumispäivä",
  bids: "Tarjoukset",
  status: "Tila",
  created_by: "Luonut",
  awarded_to: "Myönnetty",
  tenders: "Kilpailutukset",
  add_new_tender: "Luo Uusi Kilpailutus",
  view_all_tender: "Katso Kaikki Kilpailutukset",
  published_tenders: "Julkaistut Kilpailutukset",
  published: "Julkaistu",
  draft_tenders: "Kilpailutusluonnokset",
  draft: "Luonnos",
  pre_tenders: "Alustavat Tarjouspyynnöt",
  awarded_tenders: "Päättyneet Kilpailutukset",
  show_more: "Näytä Enemmän",
  show_less: "Näytä Vähemmän",
  new: "Uusi",
  tenders_submitted_bids: "Kilpailutukset - Jätetyt Tarjoukset",
  new_tenders: "Uudet Kilpailutukset",
  add_new_pre_tender: "Luo Uusi Alustava Tarjouspyyntö",
  submitted: "Jätetty",
  awarded: "Hyväksytty",
  supplier_registration_form: "Toimittajan ilmoittautumislomake",
  company_name: "Yrityksen nimi",
  business_id: "Y-tunnus",
  main_contact_person: "Yhteyshenkilö",
  email: "Sähköposti",
  mobile_number: "Puhelinnumero",
  property_manager: "isännöitsijä",
  propertymanagerdetails: "Isännöitsijä",
  "Award Comment": "Palkinnon kommentti",
  "Completion Comment": "Täydennyksen kommentti",
  "Recommendation Comment": "",
  // add: "Lisää",
  alternate_mobile_number: "Vaihtoehtoinen puhelinnumero",
  adminusers: "Admin käyttäjät",
  full_address: "Koko osoite",
  pin_code: "Postinumero",
  cities_operating: "Kaupungit, joissa toimitte",
  choose_industry_type: "Valitse palvelutyyppi",
  "error.name": "Ole hyvä ja kirjoita nimesi",
  "error.business_id": "Ole hyvä ja kirjoita Y-tunnuksesi",
  "error.email": "Ole hyvä ja kirjoita sähköpostisi",
  "error.invalid_email": "Syöttämäsi sähköposti ei ole kelvollinen.",
  "error.mobile_number": "Ole hyvä ja kirjoita puhelinnumerosi",
  "error.invalid_mobile_number": "Syöttämäsi puhelinnumero ei ole kelvollinen",
  "error.address": "Ole hyvä ja kirjoita osoitteesi",
  "error.pin_code": "Ole hyvä ja kirjoita postinumerosi",
  "error.invalid_pin_code": "Syöttämäsi postinumero ei ole kelvollinen",
  "error.city": "Ole hyvä ja kirjoita kaupunki",
  "error.service_type": "Ole hyvä ja valitse palvelutyyppisi",
  "error.supplier_name": "Ole hyvä ja kirjoita toimittajanimi",
  "error.msg": "Ole hyvä ja kirjoita kysymyksesi",
  "error.organization": "Ole hyvä ja kirjoita organisaation nimi",
  "error.company_name": "Ole hyvä ja kirjoita yrityksen nimi",
  city: "Kaupunki",
  submit: "Lähetä",
  submit_request: "Kiitos pyynnön lähettämisestä!",
  response_back: "Arvioimme pyyntönne ja olemme Teihin pian yhteydessä",
  ok: "Okei",
  subadmin: "Sub-Admin",
  edit: "Muokkaa",
  profile: "Profiili",
  profil_update: "Profiilin tiedot päivitetty onnistuneesti",
  profile_details: "Profiilin tiedot",
  password: "Salasana",
  current_pwd: "Nykyinen salasana",
  new_pwd: "Uusi salasana",
  confirm_new_pwd: "Vahvista uusi salansana",
  "error.current_pwd": "Ole hyvä ja syötä nykyinen salasanasi",
  "error.new_pwd": "Ole hyvä ja syötä uusi salasana",
  "error.confirm_new_pwd": "Vahvista salasanasi!",
  match_pwd: "Antamasi kaksi salasanaa eivät täsmää",
  change_pwd: "Vaihda salasana",
  pwd_change: "Salasana vaihdettu onnistuneesti",

  reset_email: "Sähköposti salasanan vaihtamista varten lähetetty",
  forgot_pwd: "Unohditko salasanasi?",
  reset_pwd: "Nollaa salasana",
  repair: "korjaus",
  others: "muut",
  reset_email_failure: "Käyttäjää ei ole olemassa",
  invalid_code: "Koodi ei ole kelvollinen",
  organization: "Organisaatio",
  company: "Yhtiö",

  last_updated: "Viimeksi päivitetty",
  awarded_date: "Voitettu",
  completion_date: "Työn valmistumispäivä",
  date_submitted: "Lähetyspäivä",
  published_date: "Julkaisupäivä",
  delete_tender_msg: "Haluatko varmasti poistaa tämän tarjouspyynnön?",
  supplier_details: "Toimittajatiedot",
  label_name: "Nimi",
  address: "Osoite",
  country: "Maa",
  industry_type: "Palvelutyyppi",
  supplier_rating: "Toimittajan luokitus",
  mark_favourite: "Merkitse suosikiksi",
  favourite: "Suosikki",
  deactivate: "Deaktivoi",
  save: "Tallenna",
  cancel: "Peruuta",
  send_question: "Lähetä kysymys ylläpitäjälle",
  send_question2: "Lähetä kysymys",
  ask_question: "Esitä kysymyksiä",
  send: "Lähetä",
  message: "Viesti",
  edit_company_details: "Muokkaa yrityksen tietoja",
  add_company_details: "Anna yritystiedot",
  company_details: "Yrityksen tiedot",
  select_city: "Valitse kaupunki",
  select_operating_city: "Valitse Toimintakaupungit",
  select_organization: "Valitse organisaatio",
  default_emails: "Oletussähköpostit",
  default_email: "Oletussähköposti",
  "tender.choose_tender_type.info":
    "Valitse tässä mitä haluat kilpailuttaa. Valitsemalla ”Korjaus”-vaihtoehdon, voit kilpailuttaa eri korjaustöitä, esimerkiksi varaosien korjausta. Valitsemalla “Muut”-vaihtoehdon, voit kilpailuttaa mm. siivouksia, sopimuksia, tarkastuksia ja muita vastaavia.",
  "tender.choose_company.info":
    "Valitse tässä kenelle kilpailutetaan. Käytä hakutoimintoa apuna. Tällä voit kilpailuttaa myös omia hankintojasi.",
  "tender.tender_details.info":
    "Lisää tähän kilpailutuksen otsikko/nimi. Voit antaa myös lisätietoja kyseisestä kilpailutuksesta halutessasi.",
  "tender.choose_suppliers.info":
    "Valitse toimittajat, joille haluat lähettää tämän tarjouspyynnön. Jos haluamaasi toimittajaa ei löydy listalta, niin voit lähettää kutsun toimittajillesi lisäämällä sähköpostiosoitteen yksi kerrallaan sivun alalaidassa olevaan tekstikenttään. Toimittajien kirjauduttua, löydät heidät jatkossa toimittajalistalta.",
  "tender.asset_details.info":
    "Rakenna tarjous kohteittain. Esim. pääkohde voi olla asunto 123 ja alakohde voi olla tehtävä tai remontin kohde, esim. vessanpöntön vaihto. Tämän koko vaiheen voi hypätä yli, jos seuraavassa vaiheessa liitettävä tarjouspyyntö sisältää kaikki tarvittavat tiedot.",
  "tender.file.info": "Lisää mahdolliset liitteet, esimerkiksi tarjouspyyntö.",
  "tender.closing_date.info":
    "Valitse mihin mennessä haluat vastaanottaa tarjoukset, sekä kuinka monta muistutusta haluat lähettää sulkeutuvasta kilpailutuksesta niille toimittajille, jotka eivät ole vielä vastanneet kilpailutukseen omalla tarjouksellaan.",
  "tender.additional_info.info":
    "Lisää tähän mahdollisia kilpailutukseen tai sen kohteeseen liittyviä lisätietoja. Esimerkiksi, voit mainita mistä voi noutaa avaimet tai mikä on ovikoodi.",
  "tender.summary.info":
    "Tarkista yhteenvedosta, että kaikki tiedot ja liitteet ovat oikein. Joka kohtaan pääsee siirtymään ja tietoja on mahdollista muokata tarvittaessa.",
  progress: "Tila",
  progress_bids: "Kilpailutukset - Keskeneräiset tarjoukset",
  from: "Lähettäjä",
  // date: "Päivämäärä",
  time: "Aika",
  // total: "Yhteensä",
  tender: "Tarjouspyyntö",
  last_msg: "Viimeisimmän viestin lähettäjä",
  of: "",
  items: "kohteita",
  msg_error: "Viestien lähettäminen epäonnistui",
  msg_send: "Vastauksen lähettäminen onnistui",
  tender_type: "Valitse tarjouspyynnön tyyppi",
  choose_company: "Valitse yritys",
  tender_details: "Tarjouspyynnön tiedot",
  choose_suppliers: "Valitse urakoitsija",
  asset_details: "Kilpailutuksen kohteiden tiedot",
  attach_file: "Liitä tiedosto",
  add_info: "Lisätiedot",
  summary: "Yhteenveto",
  preTender_type: "Valitse korjausehdotuksen tyyppi",
  preTender_details: "Syötä korjausehdotuksen tiedot",
  select_asset: "Valitse kilpailutuksen kohde",
  next: "Seuraava",
  bakc: "Takaisin",
  back: "Takaisin",
  publish: "Julkaise",
  // filters: "Suodattimet",
  company_type: "Yrityksen tyyppi",
  choose_org: "Valitse organisaatio",
  select: "Valitse",
  select_all: "Valitse kaikki",
  unselect_all: "Poista kaikki valinnat",
  enter_tender_details: "Syötä tarjouspyynnön tiedot",
  "error.tender_title": "Ole hyvä ja anna tarjouspyynnölle nimi.",
  title: "Otsikko",
  recommended_supplier: "Suositeltu urakoitsija",
  recommended_supplier_to_property_manager: "Suosittele urakoitsijaa isännöitsijälle",
  size: "Koko",
  desc: "Kuvaus",
  please_select_a_supplier: "Ole hyvä ja valitse urakoitsija",
  service_type: "Palvelutyyppi",
  by_country: "Maan mukaan",
  by_city: "Kaupungin mukaan",
  by_rating: "Arvion mukaan",
  supplier_for_project: "Valitse urakoitsija tälle projektille",
  rating: "Arvio",
  invite_supplier: "Kutsu uusia urakoitsijoita",
  // all: "Kaikki",
  select_fav: "Valitse ”Suosikit”",
  unselect_fav: "Poista ”Suosikit”-valinta",
  add_item: "Lisää kilpailutuksen alakohde",
  enter_asset: "Syötä kilpailutuksen kohde",
  enter_item: "Syötä kilpailutuksen alakohde",
  // add_asset: "Lisää kohde",
  upload_msg: "Klikkaa latauspainiketta, kun olet valinnut ladattavat tiedostot",
  upload_files: "Lataa tiedostot",
  input_file: "Syötä tiedostot",
  file_desc: "Tiedoston tiedot",
  drop_files: "Pudota tiedostot tähän",
  no_files_uploaded: "Ei ladattuja tiedostoja",
  files_uploaded: "Ladatut tiedostot",
  closing_date_req: "Sekä sulkeutumispäivä, että muistutusväli ovat pakollisia. Täytäthän molemmat.",
  server_error: "Palvelimeen ei saada yhteyttä",
  choose_closing_date: "Valitse sulkeutumispäivä",
  send_reminders_before: "Aloita muistutusten lähettäminen",
  days: "päivää ennen tarjouspyynnön sulkeutumista",
  additional_info: "Lisätiedot/Kommentit",
  publish_tender_conf: "Oletko varma, että haluat julkaista tämän tarjouspyynnön?",
  yes: "Kyllä",
  send_recommendation: "Lähetä suositus.",
  no: "Ei",
  email_info: "Lähetä tarjouspyynnön tiedot sähköpostilla",
  preview: "Esikatselu",
  tenderType: "Tarjouspyynnön tyyppi",
  chose_suppliers: "Valitut urakoitsijat",
  choose_receiver: "Valitse vastaanottaja",
  add_email: "Lisää sähköposti",
  comments: "Kommentit",
  include_attach: "Sisällytä tiedostoliitteet",
  evaluate_bids: "Vertaile tarjouksia",
  modify_tender: "Muokkaa tarjouspyyntöä",
  delete_tender: "Poista tarjouspyyntö",
  organisation: "Organisaatio",
  view: "Katso",
  compare_bids: "Vertaa tarjouksia",
  award_bid: "Hyväksy tarjous",
  comparison: "Vertaus",
  tenders_published: "Luodut kilpailutukset",
  today: "Tänään",
  this_week: "Tällä viikolla",
  this_month: "Tässä kuussa",
  tenders_marked_as_complete: "Valmistuneet kilpailutukset",
  tenders_due_for_completion: "Valmistumista odottavat kilpailutukset",
  tenders_failed_completion: "Kilpailutukset, joita ei saatu valmiiksi ajoissa",
  new_suppliers_registered: "Uudet rekisteröityneet urakoitsijat",
  active_tenders: "Aktiiviset kilpailutukset",
  publishing_organization: "aktiivisinta organisaatiota tässä kuussa",
  tender_name: "Kilpailutuksen nimi",
  tender_id: "Kilpailutuksen ID",
  housing_company_name: "Taloyhtiön nimi",
  bids_counts_received: "Vastaanotetut tarjoukset",
  modify: "Muokkaa",
  // delete: "Poista",
  addnew: "Lisää uusi",
  companies: "Yritykset",
  asset: "Kilpailutuksen kohde",
  assets: "Kilpailutuksen kohteet",
  supplier_name: "Urakoitsijan nimi",
  country_name: "Maa",
  inspection_report_vault: "Kuntokartoitusraportit",
  inspection_date: "kuntokartoitus päivämäärä",
  recommended_supplier_to_stakeholders: "Recommended Supplier To Stakeholders",
  recommended_supplier_to_admin: "Suositeltu toimittaja järjestelmänvalvojalle",
  CompanyDescription: "Yrityksen kuvaus",
  Date_recieved: "Vastaanotettu",
  accept: "Hyväksy",
  reject: "Hylkää",
  invitation_send: "Kutsun lähetys onnistui",
  sendinvitation: "Lähetä kutsu",
  invite_suppliers: "Kutsu uusia urakoitsijoita",
  invited_suppliers: "kutsutut tavarantoimittajat",
  Enter_emailid: "Syötä sähköposti",
  Unable_invitation: "Kutsun lähettäminen urakotsijalle epäonnistui",
  supplier: "Urakoitsija",
  sms_settings: "Tekstiviestiasetukset",
  Provided_url: "URL-osoite",
  User_id: "Käyttäjätunnus",
  email_settings: "Sähköpostiasetukset",
  server: "Palvelin",
  user: "Käyttäjä",
  SSL: "SSL",
  payment_gateway: "Maksuyhdyskäytävä",
  provided_url: "URL-osoite",
  Client_id: "Asiakastunnus",
  sekret_key: "Salainen avain",
  consultancy_price: "Konsultoinnin hinta",
  plan_name: "Palvelupaketin nimi",
  price: "Hinta",
  select_company_name: "Valitse yrityksen nimi",
  inspection_report_name: "Kuntokartoitusraportin nimi",
  Enter_reportname: "Syötä raportin nimi",
  Company_Adress: "Yrityksen osoite",
  Enter_CompanyAdress: "Syötä yrityksen osoite",
  Company_Business_Id: "Yrityksen y-tunnus",
  Select_Business_ID: "Valitse y-tunnus",
  Organisation_Name: "Organisaation nimi",
  organization_name: "Organisaation nimi",
  registration_date: "Rekisteröintipäivä",
  delete_supplier: "Haluatko varmasti poistaa tämän urakoitsijan?",
  reject_supplier: "Haluatko varmasti hylätä urakoitsijan?",
  approve_supplier: "Haluatko varmasti hyväksyä urakoitsijan?",
  add_supplier: "Haluatko varmasti lisätä tämän urakoitsijan?",
  edit_supplier: "Haluatko varmasti muokata tätä urakoitsijan?",
  supplier_already_registered:
    "Virheellinen pyyntö, urakoitsija on jo rekisteröity!",
  unable_to_send_invitation_to_supplier: "Kutsua ei voi lähettää toimittajalle",
  delete_company_type: "Haluatko varmasti poistaa tämän yritystyypin?",
  delete_service_type: "Haluatko varmasti poistaa tämän palvelutyypin?",
  add_asset: "Lisää kilpailutuksen kohde",
  edit_asset: "Haluatko varmasti muokkaa tämän kilpailutuksen kohteen?",
  delete_asset: "Haluatko varmasti poistaa tämän kilpailutuksen kohteen?",
  are_you_sure: "Are you sure you want to ",
  the_organization: " the organization?",
  dashboard: "Yhteenveto kilpailutuksista",
  bid_placed_successfully: "Tarjous tehty onnistuneesti",
  go_to_dashboard: "Siirry Dashboard:iin",
  delete_pre_tender: "Poista alustava tarjouspyyntö",
  modify_pre_tender: "Muokkaa alustavaa tarjouspyyntöä",
  pre_tender_details: "Alustavan tarjouspyynnön tiedot",
  are_you_sure_you_want_to_delete_this_pre_tender:
    "Haluatko varmasti poistaa tämän alustavan tarjouspyynnön?",
  are_you_sure_you_want_to_modify_this_pre_tender:
    "Haluatko varmasti muokata tätä alustavaa tarjouspyyntöä?",
  please_confirm_to_convert_this_pre_tender_to_tender:
    "Ole hyvä ja vahvista alutavan tarjouspyynnön muutos kilpailutukseksi",
  please_confirm_to_reject_the_tender:
    "Ole hyvä ja hyväksy kilpailutuksen hylkäys",
  please_confirm_to_award_the_tender: "Ole hyvä ja hyväksy tarjouspyyntö",
  you_have_choosen_to_award_the_bid_to: "Olet päättänyt hyväksyä urakoitsijan ",
  do_you_want_to_proceed: "tarjouksen. Haluatko edetä ?",
  Organisation_Select: "Valitse organisaatio",
  Asset_Name: "Kilpailutuksen kohteen nimi",
  Enter_Asset: "Syötä kilpailutuksen kohde",
  Search_inspiration_inspect: "Etsi kuntokartoitusraportti",
  page: "Sivu",
  document_deleted_successfully: "Tiedoston poistaminen onnistui",
  are_you_sure_you_want_to_delete_this_tender:
    "Haluatko varmasti poistaa tämän tarjouspyynnön?",
  unmark_as_favourite: "Poista suosikeista",
  mark_as_favourite: "Merkitse suosikiksi",
  Awarded_tender_progress: "Voitetut kilpailutukset",
  check_closing_date: "Tarkista sulkemispäivä.",
  nameu: "Nimi",
  tender123: "Tarjouspyyntö#123",
  supplier1: "Urakoitsija 1",
  current_tender: "Nykyinen kilpailutus",
  Created_by: "Luonut",
  Bids_Receieved: "Vastaanotetut tarjoukset",
  sub_total: "Sub Total",
  Tender_Details: "Tarjouspyynnön tiedot",
  item: "Kilpailutuksen alakohde",
  select_company_type: "Valitse yrityksen tyyppi",
  tender_title: "Tarjouspyynnön nimi",
  // search: "Hae",
  // reset: "Nollaa",
  filter: "Suodattimet",
  bids_submitted: "Löhetetyt tarjoukset",
  created_at: "Luotu",
  Updated_date: "Päivitetty",
  Action: "Toiminto",
  organisation_registration_form: "Organisaation ilmoittautumislomake",
  full_name: "Koko nimi",
  long_description_en: "Pitkä kuvaus (englanniksi)",
  long_description_fi: "Pitkä kuvaus (suomeksi)",
  short_description_en: "Lyhyt kuvaus (englanniksi)",
  short_description_fi: "Lyhyt kuvaus (suomeksi)",
  social_ig_link: "Instagram-linkki",
  social_fb_link: "Facebook-linkki",
  social_youtube_link: "Youtube-linkki",
  asset_category: "Kilpailutuksen kohteen kaegoria",
  Reason_for_modifying: "Tarjouspyynnön muokkaamisen syy",
  continue: "Jatka",
  Add_New_Inspection: "Lisää uusi kuntokartoitus",
  postal_code: "Postinumero",
  Default_Emails: "Oletussähköpostit",
  Default_Emails_Add: "Lisää oletussähköposteja",
  add: "Lisää",
  org_details: "Organisaation Tiedot",
  rating_error: "Arviointia ei ole lähetetty",
  rating_submitted: "Arvionti lähetetty onnistuneesti.",
  completed_tenders: "Valmistuneet Kilpailutukset",
  completed: "Valmistuneet",
  cnfrmPassword: "Vahvista Salasana",
  User_success: "Käyttäjän lisäys onnistui",
  contact: "Ota yhteyttä",
  userdetails: "Käyttäjän tiedot",
  Document: "Tiedosto",
  AddDocument: "Lisää tiedosto",
  companydetails: "Yritys Yksityiskohdat",
  Contactsupport: "Ota yhteyttä tukeen",
  manage: "Hallitse",
  welcome: "Tervetuloa",
  logout: "Kirjaudu ulos",
  UploadLogo: "Lataa logo",
  clickupload: "Lataa napsauttamalla",
  Upload: "Lataa",
  award: "Hyväksy tarjous",
  header: "Ylätunniste",
  footer: "Alatunniste",
  "Additional Information/Comment": "Lisätiedot/kommentit",
  "Please enter inspection report name.": "Syötä kuntokartoitusraportin nimi",
  "Inspection Report Name": "Kuntokartoitusraportin nimi",
  property: "Kiinteistö",
  "Property To Be Inspected": "Tarkastettava kohde",
  customer: "Asiakas",
  Buyer: "Tilaaja",
  Buyer_name: "Tilaaja Nimi",
  Buyer_address: "Tilaaja Osoite",
  Evaluator: "Arvion Laatija",
  Creator: "Suorittaja",
  // all: "Kaikki",
  start_bidding: "Jätä tarjous",
  cancel_bidding: "Peruuta tarjous",
  do_not_bid: "Älä tarjoa",
  bid_summary: "Tarjouksen yhteenveto",
  no_files_attached: "Ei liitettyjä tiedostoja",
  documents_attached: "Liitetyt tiedostot",
  "FUTURE CORRECTIONS": "Tulevat korjaukset",
  "Fitness rating": "Kuntoluokitus",
  "FAULT FREQUENCY ACCORDING TO SERVICE JOURNAL":
    "VIKATIHEYS HUOLTOKIRJAN MUKAAN",
  "Commendable, 0-1 bug last year":
    "Kiitettävä, 0-1 vikaa viimeisen vuoden aikana",
  "Good, 2-3 failures in the last year":
    "Hyvä, 2-3 vikaa viimeisen vuoden aikana",
  "Satisfactory, 4-5 failures in the last year":
    "Tyydyttävä, 4-5 vikaa viimeisen vuoden aikana",
  "Bad, more than 5 failures in the last year":
    "Huono. yli 5 vikaa viimeisen vuoden aikana",
  "SPARE PARTS AVAILABILITY": "Varaosien saatavuus",
  "Spare parts are well available.": "Varaosia on hyvin saatavilla.",
  "The availability of spare parts may decline in 5 years.":
    "Varaosien saatavuus voi heikentyä 5 vuoden päästä.",
  "All parts are no longer available as new, some possible repairs are based on demolition parts.":
    "Kaikkia osia ei saa enää uutena, osa mahdollisista korjauksista purkuosien varassa.",
  "There are hardly any new spare parts available.":
    "Juuri mitään uusia varaosia ei ole saatavilla.",
  "Select an Asset to create Inspection Report":
    "Valitse asetukset kuntokartoitusraportin luomista varten",
  "Reported By": "Ilmoittaja",
  "Report Date": "Ilmoituspäivä",
  // enter: "Syötä",
  "Enter Name": "Syötä Nimi",
  "SMS Setting": "SMS-asetukset",
  close: "Sulje",
  "Awarded Tenders-In Progress": "Päättyneet kilpailutukset - Käynnissä",
  "Add New Tender": "Lisää uusi tarjouspyyntö",
  Showmore: "Näytä lisää",
  submittedby: "Lähettäjä",
  "Lift repair": "Hissin korjaus",
  "Please set a title for the tender.":
    "Ole hyvä ja syötä otsikko tarjouspyynnölle ",
  "Only visible to me": "Näkyy vain minulle",
  "Enter Tender Details": "Syötä tarjouspyynnön tiedot",
  "File Description": "Tiedoston kuvaus",
  "Chosen Suppliers": "Valitut urakoitsijat",
  "Do you really want to publish this tender?":
    "Haluatko varmasti julkaista tämän tarjouspyynnön?",
  "Please enter tender type.": "Ole hyvä ja valitse tarjouspyynnön tyyppi.",
  "Please select atleast one company.":
    "Ole hyvä ja valitse ainakin yksi yritys.",
  "Enter Email": "Syötä Sähköposti",
  submitanonymously: "Lähetä anonyymisti",
  asset_item_missing: "Kilpailutuksen kohde puuttuu",
  please_enter_the_email_ids: "Ole hyvä ja syötä sähköpostiosoite",
  please_add_assets_or_upload_files_to_publish_a_tender:
    "Ole hyvä ja lisää kilpailutuksen kohde tai liitä tiedosto kilpailutettavista kohteista, jotta tarjouspyynnön julkaisu onnistuisi",
  invalid_email: "Virheellinen sähköpostiosoite",
  "Add Rating": "Lisää palaute",
  "Add Comment": "Lisää kommentti",
  "Pre Tender Details": "Pre Tender Details",
  "Received from ": "Received from ",
  "Convert to Tender": "Muuta tarjouspyynnöksi",
  "Send email with all Pre Tender info":
    "Lähetä sähköpostila kaikki alaustavan tarjouksen tiedot",
  "Reason for rejecting tender?": "Reason for rejecting tender?",
  files: "Tiedostot",
  bid_received: "Tarjous vastaanotettu",
  no_files_attached_either_add_a_file:
    "Ei liitetiedostoja. Lisää tiedosto ja yritä uudelleen, tai jatka painamalla seuraava",
  cost: "Hinta",
  "Unable to publish tender.": "Unable to publish tender.",
  "Tender has been published successfully.":
    "Kilpailutus on julkaistu onnistuneesti.",
  "Goto Tender.": "Goto Tender.",
  Pretenders: "Pre Tenders",
  total: "Yhteensä",
  submit_bid: "Lähetä tarjous",
  want_to_submit_this_bid: "Haluatko varmasti lähettää tämän tarjouksen?",
  AddUser: "Lisää käyttäjä",
  username: "Käyttäjätunnus",
  SaveDate: "Tallenna päivämäära",
  ChangeDate: "Muuta päivämäärä",
  "Save New Date": "Tallenna uusi päivämäärä",
  "Mark As Complete": "Merkitse valmiiksi",
  "Date and progress": "Päivämäärä ja edistyminen",
  proofs: "Todisteet",
  "Enter completion date": "Syötä valmistumispäivä",
  Name: "Nimi",
  adress: "Osoite",
  date: "Päivämäärä",
  select_country: "Valitse maa",
  home: "Päänäkymä",
  settings: "Asetukset",
  type: "tyyppi",
  inspection_report: "Kuntokartoitusraportin",
  "Company type added successfully": "Yritystyypin lisäys onnistui",
  "Asset Added Successfully": "Kohteen lisäys onnistui",
  "Company Description": "Yrityksen kuvaus",
  "Are you sure you want to delete this tender?":
    "Haluatko varmasti poistaa tämän tarjouspyynnön?",
  success: "Vaihdettu onnistuneesti",
  users: "Käyttäjät",
  completed_date: "Työn valmistumispäivä",
  "Please Enter Name": "Syötä Nimi",
  "Please Enter Price": "Syötä Hinta",
  "Please Enter Description": "Syötä Kuvaus",
  enter: "Syötä",
  "error.Total": "Yhteensä on oltava suurempi kuin 0",
  selected_company: "Valittu yritys",
  add_company_assets: "Lisää yritys lisätäksesi kilpailutuksen kohteet",
  selected_housing_company: "Valittu taloyhtiö",
  "error.password": "Ole hyvä ja syötä salasana",
  org_dup_err: "Organisaatio tällä sähköpostiosoitteella on jo olemassa.",
  full_bid: "Kokonaistarjous kategorioittain",
  partial_bid: "Osittainen tarjous",
  total_bid: "Kokonaistarjous",
  give_feedback: "Anna palautetta",
  Messages: "Viestit",
  sure_to_modify_tender: "Haluatko varmasti muokata tätä kilpailutusta?",
  email_sent_success: "Sähköpostin lähetys onnistui.",
  select_tender_type: "Ole hyvä ja syötä kilpailutuksen tyyppi",
  enter_tender_type: "Syötä tarjouslaji.",
  tender_delete_success: "Tarjous poistettu onnistuneesti.",
  asset_required: "Valitse vähintään yksi resurssi.",
  attachfile_required: "Lataa vähintään yksi tiedosto ennen kuin jatkat.",
  go_to_home_page: "Palaa etusivulle",
  new_request: "Uusi pyyntö",
  "Are you sure you want to rejected the organization?":
    "Haluatko varmasti hylätä organisaation?",
  "Are you sure you want to approved the organization?":
    "Haluatko varmasti hyväksyä organisaation?",
  sure_to_delete_organization: "Haluatko varmasti poistaa tämän organisaation?",
  company_updated_success_msg: "Yrityksen päivitys onnistui",
  company_added_success_msg: "Yrityksen lisääminen onnistui",
  sure_to_delete_company: "Haluatko varmasti poistaa tämän yrityksen?",
  continue_bidding: "Jatka tarjouksen tekoa",
  search_tender_name: "Hae kilpailutus nimellä",
  no_data: "Ei dataa",
  you_have_added_files_but_not_uploaded_proceed_without_uploading:
    "Olet lisännyt tiedostoja, muttet ladannut niitä. Haluatko jatkaa ilman latausta?",
  place_bids: "Jätä tarjous",
  website: "verkkosivu",
  supplier_comments: "Urakoitsijan kommentti",
  question_sent: "Kysymyksen lähetys onnistui",
  not_authorize_manger:
    "Olet kirjautunut sisään isännöitsijänä etkä ole valtuutettu katsomaan tätä sivua",
  supplier_updated: "Urakoitsijan päivitys onnistui",
  files_uploaded_success: "Tiedoston lataus onnistui",
  supplier_deactivate_msg: "Urakoitsijan deaktivointi onnistui",
  supplier_add_msg: "Urakoitsijan lisäys onnistui",
  year_of_manufacture: "Valmistusvuosi",
  control_system: "Ohjausjärjestelmä",
  elevator_speed: "Hissin nopeus",
  elevator_type: "Hissityyppi",
  // Inspection report
  Elevator_and_block_information: "HISSIN TIEDOT JA PORRAS ",
  Block_and_elevator_number: "Porras ja hissin nro:",
  Elevator_cars_doors: "Korin / tason ovet:",
  Engine_rooms_location: "Konehuoneen sijainti:",
  Manufacture_renovation_year: "Valmistus- / peruskorjausvuosi:",
  Door_dimensions_width_height: "Oven mitat mm (lev x kork):",
  Shaft_dimensions_width_height: "Kuilun mitat mm (lev x syv)",
  Elevator_type: "Hissin tyyppi:",
  Nominal_load_kg_person: "Nimelliskuorma (kg / hlö):",
  Elevator_car_dimensions: "Korin mitat mm (lev x syv x kork):",
  Manufacturer_model: "Valmistaja / malli:",
  Elevator_speed: "Hissin nopeus (m/s):",
  "Control_system/Operating_system": "Käyttöjärjestelmä / ohjausjärjestelmä:",
  Responsible_for_elevator_maintenance: "Hissin huoltaja:",
  Number_of_floors_and_floor_markings: "Kerrosmäärä ja krs merkinnät",
  // Operating_system:"Käyttöjärjestelmä:",
  Date_of_periodic_elevator_inspection_next_inspection:
    "Määräaikaistarkastettu / seur. tark:",
  "To be repaired immediately": "Korjattava välittömästi",
  "To be repaired within 1-2 years": "Korjattava 1-2 vuoden sisällä",
  "To be repaired within 2-3 years": "Korjattava 2-3 vuoden sisällä",
  "To be repaired within 3-4 years": "Korjattava 3-4 vuoden sisällä",
  "To be repaired within 4-5 years": "Korjattava 4-5 vuoden sisällä",
  "All OK, No cost within 5 years":
    "Kaikki kunnossa, ei kustannuksia 5 vuoden sisään",
  "FUTURE COSTS": "TULEVAT KUSTANNUKSET",
  "Condition rating": "Kuntoluokitus",
  "Machinery and Equipments": "KONEISTO JA LAITTEET",
  Body: "Body",
  Panel: "KOJETAULU",
  "Elevator car": "KORI",
  Shaft: "KUILU",
  "Doors and Fronts": "OVET JA EDUSTAT",
  "Compliance with regulations and using the elevator":
    "Määräystenmukaisuus ja hissin käyttäminen",
  "The elevator complies with regulations and is safe to use.":
    "Hissi on määräystenmukainen ja turvallinen käyttää.",
  "Defects and costs according to the maintenance book":
    "Huolto, viat ja kustannukset huoltokirjan mukaan",
  "The elevator has had quite a few defects and repairs throughout its life cycle. The automatic doors of the smart lift require a lot of maintenance and replacement of parts in order to work. Door straps and mechanisms usually need to be replaced annually to keep the doors in working order.":
    "Hissillä on ollut melko paljon vikoja ja korjauksia koko elinkaaren ajan. Smart-hissin automaattiovet vaativat paljon huoltoa ja osien vaihtoa toimiakseen. Ovihihnat ja mekanismit on yleensä uusittava vuosittain, jotta ovet pysyvät toimintakunnossa.",
  "Availability of spare parts": "Varaosien saatavuus",
  "The availability of spare parts is moderate with this elevator model. The smart elevator has not been manufactured since 2006, so the prices of spare parts increase considerably every year.":
    "Varaosien saatavuus on kyseisellä hissimallilla kohtalainen. Smart hissiä ei ole valmistettu vuoden 2006 jälkeen, joten varaosien hinnat nousevat reilusti vuosittain.",
  "Actions to be taken": "Tehtävät toimenpiteet",
  "The elevator has some repair debt and, for example, the technical service life of the frequency converter has been considerably exceeded. Replacing the drive costs approx. € 4,500 - € 11,000 VAT 24% depending on the person making the correction. The door area should be renovated in order to increase operational reliability and reduce unnecessary fault costs. Renovation of an automatic door costs approx. € 6,000 VAT 24%. Repairs should be done as planned, in larger units and on a competitive basis. Another option would be to overhaul the elevator more extensively, i.e., new full controls, pushbuttons, door operators and worn out parts. The price estimate for a larger renovation is approx. € 30,000 VAT 24%.":
    "Hissillä on jonkin verran korjausvelkaa ja esim taajuusmuuttajan tekninen käyttöikä on ylitetty reilusti. Taajuusmuuttajan uusiminen maksaa n. 4500 - 11000 € alv 24% riippuen korjeuksen tekijästä. Ovialue kannattaa peruskorjata, jotta toimintavarmuutta saadaan nostettua ja turhia vikakustannuksia alas. Automaattioven peruskorjaus maksaa n. 6000 € alv 24%. Korjaukset kannattaa tehdä suunnitellusti, suurempina kokonaisuuksina ja kilpailutettuna. Toinen vaihtoehto olisi peruskorjata hissiä laajemmin, eli uusia koko ohjaus, painonapit, ovikoneisto ja loppuunkuluneet osat. Laajemman peruskorjauksen hinta-arvio on n. 30 000 € alv 24 %. ",
  PHOTOS: "KUVAT",
  "Hissitaito Oy on puolueeton hissikonsultointiin erikoistunut yritys, joka tuottaa asiakkailleen valvontapalveluja ja erilaisia yksilöllisiä palveluja asiakkaiden tarpeiden mukaan. Tämä raportti liitteineen on Hissitaito Oy:n näkemys taloyhtiön hissien kunnosta ja arvio seuraavien vuosien kunnostustarpeista. Raporttia ei saa käyttää tarjouspyyntönä. Raportti on tarkoitettu ohjeelliseksi ja avuksi laadittaessa vuosittaisia kunnossapitobudjetteja. Hissien kuntokartoitus olisi hyvä päivittää n. viiden vuoden välein, jotta se pysyy ajan tasalla.":
    "Hissitaito Oy on puolueeton hissikonsultointiin erikoistunut yritys, joka tuottaa asiakkailleen valvontapalveluja ja erilaisia yksilöllisiä palveluja asiakkaiden tarpeiden mukaan. Tämä raportti liitteineen on Hissitaito Oy:n näkemys taloyhtiön hissien kunnosta ja arvio seuraavien vuosien kunnostustarpeista. Raporttia ei saa käyttää tarjouspyyntönä. Raportti on tarkoitettu ohjeelliseksi ja avuksi laadittaessa vuosittaisia kunnossapitobudjetteja. Hissien kuntokartoitus olisi hyvä päivittää n. viiden vuoden välein, jotta se pysyy ajan tasalla.",
  Door: "",
  // Mirror: "",
  Engine: "Moottori",
  "Engine room cleanliness": "Konehuoneen siisteys",
  Machinery: "Koneisto",
  "Folding wheels": "Taittopyörät",
  Change: "Muuta",
  "Frequency converter": "Taajuusmuuttaja",
  "Floor divider": "Kerrosjakaja",
  "Lock track": "Lukkorata",
  "Drive wheel": "Vetopyörä",
  "Speed Limiter": "Nopeudenrajoittaja",
  Breaks: "Jarru",
  "Speed limiter rope": "Nopeudenrajoittajan köysi",
  "Limit switches": "Rajakytkimet",
  "Support ropes or straps": "Kannatinköydet tai hihnat",
  "Panel Fittings": "",
  "Panel Cover": "",
  "Main contactors": "Pääkontaktorit",
  "Auxiliary contactors / relays": "Apukontaktorit / releet",
  Electronics: "Elektroniikka",
  "Engine protection": "Moottorisuoja",
  "Electrical drawings": "Sähköpiirustukset",
  "Maintenance instructions": "Huolto-ohjeet",
  "Reserve power": "Varavoima",
  "Car interior": "Korin sisustus",
  "Emergency phone": "Hätäpuhelin",
  Mirror: "Peili",
  "Information board": "Informaatiotaulu",
  // Lighting: "Valaistus",
  "Buttons / displays": "Painikkeet / näytöt",
  "Ride Comfort": "Ajomukavuus",
  "Car's door": "Korin ovi",
  "Safety Devices": "Turvalaitteet",
  "Door motor and control": "Ovimoottori ja ohjaus ",
  "Door straps, fasteners, wheels": "Ovihihnat, kiinnikkeet, pyörät",
  "Car Cables": "Korikaapelit",
  "Limit switches/Magnetic switches": "Rajakytkimet/Magneettikytkimet",
  "Lubrication equipment": "Voitelulaitteet",
  "Sticker maker": "Tarraaja",
  "Steering shoes": "Ohjauskengät",
  Conductors: "Johteet",
  Lighting: "Valaistus",
  Bumpers: "Puskurit",
  Counterweight: "Vastapaino",
  "Shaft botton painting": "Kuilunpohjan maalaus",
  Cleanliness: "Siisteys",
  "Floor doors": "Tason ovet",
  "Door rollers": "Ovirullat",
  Locks: "Lukot",
  "Door switches": "Ovikoskettimet",
  "Door's lower controllers": "Oven alaohjaimet",
  Buttons: "Painikkeet",
  Fronts: "Edustat",
  "Displays / indicator lights": "Näyttäjät / merkkivalot",
  "Select date": "Valitse päivämäärä",
  "Please select atleast one search criteria":
    "Ol ehyvä ja valitse ainaki yksi hakukriteeri",
  "No Inspection Reports Found": "Tarkastusraportteja ei löytynyt",
  "Elevator's inspection report": "HISSIN KUNTOKARTOITUSRAPORTTI ",
  Respectfully: "Kunnioittavasti",
  "Inspection Report Not Saved": "Kuntokartoitusraportti ei tallennettu",
  "Inspection Report Saved Successfully": "Kuntokartoitusraportti tallennettu",
  "Edit User": "Muokkaa käyttäjää",
  "Pre Tender successfully accepted": "Alustava kilpailutus hyväksytty",
  "Sent recommendation.": "Lähetetty suositus.",
  "Housing Company To Be Inspected": "Asuntoyhtiö tarkastettava",
  "Housing Company": "Asuntoyhtiö",
  datepickerLocale: {
    lang: {
      locale: "fi_FI",
      placeholder: "Päivämäärä",
      rangePlaceholder: ["Start date", "End date"],
      today: "Tänään",
      now: "Now",
      backToToday: "Back to today",
      ok: "Okei",
      clear: "Clear",
      month: "kk",
      year: "Year",
      timeSelect: "Select time",
      dateSelect: "Select date",
      monthSelect: "Choose a month",
      yearSelect: "Choose a year",
      decadeSelect: "Choose a decade",
      yearFormat: "YYYY",
      dateFormat: "D.M.YYYY",
      dayFormat: "D",
      dateTimeFormat: "D.M.YYYY HH:mm:ss",
      // "monthFormat": "MMMM",
      monthBeforeYear: true,
      previousMonth: "Previous month (PageUp)",
      nextMonth: "Next month (PageDown)",
      previousYear: "Last year (Control + left)",
      nextYear: "Next year (Control + right)",
      previousDecade: "Last decade",
      nextDecade: "Next decade",
      previousCentury: "Last century",
      nextCentury: "Next century",
      shortWeekDays: ["Sun", "ma", "Ti", "Kes", "Tor", "Per", "Lau"],
      shortMonths: [
        "Tammi",
        "Helmi",
        "Maalis",
        "Huhta",
        "Touko",
        "Kesä",
        "Heinä",
        "Elo",
        "Syksy",
        "Loka",
        "Marras",
        "Joulu",
      ],
    },
    timePickerLocale: {
      placeholder: "Select time",
    },
    dateFormat: "DD.MM.YYYY",
    dateTimeFormat: "DD.MM.YYYY HH:mm:ss",
    weekFormat: "wo.YYYY",
    monthFormat: "MM.YYYY",
  },
  sorry_no_matching_records_found: "Valitettavasti vastaavia tietueita ei löytynyt",
  sort: "Järjestellä",
  sort_for: "Lajittele",
  next_page: "Seuraava sivu",
  previous_page: "Edellinen sivu",
  rows_per_page: "Rivit per sivu:",
  of2: "/",
  search: "Hae",
  download_csv: "Lataa CSV",
  print: "Tulosta",
  view_columns: "Näytä sarakkeet",
  filter_table: "Suodatintaulukko",
  all: "Kaikki",
  filters: "Suodattimet",
  reset: "Nollaa",
  show_columns: "Näytä sarakkeet",
  show_hide_table_columns: "Näytä/piilota taulukon sarakkeet",
  rows_selected: "rivi(t) valittu",
  delete: "Poista",
  delete_selected_rows: "Poista valitut rivit",
};
