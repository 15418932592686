/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { Modal, Spin, message } from "antd";
// import { EditOutlined } from "@ant-design/icons";

import "../../Tender/Summary/Summary.css";
import { domainUrl, awsDocumentUrl } from "../../../helper";
import { genHeaders } from "../../Tender/Tender";
import EmailFormatter from "../../EmailFormatter";
import ConfirmationBox from "../../ConfirmationBox";
import { sendEmailCommunication } from "../../../actions/communication";
import { useDispatch } from "react-redux";
import { FormattedMessage,useIntl } from "react-intl";
import { changeDateFormat } from '../../../utils/common';

const emailSections = [
  {
    value: "Tender Info",
    selected: false,
  },
  {
    value: "List of chosen Supplier",
    selected: false,
  },
  {
    value: "Attached File",
    selected: false,
  },
];

const Summary = ({ tenderId }) => {
  const { onStepChange: move } = {
    onStepChange: null,
  };

  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [sentEmailModel, setSentEmailModel] = useState(false);
  const [showModifyModal, setShowModifyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [confirmMsg, setConfirmMsg] = useState('');

  // const editIconProps = {
  //   style: {
  //     borderRadius: "50%",
  //     color: "white",
  //     background: "#2B7776",
  //     padding: "10px",
  //   },
  // };

  const onFileClick = async (file) => {
    try {
      if (file?.document_id) {
        const documentDetails = await axios.get(
          `${awsDocumentUrl}/Prod/v1/document/${file.document_id}`
        );

        let link;
        if ((link = documentDetails?.data?.data?.signed_link)) {
          const win = window.open(link, "_blank");
          win.focus();
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const getInfo = async () => {
      const assetsCall = axios
        .get(`${domainUrl}/tender/v1/tender/${tenderId}/asset`, genHeaders())
        .catch((e) => {
          console.error(e);
          return null;
        });

      const generalInfoCall = axios
        .get(`${domainUrl}/tender/v1/tender/${tenderId}`, genHeaders())
        .catch((e) => {
          console.error(e);
          return null;
        });

      const filesCall = axios
        .get(
          `${awsDocumentUrl}/Prod/v1/document?entity_type=T&entity_id=${tenderId}`,
          { token: sessionStorage.getItem("token") }
        )
        .catch((e) => {
          console.error(e);
          return null;
        });

      const suppliersCall = axios
        .get(`${domainUrl}/tender/v2/tender/${tenderId}/supplier`, genHeaders())
        .catch((e) => {
          console.error(e);
          return null;
        });

      const companiesCall = axios
        .get(`${domainUrl}/tender/v2/tender/${tenderId}/company`, genHeaders())
        .catch((e) => {
          console.error(e);
          return null;
        });

        const headers = {
          headers: {
            client_code: "ht_front",
            token: sessionStorage.getItem("token"),
          },
        };
    
        const bidDetails = axios
          .get(`/bid/v1/tender/${tenderId}/bid?is_submitted=1`, genHeaders())
          // .then((response) => {
          //   if (response.data.success) {
          //     setLoading(false);
          //     setData(response.data.data.result);
          //     setAwardedSupplier(response?.data?.data?.result[0]?.winner_id);
          //   } else {
          //     message.error(response.data.message);
          //   }
          // })
          .catch((error) => {
            console.error(error);
            message.error("Could not receive bid information");
            return null;
            // history.goBack();
          });

      try {
        let title,
          is_awarded,
          description,
          tender_type,
          tender_type_other,
          additional_info,
          items,
          files,
          closing_date,
          suppliers,
          bids,
          companies;

        const [
          assetsRes,
          generalInfoRes,
          filesRes,
          suppliersRes,
          companiesRes,
          bidsRes,
        ] = await Promise.all([
          assetsCall,
          generalInfoCall,
          filesCall,
          suppliersCall,
          companiesCall,
          bidDetails,
        ]);

        if (assetsRes) {
          const itemsRes = await Promise.all(
            assetsRes.data.data.assets.map((el) =>
              axios
                .get(
                  `${domainUrl}/tender/v2/tender/asset/${el.tender_line_id}/items`,
                  genHeaders()
                )
                .catch((e) => {
                  console.error(e);
                  return [];
                })
            )
          );

          items = itemsRes.map((el, index) => {
            const key =
              assetsRes?.data?.data?.assets[index]?.line_text ??
              assetsRes?.data?.data?.assets[index]?.asset_name;
            const value = el.data.data.items;
            return {
              key,
              value,
            };
          });
        }

        if (generalInfoRes) {
          ({
            title,
            is_awarded,
            description,
            additional_info,
            tender_type,
            tender_type_other,
            closing_date,
          } = generalInfoRes.data.data);

          tender_type = tender_type === "r" ? "Repair" :tender_type === "m" ? "Maintenance" : tender_type_other;
        }

        if (filesRes) {
          files = filesRes?.data?.data?.documents;
        }

        if (suppliersRes) {
          suppliers = suppliersRes?.data?.data?.suppliers;
        }

        if (companiesRes) {
          companies = companiesRes?.data?.data?.companies;
        }
        if (bidsRes) {
          bids = bidsRes?.data?.data?.result;
        }

        setInfo({
          title,
          is_awarded,
          description,
          tender_type,
          tender_type_other,
          closing_date,
          additional_info,
          items,
          files,
          suppliers,
          companies,
          bids,
        });
      } catch (e) {
        console.error(e);
        message.error(
          e?.message ?? "Unable to retrieve information from server."
        );
      } finally {
        setLoading(false);
      }
    };

    getInfo();
  }, []);

  const onDelete = async () => {
    setDeleting(true);
    try {
      //TODO: tenders once created should not be deleted as per spec, plz make sure it is only disabled in backend.not deleted.
      await axios.delete(`/tender/v1/tender/${tenderId}`, genHeaders());
      await dispatch(sendEmailCommunication('DELETE_TENDER', {tender_id: parseInt(tenderId)}));
      history.goBack();
      message.success(intl.formatMessage({ id: "tender_delete_success" }));
    } catch (e) {
      console.error(e);
      message.error("Unable to delete tender.");
    } finally {
      setDeleting(false);
      setShowDeleteModal(false);
    }
  };

  const modifyTender = () => {
    var currentDate = new Date();
    var closingDate = new Date(info?.closing_date);
    if(currentDate.getTime() > closingDate.getTime()){
      message.error("Closing date passed.");
      return false;
    }
    setConfirmMsg(<FormattedMessage id="sure_to_modify_tender" />);
    setShowModifyModal(true)
  }

  const declineSubmit = () => {
    setShowModifyModal(false);
    setConfirmMsg('');
}

const confirmSubmit = async () => {
  setShowModifyModal(false);
  setConfirmMsg('');
  history.push(`/tender/${tenderId}`)
};

  if (loading)
    return <Spin style={{ display: "flex", justifyContent: "center" }} />;
  return (
    <div className="summaryOuter">
      <div className="summaryHeadder">
        <h2><FormattedMessage id="summary" /></h2>
      </div>
      <div className="mainContainer">
        <div className="block01">
          {/* <div className="topLeftInfo"> */}
          {/* <svg */}
          {/* xmlns="http://www.w3.org/2000/svg" */}
          {/* width="61.659" */}
          {/* height="57.254" */}
          {/* viewBox="0 0 61.659 57.254" */}
          {/* > */}
          {/* <path */}
          {/* id="Icon_ionic-ios-star" */}
          {/* data-name="Icon ionic-ios-star" */}
          {/* d="M61.569,23.194H41.324L35.172,4.834a2.229,2.229,0,0,0-4.184,0l-6.152,18.36H4.452a2.209,2.209,0,0,0-2.2,2.2,1.618,1.618,0,0,0,.041.372,2.116,2.116,0,0,0,.922,1.555l16.64,11.726L13.467,57.615a2.208,2.208,0,0,0,.757,2.477,2.13,2.13,0,0,0,1.239.537,2.7,2.7,0,0,0,1.376-.5L33.08,48.559,49.32,60.134a2.579,2.579,0,0,0,1.376.5,1.977,1.977,0,0,0,1.225-.537,2.181,2.181,0,0,0,.757-2.477L46.292,39.049l16.5-11.836.4-.344a2.309,2.309,0,0,0,.716-1.473A2.331,2.331,0,0,0,61.569,23.194Z" */}
          {/* transform="translate(-2.25 -3.375)" */}
          {/* fill="#21d59b" */}
          {/* /> */}
          {/* </svg> */}
          {/* <span>Consultation purchased from Janne</span> */}
          {/* </div> */}
        </div>
        <div className="textBlock">
          <div className="labelBLock"><FormattedMessage id="title" /></div>
          <div className="contentBLock">{info?.title}</div>
        </div>
        <div className="textBlock">
          <div className="labelBLock"><FormattedMessage id="desc" /></div>
          <div
            style={{ whiteSpace: "pre-wrap" }}
            className="contentBLock"
            dangerouslySetInnerHTML={{ __html: info?.description }}
          ></div>
        </div>
        <div className="textBlock">
          <div className="labelBLock"><FormattedMessage id="tenderType" /></div>
          <div className="contentBLock">{info?.tender_type}</div>
        </div>
        <div className="textBlock">
          <div className="labelBLock"><FormattedMessage id="company" /></div>
          <div className="contentBLock">{info?.companies && info?.companies?.map(el=>{return el.company_name})?.toString()}</div>
        </div>
      </div>

      <div className="mainContainer">
        <div className="titleSummary">
          <div className="titile"><FormattedMessage id="items" /></div>
        </div>
        {info?.items?.map((item, index) => (
          <React.Fragment key={index}>
            <div
              style={{ margin: "10px 0", flexDirection: "column" }}
              className=" textBlock list01"
            >
              <div className="labelBLock">{item?.key}</div>
              <ul>
                {item?.value?.map((i, itemIndex) => (
                  <li key={itemIndex}>{i?.item_text}</li>
                ))}
              </ul>
            </div>
            <div className="bdrb"></div>
          </React.Fragment>
        ))}
      </div>

      <div className="mainContainer">
        <div className="titleSummary">
          <div className="titile"><FormattedMessage id="files" /></div>
        </div>

        {info?.files?.map((file, fileIndex) => (
          <div
            onClick={() => onFileClick(file)}
            key={fileIndex}
            style={{ cursor: "pointer" }}
            className="filesNames mt-2"
          >
            <div className="filesDes">
              <span><FormattedMessage id="file_desc" /></span>
              <span>{file?.file_description}</span>
            </div>
            <div className="fileFormat" style={{ padding: "0 20px" }}>
              <div className="fileType">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="51.467"
                  height="68.622"
                  viewBox="0 0 51.467 68.622"
                >
                  <path
                    id="Icon_awesome-file-image"
                    data-name="Icon awesome-file-image"
                    d="M51.467,16.344v.812H34.311V0h.812A3.217,3.217,0,0,1,37.4.942L50.525,14.069A3.217,3.217,0,0,1,51.467,16.344Zm-18.228,5.1a3.226,3.226,0,0,1-3.217-3.217V0H3.217A3.217,3.217,0,0,0,0,3.217V65.406a3.217,3.217,0,0,0,3.217,3.217H48.25a3.217,3.217,0,0,0,3.217-3.217V21.444ZM15.084,23.589a6.433,6.433,0,1,1-6.433,6.433A6.433,6.433,0,0,1,15.084,23.589ZM42.962,55.756H8.651l.065-6.5,5.3-5.3a1.532,1.532,0,0,1,2.209.065l5.3,5.3L35.391,35.448a1.608,1.608,0,0,1,2.275,0l5.3,5.3Z"
                    fill="#2B7776"
                  />
                </svg>
              </div>
              <div className="fileInfo">
                <div className="text">{file?.file_name}</div>
                {/* <div className="fileSize">200kb</div> */}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mainContainer">
        <div className="titleSummary">
          <div className="titile"><FormattedMessage id="additional_info" /></div>
        </div>
        <p
          style={{ whiteSpace: "pre-wrap" }}
          className="contentBLock"
          dangerouslySetInnerHTML={{ __html: info?.additional_info }}
        ></p>
      </div>
      <div className="mainContainer">
        <div className="titleSummary">
          <div className="titile"><FormattedMessage id="chose_suppliers" /></div>
        </div>
        <div className="list01">
          <ul>
            {info?.suppliers?.map((supplier, index) => (
              <li key={index}>{supplier?.supplier_name}</li>
            ))}
          </ul>
        </div>
      </div>
      {
        info.bids?.length > 0 && (
        <div className="mainContainer">
          <div className="titleSummary">
            <div className="titile"><FormattedMessage id="Bids_Receieved" /></div>
          </div>
          <div className="list01">
            <ul>
              {info?.bids?.map((bid, index) => (
                <li key={index}>{bid?.supplier_name}
                  <ol>
                    <li>Total : €{bid?.sub_total}</li>
                  </ol>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {
        info?.closing_date && 
          <div className="mainContainer">
            <div className="textBlock">
              <div className="labelBLock"><FormattedMessage id="closing_date" /></div>
              <div className="contentBLock">{changeDateFormat(info?.closing_date,intl?.locale)} {}</div>
            </div>
          </div>
      }
      {
        info?.is_awarded !== 1 && 
        <div
        className="d-flex j-space"
        style={{ marginBottom: 20, flexWrap: "wrap" }}
      >
        <Link className="btn" to={`/bid/received/${tenderId}/bids`}>
          <FormattedMessage id="evaluate_bids" />
        </Link>
        <div
          onClick={modifyTender}
          className="btn"
          style={{ background: "#fabf01" }}
        >
          <FormattedMessage id="modify_tender" />
        </div>

        <div
          onClick={() => setShowDeleteModal(true)}
          className="btn"
          style={{ background: "red" }}
        >
          <FormattedMessage id="delete_tender" />
        </div>
        <div onClick={() => setSentEmailModel(true)} className="btn">
        <FormattedMessage id="email_info" />
        </div>
      </div>
      }
     
      {sentEmailModel && (
        <EmailFormatter
          isOpen={sentEmailModel}
          onClose={() => setSentEmailModel(false)}
          contentList={emailSections}
          tender_id={tenderId}
        />
      )}

    <ConfirmationBox showConfirmationBox={showModifyModal} message={confirmMsg } confirmSubmit={confirmSubmit} declineSubmit={declineSubmit} />

      {/* <Modal open={showModifyModal} footer={null}>
        <div className="hedding">
          <h2>Reason for modifying tender?</h2>
        </div>

        <div className="d-flex f-col">
          <textarea className="comments"></textarea>

          <Link className="btn" to={`/tender/${tenderId}`}>
            Continue
          </Link>
        </div>
      </Modal> */}
      <Modal open={showDeleteModal} footer={null} onCancel={()=>setShowDeleteModal(false)}>
        <div className="hedding">
          <h2><FormattedMessage id="are_you_sure_you_want_to_delete_this_tender" /></h2>
        </div>

        <div className="d-flex f-col">
          <div className={`btn ${deleting ? 'disabled' : ''}`} onClick={!deleting ? onDelete : null} style={{ position: 'relative', pointerEvents: deleting ? 'none' : 'auto' }}>
            {deleting ? <Spin style={{ marginRight: '10px' }} /> : <FormattedMessage id="are_you_sure_you_want_to_delete_this_tender" />}
          </div>
          <div className="btn" onClick={() => setShowDeleteModal(false)} style={{ pointerEvents: deleting ? 'none' : 'auto' }}>
            <FormattedMessage id="no" />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Summary;
