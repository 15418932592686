import React from 'react';
import { useParams, useHistory } from "react-router-dom";
import Header from "../../components/Header";
import { Rate,Form,Input,Button, Checkbox, message } from 'antd';
import 'antd/dist/antd.css';
import "./addrating.less"

import {
  createSuppliersRating
} from "../../actions/suppliersRating";
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
const AddRating = () => {
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const { tenderId } = useParams(); 
  const history = useHistory();
  const intl = useIntl();

  const submitSupplierRating = async (values) => {
    values.anonymous = values.anonymous ? '1' : '0';
    values.rating = String(values.rating);
    values.tender_id = tenderId;
    const result = await dispatch(createSuppliersRating(values, {}));
    if(result?.error) {
      message.error(intl.formatMessage({id:"rating_error"}));
    }
    else {
      message.success(intl.formatMessage({id:"rating_submitted"}));
      setTimeout(() => {
        history.push('/');
      },500);
    }
}

	return (
    <>
      <Header />
      <div className="mainContainer profileContainer">
      	<div className="innerContainer">
          <Form
          labelCol={{span:6}} onFinish={submitSupplierRating}
          >
            <Form.Item label={intl.formatMessage({id:"Add Rating"})} name="rating">
              <Rate className="rate"/></Form.Item>
              <Form.Item label={intl.formatMessage({id:"Add Comment"})} name="comment">
      <TextArea onResize="none" rows={3}  />
    </Form.Item>
    <Form.Item name="anonymous" valuePropName="checked" wrapperCol={{ offset: 6, span: 18 }}>
        <Checkbox><FormattedMessage id="submitanonymously"/></Checkbox>
      </Form.Item>
    <Form.Item style={{display:"flex",justifyContent:"center"}} className="Buttonsub">
      <Button htmlType="submit" type="primary">
        <FormattedMessage id="submit"/>
      </Button>
    </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};
 export default AddRating;
