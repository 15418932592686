/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import {ArrowLeftOutlined} from '@ant-design/icons';

import ReportVault from "./ReportVault";
import ReportAsset from "./ReportAsset";
import { FormattedMessage } from "react-intl";

const InspectionSearchVault = ({isModalVisible, inspectionReports, handleCancel}) => {
  const data = [
    {
      name: 'HC1',
      countString: '5 Assets'
    },
    {
      name: 'HC2',
      countString: '5 Assets'
    },
    {
      name: 'HC2',
      countString: '5 Assets'
    }
  ];
  const [step, setStep] = useState(1);
  const [header, setHeader] = useState('');
  const [vaultData, setVaultData] = useState([]);
  const [HCData, setHCData] = useState({});
  const [assetData, setAssetData] = useState({});

  const getData = (reportStep) => {
    setVaultData([]);
    if(reportStep === 1){
      let data = [];
      Object.keys(inspectionReports).map(hc => {
        let obj = {};
        obj.name = inspectionReports[hc].company_name;
        let countText = Object.keys(inspectionReports[hc].assets).length > 1 ? ' Assets' : ' Asset';
        obj.countString = Object.keys(inspectionReports[hc].assets).length+ countText;
        obj.key = hc;
        data.push(obj);
      });
      setVaultData(data);
      setHeader(<FormattedMessage id="inspection_report_vault" />);
    }
    if(reportStep === 2) {
      let data = [];
      Object.keys(HCData.assets).map(asset => {
        let obj = {};
        obj.name = HCData.assets[asset][0].asset_name;
        let countText = HCData.assets[asset].length > 1 ? ' Reports' : ' Report';
        obj.countString = HCData.assets[asset].length+ countText;
        obj.key = asset;
        data.push(obj);
      });
      setVaultData(data);
      setHeader(HCData.company_name);
    }
    if(reportStep === 3)
      setHeader(assetData[0].asset_name);
  }

  useEffect(() => {
    getData(step);
  }, [step,HCData,assetData]);

  const onVaultSelect = (key) => {
    let dataStep = step + 1;
    if(step === 1) {
      setHCData(inspectionReports[key]);
    }
    if(step === 2) {
      setAssetData(HCData.assets[key]);
    }

    setStep(dataStep);

  }

  const goBack= () => {
    let dataStep = step - 1;
      setStep(dataStep);
  }

	return (
		<Modal className="vault-modal" centered destroyOnClose maskClosable={false} open={isModalVisible} closable={true} footer={null} onCancel={handleCancel}>
        {
          step > 1 && 
            <ArrowLeftOutlined className="step-back" onClick={goBack}/>
        }
        <h2 className={step>1 ? 'header-center' : ''}>{header}</h2>
        {
          step <= 2 && 
          <ReportVault reportData={vaultData} onVaultSelect={onVaultSelect}/>
        }
        {
          step == 3 && 
          <ReportAsset data={assetData} onVaultSelect={onVaultSelect}/>
        }
    </Modal>
    )
};

InspectionSearchVault.propTypes = {
  isModalVisible: PropTypes.bool,
  inspectionReports: PropTypes.shape({}),
  handleCancel: PropTypes.func,
};

export default InspectionSearchVault;