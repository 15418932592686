import { schema } from "normalizr";
import { createAction } from "redux-api-middleware";
import {
	handleResponse,
	handleError,
    handleQuery,
	handleReducerResponse,
} from "../utils";
import { awsDocumentUrl } from "../helper";

export const FETCH_FILES_REQUEST = "FETCH_FILES_REQUEST";
export const FETCH_FILES_SUCCESS = "FETCH_FILES_SUCCESS";
export const FETCH_FILES_FAILURE = "FETCH_FILES_FAILURE";

export const FETCH_DOCUMENT_REQUEST = "FETCH_DOCUMENT_REQUEST";
export const FETCH_DOCUMENT_SUCCESS = "FETCH_DOCUMENT_SUCCESS";
export const FETCH_DOCUMENT_FAILURE = "FETCH_DOCUMENT_FAILURE";

export const DELETE_DOCUMENT_REQUEST = "DELETE_DOCUMENT_REQUEST";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_FAILURE = "DELETE_DOCUMENT_FAILURE";

export const filesSchema = new schema.Entity("files", [], {
	idAttribute: "document_id",
});

/**
 * Returns a redux api middleware action that makes a request for the files uploaded.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const fetchFiles = (params = {}) =>
	createAction({
		endpoint: `${awsDocumentUrl}/Prod/v1/document?${handleQuery(params)}`,
		// endpoint: `http://127.0.0.1:3000/v1/document?${handleQuery(params)}`,
		method: "GET",
		types: [
			{ type: FETCH_FILES_REQUEST },
			{
				type: FETCH_FILES_SUCCESS,
				payload: (action, state, res) =>
					handleResponse([filesSchema], action, state, res),
			},
			{ type: FETCH_FILES_FAILURE, payload: handleError },
		],
	});

/**
 * Returns a redux api middleware action that makes a request for a uploaded document.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const fetchDocument = (documentId) =>
    createAction({
        endpoint: `${awsDocumentUrl}/Prod/v1/document/${documentId}`,
        method: "GET",
        types: [
            { type: FETCH_DOCUMENT_REQUEST },
            {
                type: FETCH_DOCUMENT_SUCCESS,
                payload: (action, state, res) =>
                    handleResponse(filesSchema, action, state, res),
            },
            { type: FETCH_DOCUMENT_FAILURE, payload: handleError },
     ],
 });

 /**
 * Returns a redux api middleware action that makes a request to delete a document.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const deleteDocument = (documentId) =>
    createAction({
        endpoint: `${awsDocumentUrl}/Prod/v1/document/${documentId}`,
        method: "DELETE",
        types: [
            { type: DELETE_DOCUMENT_REQUEST },
            {
                type: DELETE_DOCUMENT_SUCCESS,
                payload: (action, state, res) =>
                    handleReducerResponse(action, state, res),
            },
            { type: DELETE_DOCUMENT_FAILURE, payload: handleError },
     ],
 });