/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
// import Moment from 'react-moment';
import { makeStyles } from "@material-ui/core/styles";
import { Table, Button } from 'antd';
import Paper from '@material-ui/core/Paper';
import axios from "axios";
import { FormattedMessage, useIntl } from "react-intl";
import ConfirmationBox from '../ConfirmationBox';
import { changeDateFormat } from '../../utils/common';
import { domainUrl }  from "../../helper";
import { compNames, getFilterValues } from '../../utils';

import {
    fetchNewSuppliersRequest,
    supplierRequestDecision,
} from "../../actions/suppliers";

const useStyles = makeStyles((theme) => ({
    
    favIcon: {
        display: 'flex',
        alignItems: 'center',
    },
    fav: {
        marginRight: 5,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 400,
      height: 45,
    border: 'solid 0.5px #d5d7e3',
    backgroundColor: '#e6e9f4',
    padding: '5px 15px',
    fontFamily: 'Poppins',
    fontSize: 16,
    color: '#3f3f41',
    },
    button: {
      margin: theme.spacing(1),
      borderRadius: 8,
      backgroundColor: '#2B7776',
      color: '#fff',
      fontFamily: 'Poppins',
      fontSize: 16,
    fontWeight: 500,
    },
    inviteWrapper: {
        padding: 20,
        marginTop: 30,

    },
    form: {
        margin: '0 auto',
        width: '95%',
    }
}));

const SupplierNewRequestList = ({ value, showFeedBack, viewSupplierDetails }) => {
	const intl = useIntl();
    const classes = useStyles();
    
    const [suppliersRequest, setSuppliersRequest] = useState([]);

    const [formEmailInput, setFormEmailInput] = useState(
        {
          supplier_email: ""
        }
      );
    const [pageSize, setPageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(1);
    const [message, setMessage] = useState('');
    const [showConfirmationBox, setShowConfirmationBox] = useState(false);
    const [decision, setDecision] = useState('');
    const [supplierId, setSupplierId] = useState();

    const dispatch = useDispatch();

    async function getSuppliers(page, pageSize) {
        const response = await dispatch(fetchNewSuppliersRequest({limit: pageSize, offset: pageSize*(page-1), status: 'pending',order_by: 'request_datetime', sort_by: 'DESC'}, true));
        const count = response?.payload?.meta?.count;
        setTotalCount(count);
        // const supplierRequestData = Object.values(response?.payload?.entities?.suppliersRequest || []);
        const supplierRequestData = Object.values(response?.payload?.result || []);
        setSuppliersRequest(supplierRequestData);
    }

    useEffect(() => {
        if(value == 0)
            getSuppliers(1, pageSize);
    }, [value]);

    // const mapCompanyIdNames = (assetData, companyData) => {
    // 	assetData.map((asset) => {
    // 		const assetCompany = companyData.find(
    // 			(company) => company.company_id === asset.company_id
    // 		);
    // 		asset.company_name = assetCompany.company_name;
    // 		asset.organization_name = assetCompany.organization_name;
    // 	});
    // 	setAssets(assetData);
    // };

    const columns = [{
            dataIndex: "supplier_name",
            title:intl.formatMessage({ id:'supplier_name'}),
            filters: getFilterValues("supplier_name", suppliersRequest),
			onFilter: (value, record) => record.supplier_name.indexOf(value) === 0,
			sorter: {
				compare: (a, b) => {
				return compNames(a.supplier_name, b.supplier_name);
				},
			},
        },
        {
            dataIndex: "request_datetime",
            title:intl.formatMessage({ id:'Date_recieved'}),
            render: (value, tableMeta, updateValue) => changeDateFormat(value,intl?.locale),
        },
        {
            dataIndex: "supplier_request_id",
            title:intl.formatMessage({ id:'view'}),
            width:75,
            render: (value, tableMeta, updateValue) => (
                <div
                    className="viewIcon"
                    onClick={() => openSupplierForm(value, { readOnly: true })}
                >
                  <FormattedMessage id={'view'} />
                </div>
            ),
        },
        {
            dataIndex: "supplier_request_id",
            title:intl.formatMessage({ id:'accept'}),
            width:150,
            render: (value, tableMeta, updateValue) => (
                <div
                    className="btn-primary"
                    onClick={() =>{
                        makeDecision(value, 'approve')
                      }
                    }
                >
                     <FormattedMessage id={'accept'} />
                </div>
            ),
        },
        {
            dataIndex: "supplier_request_id",
            title:intl.formatMessage({ id:'reject'}),
            width: 150,
            render: (value, tableMeta, updateValue) => (
                <div
                    className="btn-secondary"
                    onClick={() =>
                        makeDecision(value, 'reject')
                    }
                >
                 <FormattedMessage id={'reject'} />
                </div>
            ),
        },
    ];

    const declineSubmit = () => {
		setShowConfirmationBox(false);
		setMessage('' );
        setSupplierId('');
	}

    const makeDecision = (supplierId, decision) => {
        setMessage(decision == 'reject' ? intl.formatMessage({ id:'reject_supplier'}):intl.formatMessage({ id:'approve_supplier'}) );
        setSupplierId(supplierId);
        setDecision(decision);
        setShowConfirmationBox(true);
    }

    const acceptRejectRequest = async () => {
        setMessage('' );
        setShowConfirmationBox(false);
        const response = await dispatch(supplierRequestDecision(supplierId, { status: decision }));
        setSupplierId('');
        const responseStatus = response.payload.body;
        if (responseStatus && !responseStatus?.success) {
            showFeedBack({
                open: true,
                success: false,
                error: true,
                info: false,
                message: responseStatus?.error?.message,
            })
            
        } else {
            getSuppliers(1, pageSize);
            showFeedBack({
                open: true,
                success: true,
                error: false,
                info: false,
                message: `The request has been ${decision === 'approve' ? 'approved' : 'rejected'} successfully`,
            })
        }
        
    }

    const getSelectedSupplier = (supplier_request_id) => {
    	return suppliersRequest.find(supplier => supplier.supplier_request_id === supplier_request_id);
    };

    const openSupplierForm = (supplier_request_id, opts) => {
        let selected = getSelectedSupplier(supplier_request_id);
        viewSupplierDetails(selected, opts);
    };

    const handleInput = evt => {
        const name = evt.target.name;
        const newValue = evt.target.value;
        setFormEmailInput({ [name]: newValue });
    };

    const handleSubmit = evt => {
        evt.preventDefault();

        axios
        .post(
          `${domainUrl}/masterservicenew/master/supplier_request/invite`,
          formEmailInput,
          {
            headers: {
                token: sessionStorage.getItem("token"),
                client_code: "ht_front",
            },
          }
        )
        .then(() => {

            showFeedBack({
                open: true,
                success: true,
                error: false,
                info: false,
                message:intl.formatMessage({ id:'invitation_send'}),
            })
        })
        .catch(function (error) {
            if (error.response) {
                if(error.response.status==400){
                    showFeedBack({
                        open: true,
                        success: false,
                        error: true,
                        info: false,
                        message: intl.formatMessage({ id:'unable_to_send_invitation_to_supplier'}),
                    })

                }else{
                    showFeedBack({
                        open: true,
                        success: false,
                        error: true,
                        info: false,
                        message: intl.formatMessage({ id:'unable_to_send_invitation_to_supplier'}),
                    })
                }
  

            }
          })

        .finally(() => {
          setFormEmailInput({
              supplier_email: ""
          })
        });
    };

    const onPaginationChange = (pageDetails) => {
        setPageSize(pageDetails.pageSize);
        getSuppliers(pageDetails.current, pageDetails.pageSize);
    }

   return (
		<>
		
            <Table
                className="ant-table-root"
                // scroll={{ x: 0, y: '40vh' }}
                columns={columns}
                dataSource={suppliersRequest}
                pagination={{ defaultPageSize: pageSize, showSizeChanger: false, total: totalCount }}
                onChange={onPaginationChange}
                bordered
                rowKey='supplier_request_id'
                locale={{emptyText: intl.formatMessage({ id:'no_data'})}}
            />
			<div className="invite">
				<Paper elevation={3} className={classes.inviteWrapper}>
                    <form onSubmit={handleSubmit} className={classes.form}>
                    <label> <FormattedMessage id={'invite_suppliers'} /></label>
                    <input
                      className={classes.textField}
                            placeholder={intl.formatMessage({ id:'Enter_emailid'})}
                            onChange={handleInput}
                      type="text"
                      name="supplier_email"
                      value={formEmailInput.supplier_email}
                    />
                       
                        <Button
                            htmlType="submit"
                            type="primary"
                            className='btn'
                            style={{borderRadius: "8px"}}
                          >
                            <FormattedMessage id={'sendinvitation'} />
                          </Button>
                    </form>
                </Paper>
			</div>
            <ConfirmationBox showConfirmationBox={showConfirmationBox} message={message} confirmSubmit={acceptRejectRequest} declineSubmit={declineSubmit} />
		
		</>
	);
};

export default SupplierNewRequestList;