/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import Header from "../Header/Header";
import { Row, Col } from "antd";
import Tenders from "./Tenders/Tenders";
import "./Dashboard.css";
import MessagesCard from "../MessagesCard";
import profilePic from "../../assets/user-img2.png";
import profilePic2 from "../../assets/user-img3.png";
import profilePic3 from "../../assets/user-img4.png";

const Dashboard = (props) => {
  return (
    <>
      <Header />
      <div className="container-fluid">
        <Row /*gutter={[20, 3]} style={{width: '100%'}}*/ style={{marginTop: '10px'}} justify="center">
          <Col md={24} xl={14} className="leftSidePanel">
            <Tenders />
          </Col>
          <Col  md={24} xl={10} className="rightSidePanel">
            <MessagesCard />
          </Col>
        </Row>
      </div>
    </>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
