/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";

import { Card, Form, Input, Button, Select, Spin } from 'antd';
import MessageToast from "../MessageToast";
import { FormattedMessage, useIntl } from "react-intl";
import { fetchCompanies } from "../../actions/company";
import {
	fetchAssets,
} from "../../actions/assets";

import { fetchAllInspectionReports } from '../../actions/inspection-report';

import { groupByKey } from '../../utils';
import { get } from 'lodash';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 16 },
};

const { Option } = Select;

const InspectionSearch = ({reportsReceived}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const roleId = sessionStorage.getItem('role_id');
  const userId = sessionStorage.getItem('user_id');
  const [companyNames, setCompanyNames] = useState([]);
  const [businessId, setBusinessId] = useState([]);
  const [orgNames, setOrgNames] = useState([]);
  const [assetNames, setAssetNames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(state => get(state, `entities.users.${userId}`));
  const [showFeedback, setShowFeedback] = useState({
    open: false,
    success: false,
    error: false,
    info: false,
    message: "",
  });

  const [searchObj, setSearchObj] = useState({
    company_id: '',
    inspection_name: '',
    address: '',
    business_id: '',
    organization_name: '',
    asset_name: '',
    limit: 1000,
  })

  const segKeyData = (key,valueKey, data) => {
    let keyArray = data.map(obj => { 
      return {label: obj[key], value: obj[valueKey]}});
      return keyArray.filter((value, index, self) => self.map(x => x.value).indexOf(value.value) == index)
  }

  useEffect(() => {
    async function getCompanyData() {
      let params = {limit: 1000};
      if(roleId == 2){
        params.organization_id = sessionStorage.getItem('entity_id');
      }
			const response = await dispatch(fetchCompanies(params));
			const companyData = Object.values(response.payload.entities?.companies || []);
			setCompanyNames(segKeyData('company_name','company_id',companyData));
      setBusinessId(segKeyData('business_id','business_id',companyData));
      setOrgNames(segKeyData('organization_name','organization_id',companyData));
		}
		getCompanyData();
  },[]);

  const onFinish = async (values) => {
    setIsLoading(true);
    let params = {};
    Object.keys(values).map(key => {
      if(values[key])
        params[key] = values[key];
    })
    if(Object.keys(params).length === 0) {
      setShowFeedback({
        open: true,
        success: false,
        error: true,
        info: false,
        message: intl.formatMessage({id:"Please select atleast one search criteria"}),
      });
      setIsLoading(false);
      return;
    }
    params.limit = 100;
    const response = await dispatch(fetchAllInspectionReports(params));
    if(response?.error || Object.values(response.payload.entities).length === 0) {
      setShowFeedback({
        open: true,
        success: false,
        error: true,
        info: false,
        message: intl.formatMessage({id:"No Inspection Reports Found"}),
      });
      setIsLoading(false);
      return;
    }
    const reportData = Object.values(response.payload.entities?.inspections || []);
    const groupedByHC = groupByKey(reportData, 'company_id');
    let clonedGroupedByHC = Object.assign({},groupedByHC);
    Object.keys(clonedGroupedByHC).map(key => {
      const groupedByAsset = groupByKey(clonedGroupedByHC[key], 'asset_id');
      let data = [...groupedByHC[key]];
      groupedByHC[key] = {};
      groupedByHC[key].company_name = data[0].company_name;
      groupedByHC[key].company_id = data[0].company_id;
      groupedByHC[key].organization_id = data[0].organization_id;
      groupedByHC[key].assets = groupedByAsset;

    });
    setIsLoading(false);
    reportsReceived(groupedByHC);
  };

  const onFinishFailed = (errorInfo) => {
  };

  const closeFeedback = () => {
    setShowFeedback({
      open: false,
      success: false,
      error: false,
      info: false,
      message: "",
    });
  };

  const handleSearch = async (value) => {
    if(value) {
      let params = {limit: 1000, asset_name: value};
      if(roleId == 2){
        params.created_by = user?.created_by;
      }
      const response = await dispatch(fetchAssets(params));
      const assetData = Object.values(response.payload.entities?.assets || []);
      setAssetNames(segKeyData('asset_name','asset_name',assetData));
    }
    else {
      setAssetNames([]);
    }
  }

  const handleChange = (value, key) => {
    setSearchObj({
      ...searchObj,
      key: value,
    })
  }

	return (
    <>
     {showFeedback.open && (
        <MessageToast
          open={showFeedback.open}
          success={showFeedback.success}
          error={showFeedback.error}
          message={showFeedback.message}
          onClose={closeFeedback}
        />
      )}
    <Spin spinning={isLoading}>
      <Card title={intl.formatMessage({id:"Search_inspiration_inspect"})} className="card-layout">
      <Form
      {...layout}
      name="basic"
      labelAlign='left'
      initialValues={{}}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label={ <FormattedMessage id="company_name"/>}
        name="company_id"
      >
        <Select
          showSearch
          allowClear
          style={{ width: '100%' }}
          placeholder={ <FormattedMessage id="select_company_name"/>}
          onChange={(value) => handleChange(value, 'company_id')}
          optionLabelProp="label"
          optionFilterProp="label"
          options={companyNames}
        >
        </Select>
      </Form.Item>

      <Form.Item
        label={<FormattedMessage id="inspection_report_name"/>}
        name="inspection_name"
      >
        <Input placeholder={intl.formatMessage({ id:'Enter_reportname'})} onChange={(value) => handleChange(value, 'inspection_name')}/>
      </Form.Item>

      <Form.Item
        label={<FormattedMessage id="Company_Adress"/>}
        name="address"
      >
        <Input placeholder={intl.formatMessage({ id:'Enter_CompanyAdress'})} onChange={(value) => handleChange(value, 'address')}/>
      </Form.Item>

      <Form.Item
        label={<FormattedMessage id="Company_Business_Id"/>}
        name="business_id"
      >
        <Select
          showSearch
          allowClear
          style={{ width: '100%' }}
          placeholder={<FormattedMessage id="Select_Business_ID"/>}
          onChange={(value) => handleChange(value, 'business_id')}
          optionLabelProp="label"
          optionFilterProp="label"
          options={businessId}
        >
        </Select>
      </Form.Item>

      <Form.Item
        label={<FormattedMessage id="Organisation_Name"/>}
        name="organization_id"
      >
        <Select
          showSearch
          allowClear
          style={{ width: '100%' }}
          placeholder={<FormattedMessage id="Organisation_Select"/>}
          onChange={(value) => handleChange(value, 'organization_id')}
          optionLabelProp="label"
          optionFilterProp="label"
          options={orgNames}
        >
        </Select>
      </Form.Item>

      <Form.Item
        label={<FormattedMessage id="Asset_Name"/>}
        name="asset_name"
      >
        <Select
          showSearch
          allowClear
          style={{ width: '100%' }}
          placeholder={<FormattedMessage id="Enter_Asset"/>}
          showArrow={false}
          onSearch={handleSearch}
          onChange={(value) => handleChange(value, 'asset_name')}
          notFoundContent={null}
          optionFilterProp="label"
          options={assetNames}
        >
        </Select>
      </Form.Item>

        <Button className="form-btn ant-custom-btn" type="primary" htmlType="submit">
        <FormattedMessage id="Search_inspiration_inspect"/>
        </Button>
    </Form>
      </Card>
      </Spin>
    </>
  );
};
 export default InspectionSearch;
