import React, { useEffect, useState } from "react";
// import axios from "axios";
import { FormattedMessage } from "react-intl";

import "./AdditionalInfo.css";
import { 
  // genHeaders, 
  useTender 
} from "../TenderCreationSteps";
import TextEditor from '../../TextEditor';
// import { domainUrl } from "../../../helper";
import { message, Spin } from "antd";
// import { useDispatch } from "react-redux";
import ToolTip from '../../ToolTip';

const AdditionalInfo = ({updateTenderData}) => {
  // const dispatch = useDispatch();
  const { tenderDetails, onStepChange: move, modifyData, tenderModifiedDetails } = useTender();

  const [info, setInfo] = useState(tenderDetails?.additional_info ?? "");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(tenderDetails?.is_published === 0) {
      setInfo(tenderDetails?.additional_info ?? "")
    }
    else
      setInfo(tenderModifiedDetails?.additional_info ?? "")
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[tenderDetails])

  const onNext = async() => {

    setLoading(true);


    try{
      if(tenderDetails.is_published !== 1) {
        //if (info.trim() !== "") {
          const status = await updateTenderData( {
            state: '8',
            additional_info: (!info || info === '') ? 'N/A' : info,
          });
          if(status) {
            move(1, { additional_info: info });
          }
          else {
            message.error("Unable to reach server.");
          }
       // }
        // else {
        //   move(1, { additional_info: info });
        // }
        }
        else {
          move(1, {
            additional_info: info,
          },true);
        }
    } catch (e) {
      message.error(e?.message ?? "An error occurred. Please try again.");
    } finally {
      setLoading(false); // Reset loading state after API call completes
    }


    
  };
  const onBack = () => {
    move(-1, { additional_info: info });
  };

  const saveAdditionalInfo = (data) => {
    setInfo(data);
  }

  const publishData = () => {
    move(0, {
      additional_info: (!info || info === '') ? 'N/A' : info,
    },true);
    modifyData();
  }

  return (
    <div className="__AdditionalInfo__">
      <div className="hedding">
        <h2><FormattedMessage id="additional_info" /><ToolTip text_id="tender.additional_info.info" placement="top" /></h2>
      </div>
      <div className="commentblock">
        <h3 className="left"><FormattedMessage id="additional_info" /></h3>
        <div className="right">
          <TextEditor textData={info} onChangeData={saveAdditionalInfo}/>
          
        </div>
      </div>
      <div className="buttonBlock">
        <div onClick={onBack} className="btn cancelBtn">
          <FormattedMessage id='back'/> </div>
        {
          tenderDetails?.is_published === 1 && 
          <div className="btn" onClick={publishData}>
            <FormattedMessage id="publish" />
          </div>
        }
        <div onClick={!loading ? onNext : null} className={`btn ${loading ? 'disabled' : ''}`} disabled={loading} >
          {loading ? <Spin style={{ marginRight: '10px' }} /> : <FormattedMessage id="next" />}
          </div>
      </div>
    </div>
  );
};

// Tender.propTypes = {};

export default AdditionalInfo;
