import React, { useState } from "react";

// import axios from "axios";
import { message, Spin } from "antd";
// import { DeleteOutlined } from "@ant-design/icons";

import { FormattedMessage, useIntl } from "react-intl";

import "../AdditionalInfo/AdditionalInfo.css";
import "./AttachFile.css";

import FileUpload from "../../../components/FileUpload";
import { 
  // awsDocumentUrl, 
  domainUrl 
} from "../../../helper";
import { 
  // genHeaders, 
  useTender 
} from "../TenderCreationSteps";

import ConfirmationBox from '../../ConfirmationBox';
// import { useDispatch } from "react-redux";
// import { updatePreTender } from "../../../actions/pre-tender";
import FileDisplay from "../../../components/FileDisplay";

const FILE_POST_URL = `${domainUrl}/tender/v1/document`;

const AttachFile = ({updateTenderData}) => {
  // const dispatch = useDispatch();
  const { tenderDetails, onStepChange: move } = useTender();

  const [fileToUploadList, setFileToUploadList] = useState(tenderDetails?.files ?? []);
  const [fileList, setFileList] = useState(tenderDetails?.files ?? []);
  const [isUploading, setIsUploading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const intl = useIntl();
  
  const onBack = () => {
    move(-1, { files: fileList });
  };

  const onNext = async() => {

    const fileArray = Array.isArray(fileList) ? fileList : Object.values(fileList);
    console.log(fileArray);
    if (fileArray.length === 0 || fileArray.every(file => !file.document_id)) {
      message.error(intl.formatMessage({ id: "attachfile_required" }));
      return;
    }
    
    const filesToUpload = fileToUploadList.filter(doc => doc.document_id === null);
    if(filesToUpload.length > 0) {
      setShowConfirmationModal(true);
    }
    else{
      setLoading(true); // Added to Handle Next Click Disable
      try{
        await updateTenderData({
          state: '6',
        })
        if(tenderDetails.is_published !== 1) {
          move(1, { files: fileList });
        }
        else {
          move(2, { files: fileList },true);
        }
      }catch (e) {
        message.error(e?.message ?? "An error occurred. Please try again.");
      } finally {
        setLoading(false); // Reset loading state after API call completes
      }


    }
  };

  const onUploadStatusChange = (e) => {
    setIsUploading(e);
  };

  const onFilesStatusChange = (e) => {
    setFileToUploadList(e);
  };

  const onFilesUploadStatusChange = (e) => {
    setFileList(e);
  };

  const confirmSubmit = (e) => {
    setLoading(true);
    setShowConfirmationModal(false);
    move(1, { files: fileList });
    setLoading(false);
  }

  const declineSubmit = (e) => {
    setShowConfirmationModal(false);
  }

  const addFileList = (files) => {
    setFileList({
      ...fileList,
      ...files
    })
  }

  return (
    <div className="__AttachFile__">
      <div className="parentBlock2">
        <FileUpload
          filePostURL={FILE_POST_URL}
          entityType={"T"}
          entityID={tenderDetails?.tender_id}
          onFilesStatusChange={onFilesStatusChange}
          addFileList={addFileList}
          onUploadStatusChange={onUploadStatusChange}
          onFilesUploadStatusChange={onFilesUploadStatusChange}
        />
        <FileDisplay entityType={'T'} setFileList={setFileList} fileList={fileList} entityId={tenderDetails?.tender_id ? tenderDetails?.tender_id?.toString() : ""} hideTitle={false} deleteFiles={true}/>
        <div className="buttonBlock">
          <div onClick={isUploading ? null : onBack} className="btn cancelBtn">
          <FormattedMessage id="bakc" />
          </div>
          <div onClick={!loading && !isUploading ? onNext : null} className={`btn ${loading || isUploading ? 'disabled' : ''}`} disabled={loading || isUploading}>
          {loading ? (<Spin style={{ marginRight: '10px' }} />) : (<FormattedMessage id="next" />)}
          </div>
          

          
        </div>
      </div>
      <ConfirmationBox showConfirmationBox={showConfirmationModal} message={<FormattedMessage id="you_have_added_files_but_not_uploaded_proceed_without_uploading"/> } confirmSubmit={confirmSubmit} declineSubmit={declineSubmit} />
    </div>
  );
};

export default AttachFile;
