import React  from 'react';
import { useParams } from 'react-router';

import Header from "../../components/Header";

import TenderBidDetails from '../../components/TenderBidDetails';
import Summary from "../../components/TenderDetailSupplier/Summary/Summary";

const BidSubmitted = () => {

  const {tenderId} = useParams();
	return (
    <>
    <Header />
      <div className="mainContainer">
      	<div className="innerContainer">
              <TenderBidDetails />
              <Summary tenderId={tenderId} hidebuttons={true} allowQs = {true}/>
              </div>
              </div>
    </>
  );
};
 export default BidSubmitted;
