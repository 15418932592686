import React, { useState, useEffect } from "react";
import axios from "axios";
// import { apiUrl, domainUrl } from "../../helper";
import { domainUrl } from "../../helper";
import { FormattedMessage, useIntl } from "react-intl";
import "./AddModal.css";
import { validURL } from "../../utils";
import FileDisplay from "../FileDisplay";
import FileUpload from "../FileUpload";
import { Select, Spin } from "antd";
const FILE_POST_URL = `${domainUrl}/tender/v1/document`;

const AddModal = (props) => {
  const intl = useIntl();
  const [company, setCompany] = React.useState(props.data);
  // const [isReadOnly, setIsReadOnly] = useState(props.readOnly);
  const [isReadOnly] = useState(props.readOnly);
  const [errorMessage, setErrorMessage] = React.useState({});
  const [organization, setOrganization] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  // const [fileToUploadList, setFileToUploadList] = useState(company?.files ?? []);
  const [fileList, setFileList] = useState(company?.files ?? []);
  const [isUploading, setIsUploading] = useState(false);
  function handleInputChange(event) {
    if(event.target.name === "contact" && event.target.value.match("^[+]*[0-9]*$") === null) {
      return;
    }
    if(event.target.name === "pincode" && event.target.value.match("^([0-9])*$") === null) {
      return;
    }
    const data = {
      ...company,
      [event.target.name]:
        event.target.name === "organization_id" ||
        event.target.name === "city_id"
          ? +event?.target?.value
          : event.target.value,
    };
    setCompany(data);
    setErrorMessage({});
  }
  function handleCityChange(event) {
    const data = {
      ...company,
      city_id:event
    }
    setCompany(data);
    setErrorMessage({});
  }

  useEffect(() => {
    if(props.role_id !== 2)
      getOrganization();
    getAllCountries();
    company && company.country_id && getAllCities(company.country_id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOrganization = () => {
    axios
      .get(`${domainUrl}/masterservicenew/master/organization?limit=100`, {
        headers: {
          token: sessionStorage.getItem("token"),
          client_code: "ht_front",
        },
      })
      .then((res) => {
        let data = res.data.data;
        setOrganization(data.result);
      })
      .catch((err) => {
        
      });
  };

  const getAllCountries = () => {
    axios
      .get(
        `${domainUrl}/masterservicenew/master/country?limit=100&offset=0&order_by=created_at&sort_by=ASC`,
        {
          headers: {
            token: sessionStorage.getItem("token"),
            client_code: "ht_front",
          },
        }
      )
      .then((res) => {
        let data = res.data.data;
        setCountries(data.result);
      })
      .catch((err) => {
        
      });
  };

  const getAllCities = (countryId) => {
    axios
      .get(
        `${domainUrl}/masterservicenew/master/city?limit=100&country_id=${countryId}`,
        {
          headers: {
            token: sessionStorage.getItem("token"),
            client_code: "ht_front",
          },
        }
      )
      .then((res) => {
        let data = res.data.data;
        setCities(data.result);
      })
      .catch((err) => {
        
      });
  };

  const onSelectCountry = (e) => {
    const countryId = +e?.target?.value || null;
    setCompany({ ...company, country_id: countryId });
    countryId && getAllCities(countryId);
  };

  // const onAddNewEmail = () => {
  //   const currEmailAccounts = company?.default_emails ?? [];

  //   setCompany({
  //     ...company,
  //     default_emails: [...currEmailAccounts, ""],
  //   });
  //   console.log(currEmailAccounts);
  // };

  // const onDeleteEmail = (index) => {
  //   const filteredEmailAccounts = company?.default_emails?.filter(
  //     (_, i) => i !== index
  //   );
  //   setCompany({
  //     ...company,
  //     default_emails: [...filteredEmailAccounts],
  //   });
  // };

  // const onDefaultEmailChange = (index, e) => {
  //   const _ = company?.default_emails?.slice() ?? [];
  //   _[index] = e.target.value;

  //   setCompany({ ...company, default_emails: _ });
  // };

  function handleSubmit() {
    if (!company) {
      setErrorMessage({
        org_id: intl.formatMessage({ id:'error.organization'}),
        company_name: intl.formatMessage({ id:'error.company_name'}),
        business_id: intl.formatMessage({ id:'error.business_id'}),
        email: intl.formatMessage({ id:'error.email'}),
        mobile: intl.formatMessage({ id:'error.mobile_number'}),
        address: intl.formatMessage({ id:'error.address'}),
        country: intl.formatMessage({ id:'select_country'}),
        city: intl.formatMessage({ id:'error.city'}),
        pincode: intl.formatMessage({ id:'error.pin_code'}),
      });
    }  if (!company.organization_id || company.organization_id === "") {
        errorMessage.org_id= intl.formatMessage({ id:'error.organization'});
    }  if (!company.company_name || company.company_name === "") {
        errorMessage.company_name= intl.formatMessage({ id:'error.company_name'});
    }  if (!company.business_id || company.business_id === "") {
        errorMessage.business_id= intl.formatMessage({ id:'error.business_id'});
    }  if (!company.email || company.email === "") {
        errorMessage.email= intl.formatMessage({ id:'error.email'});
    }  if (
      company.email &&
      company.email !== "" &&
      // eslint-disable-next-line no-useless-escape
      !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        company.email
      )
    ) {
        errorMessage.email= intl.formatMessage({ id:'error.invalid_email'});
    }if (
      company.website &&
      company.website !== "" &&
      !validURL(company.website)
    ) {
      errorMessage.website = intl.formatMessage({ id:'error.invalid_website_url'});
    }  if (!company.contact || company.contact === "") {
        errorMessage.mobile= intl.formatMessage({ id:'error.mobile_number'});
    } if (
      company.contact &&
      company.contact !== "" &&
      // eslint-disable-next-line no-useless-escape
      !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
        company.contact
      )
    ) {
        errorMessage.mobile= intl.formatMessage({ id:'error.invalid_mobile_number'});
    } if (!company.address || company.address === "") {
        errorMessage.address= intl.formatMessage({ id:'error.address'});
    }  if (!company.country_id || company.country_id === "") {
        errorMessage.country= intl.formatMessage({ id:'select_country'});
    }  if (!company.city_id || company.city_id === "") {
        errorMessage.city= intl.formatMessage({ id:'error.city'});
    }  if (!company.pincode || company.pincode === "") {
      
        errorMessage.pincode = intl.formatMessage({ id:'error.pin_code'});
      
    } if (company.default_emails) {
      const mailError = [];
      // eslint-disable-next-line array-callback-return
      company.default_emails.map((email,index) => {
        // eslint-disable-next-line no-useless-escape
        if(email !== "" && !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email
        )) {
          mailError[index] = intl.formatMessage({ id:'error.invalid_email'});
        }
      })
      errorMessage.default_emails = mailError;

    } 
    if(errorMessage?.default_emails && errorMessage?.default_emails?.length === 0) {
      delete errorMessage.default_emails;
    }
      if (Object.keys(errorMessage).length >= 1) {
        setErrorMessage({ ...errorMessage} );
        return;
      } else 
       if (
        company &&
        company.organization_id !== "" &&
        company.company_name !== "" &&
        company.business_id !== "" &&
        company.email !== "" &&
        company.contact !== "" &&
        company.address !== "" &&
        company.country !== "" &&
        company.city !== "" &&
        company.pincode !== ""
      ) {
        props.onSubmit(company);
      } else {
        setErrorMessage({
          message: "Some issue occurred please try again",
        });
      }
  }

  const addFileList = (files) => {
    setFileList({
      ...fileList,
      ...files
    })
  }

  const onFilesStatusChange = (e) => {
    // setFileToUploadList(e);
  };

  const onUploadStatusChange = (e) => {
    setIsUploading(e);
  };

  const onFilesUploadStatusChange = (e) => {
    setFileList(e);
  };

  return (
    <div className="companyAddModal">
      <div className="pageOverlay">
        <div className="modalParent">
          <div className="closeBtn2" onClick={() => props.onClose()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48.408"
              height="48.408"
              viewBox="0 0 48.408 48.408"
            >
              <g id="Group_2114" transform="translate(-198.987 -621.867)">
                <g id="Group_1542" transform="translate(198.987 621.867)">
                  <path
                    id="Path_61"
                    d="M26.2 50.408A24.2 24.2 0 1 0 2 26.2a24.2 24.2 0 0 0 24.2 24.2zm0-44.951A20.746 20.746 0 1 1 5.458 26.2 20.746 20.746 0 0 1 26.2 5.458z"
                    className="cls-1"
                    transform="translate(-2 -2)"
                  />
                  <path
                    id="Path_62"
                    d="M9.506 32.667a1.729 1.729 0 0 0 2.438 0l9.162-9.167 9.146 9.146a1.729 1.729 0 0 0 2.438-2.438l-9.146-9.146 9.146-9.142a1.729 1.729 0 0 0-2.438-2.438l-9.146 9.146-9.145-9.163A1.729 1.729 0 0 0 9.523 11.9l9.146 9.163-9.163 9.149a1.729 1.729 0 0 0 0 2.455z"
                    className="cls-1"
                    transform="translate(3.098 3.138)"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div className="modalOuterMain">
            <div className="modalOuter2">
              {errorMessage.message && (
                <div className="errorMessage">{errorMessage.message}</div>
              )}
              <h2 className="title">{!isReadOnly ? (company?.company_id ? <FormattedMessage id={'edit'} /> : <FormattedMessage id={'enter'} />) : ''} <FormattedMessage id={'company_details'} /></h2>
              {props.role_id !== 2 && 
              
              <div className="inputFieldGroup">
                <label><FormattedMessage id={'organisation'} /> <span style={{color:"red"}}>*</span></label>
                <div className="inputText">
                  {isReadOnly ? (
                    <input
                      readOnly={isReadOnly}
                      className="inputFiled"
                      type="text"
                      name="Organization"
                      value={company?.organization_name ?? ""}
                    />
                  ) : (
                    <div className="costumSelect">
                      {organization && organization.length > 0 && 
                        <select
                          name="organization_id"
                          id="slct"
                          className="inputFiled"
                          value={company?.organization_id ?? ""}
                          onChange={handleInputChange}
                        >
                          <option value="">{intl.formatMessage({id: 'select_organization'})}</option>
                          {organization?.map((el, index) => (
                            <option key={index} value={el.organization_id}>
                              {el?.organization_name}
                            </option>
                          ))}
                        </select>
                      }
                    </div>
                  )}
                  <div className="errorMessage">{errorMessage.org_id}</div>
                </div>
              </div>
              }
              <div className="inputFieldGroup">
              <label><FormattedMessage id={'title'} />
                <span style={{color:"red"}}>*</span>
                </label>

                <div className="inputText">
                  <input
                    readOnly={isReadOnly}
                    className="inputFiled"
                    type="text"
                    name="company_name"
                    value={company ? company.company_name : ""}
                    onChange={handleInputChange}
                  />
                  <div className="errorMessage">
                    {errorMessage.company_name}
                  </div>
                </div>
              </div>
              <div className="inputFieldGroup">
              <label><FormattedMessage id={'business_id'} />
                <span style={{color:"red"}}>*</span>
                </label>
                <div className="inputText">
                  <input
                    readOnly={isReadOnly}
                    className="inputFiled"
                    type="text"
                    name="business_id"
                    value={company ? company.business_id : ""}
                    onChange={handleInputChange}
                  />
                  <div className="errorMessage">{errorMessage.business_id}</div>
                </div>
              </div>
              <div className="inputFieldGroup">
                <label><FormattedMessage id={'email'} />
                <span style={{color:"red"}}>*</span>
                </label>
                <div className="inputText">
                  <input
                    readOnly={isReadOnly}
                    className="inputFiled"
                    type="email"
                    name="email"
                    value={company ? company.email : ""}
                    onChange={handleInputChange}
                  />
                  <div className="errorMessage">{errorMessage.email}</div>
                </div>
              </div>
              <div className="inputFieldGroup">
                <label><FormattedMessage id={'mobile_number'} />
                <span style={{color:"red"}}>*</span>
                </label>
                <div className="inputText">
                  <input
                    readOnly={isReadOnly}
                    className="inputFiled"
                    maxLength="16"
                    minLength="6"
                    name="contact"
                    value={company ? company.contact : ""}
                    onChange={handleInputChange}
                  />
                  <div className="errorMessage">{errorMessage.mobile}</div>
                </div>
              </div>
              <div className="inputFieldGroup">
                <label><FormattedMessage id={'address'} />
                <span style={{color:"red"}}>*</span>
                </label>
                <div className="inputText">
                  <input
                    readOnly={isReadOnly}
                    className="inputFiled"
                    type="text"
                    name="address"
                    value={company ? company.address : ""}
                    onChange={handleInputChange}
                  />
                  <div className="errorMessage">{errorMessage.address}</div>
                </div>
              </div>
              <div className="inputFieldGroup">
                <label><FormattedMessage id={'country'} />
                <span style={{color:"red"}}>*</span>
                </label>
                <div className="inputText">
                  {isReadOnly ? (
                    <input
                      readOnly={isReadOnly}
                      className="inputFiled"
                      type="text"
                      name="Country"
                      value={company?.country_name ?? ""}
                    />
                  ) : (
                    <div className="costumSelect">
                      <select
                        name="country_id"
                        id="slct"
                        className="inputFiled"
                        value={company?.country_id || ""}
                        onChange={(e) => onSelectCountry(e)}
                      >
                       <option value="">{intl.formatMessage({id: 'select_country'})}</option>
                        {countries?.map((el, index) => (
                          <option key={index} value={el.country_id}>
                            {el?.country_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="errorMessage">{errorMessage.country}</div>
                </div>
              </div>
              <div className="inputFieldGroup">
                <label><FormattedMessage id="city"/>
                  <span style={{color:"red"}}>*</span>
                </label>
                <div className="inputText">
                  {isReadOnly ? (
                    <input
                      readOnly={isReadOnly}
                      className="inputFiled"
                      type="text"
                      name="City"
                      value={company?.city_name ?? ""}
                    />
                  ) : (
                    // <div className="costumSelect">
                    //   <select
                    //     name="city_id"
                    //     id="slct"
                    //     className="inputFiled"
                    //     value={company?.city_id ?? ""}
                    //     onChange={handleInputChange}
                    //   >
                    //      <option value="">{intl.formatMessage({id: 'select_city'})}</option>
                    //     {cities?.map((el, index) => (
                    //       <option key={index} value={el.city_id}>
                    //         {el?.city_name}
                    //       </option>
                    //     ))}
                    //   </select>
                    // </div>
                    <Select
                      name="city_id"
                      showSearch
                      style={{width: 270}}
                      placeholder="Select a city"
                      optionFilterProp="children"
                      getPopupContainer={trigger => trigger.parentElement}
                      value={company?.city_id ?? ""}
                      onChange={handleCityChange}
                      onSearch={handleCityChange}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      // filterSort={(optionA, optionB) =>
                      //   (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                      // }
                      options={
                        cities?.map((city,index)=> {
                          let option = {
                            label: city.city_name, value: city.city_id
                          }
                          return option;
                        }).sort((a, b) =>a.label.localeCompare(b.label, 'fi', {
                            caseFirst: 'upper'
                          }))
                      }
                    />
                  )}

                  <div className="errorMessage">{errorMessage.city}</div>
                </div>
              </div>
              <div className="inputFieldGroup">
                <label><FormattedMessage id={'postal_code'} />
                  <span style={{color:"red"}}>*</span>
                </label>
                <div className="inputText">
                  <input
                    readOnly={isReadOnly}
                    className="inputFiled"
                    maxLength= "5"
                    name="pincode"
                    value={company ? company.pincode : ""}
                    onChange={handleInputChange}
                  />
                  <div className="errorMessage">{errorMessage.pincode}</div>
                </div>
              </div>
              <div className="inputFieldGroup">
                <label><FormattedMessage id={'website'} />
                  {/* <span style={{color:"red"}}>*</span> */}
                </label>
                <div className="inputText">
                  <input
                    readOnly={isReadOnly}
                    className="inputFiled"
                    // maxLength= "5"
                    name="website"
                    value={company ? company.website : ""}
                    onChange={handleInputChange}
                  />
                  <div className="errorMessage">{errorMessage.website}</div>
                </div>
              </div>
              <Spin spinning={isUploading} >
                {company?.company_id &&
                  <div className="inputFieldGroup">
                    <label><FormattedMessage id="upload_files" />
                    </label>
                    
                      <div className="inputText" style={{width: "100%"}}>
                        {!isReadOnly &&
                          <FileUpload
                            filePostURL={FILE_POST_URL}
                            entityType={"R"}
                            entityID={company?.company_id}
                            onFilesStatusChange={onFilesStatusChange}
                            addFileList={addFileList}
                            onUploadStatusChange={onUploadStatusChange}
                            onFilesUploadStatusChange={onFilesUploadStatusChange}
                          />
                        }
                        <FileDisplay entityType={'R'} fileList={fileList} entityId={company?.company_id} hideTitle={false} deleteFiles={true}/>
                      </div>
                    
                  </div>
                }
              </Spin>
              {isReadOnly && company?.default_emails?.length > 0 && (
                <div className="inputFieldGroup emailGroup">
                  <label ><FormattedMessage id={'Default_Emails'} /></label>
                  <div className="inputText">
                    {company?.default_emails?.map((el, index) => (
                      <input
                      readOnly={isReadOnly}
                      className="inputFiled"
                      type="text"
                      value={el || ""}
                      name={"default_email"}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
            {!isReadOnly && (
              <div className="modalOuter2">
                {/* {company?.default_emails?.map((el, index) => (
                  <>
                  <div key={index} className="inputFieldGroup">
                    {index === 0 && <label><FormattedMessage id={'Default_Emails'} /></label>}
                    <div className="inputText ml-auto">
                      <input
                        className="inputField"
                        type="email"
                        name={"default_email"}
                        value={el || ""}
                        onChange={(e) => onDefaultEmailChange(index, e)}
                      />
                    </div>
                    <div
                      onClick={() => onDeleteEmail(index)}
                      className="deleteIcon"
                    >
                     <FormattedMessage id={'delete'} />
                    </div>
                  </div>
                  <div className="errorMessage" style={{marginLeft: 200, marginTop: -15, marginBottom: 15, }}>{errorMessage?.default_emails && errorMessage?.default_emails[index]}</div>
                  </>
                ))} */}

                {/* <div className="inputFieldGroup">
                  <div className="button1 button2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29.701"
                      height="29.701"
                      viewBox="0 0 29.701 29.701"
                    >
                      <path
                        fill="#fff"
                        d="M15.85 1A14.85 14.85 0 1 0 30.7 15.85 14.852 14.852 0 0 0 15.85 1zm6.75 16.2h-5.4v5.4h-2.7v-5.4H9.1v-2.7h5.4V9.1h2.7v5.4h5.4z"
                        transform="translate(-1 -1)"
                      />
                    </svg>
                    <span onClick={onAddNewEmail}><FormattedMessage id={'Default_Emails_Add'} /></span>
                  </div>
                </div> */}

                <div className="buttonBlock">
                  <div
                    className="btn cancelBtn"
                    onClick={() => props.onClose()}
                  >
                 <FormattedMessage id={'cancel'} />
                  </div>
                  <div className="btn" onClick={() => handleSubmit()}>
                  <FormattedMessage id={'save'} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddModal;