/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Spin, message, Modal } from "antd";

import "../../Tender/Summary/Summary.css";
import { useTender } from "../PreTenderSteps";
import PreTenderDetails from "../../PreTenderDetail/PreTenderDetails";
import { submitPreTender } from "../../../actions/pre-tender";
import PdfDisplayPreTender from '../../PDFDisplayPreTender';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { sendEmailCommunication } from "../../../actions/communication";
import { FormattedMessage, useIntl } from "react-intl";

const Summary = () => {

  const dispatch = useDispatch();
  const { preTenderDetails, onStepChange: move, modifyData } = useTender();
  const [showPreview, setShowPreview] = useState(false);
  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const intl = useIntl();
  const editIconProps = {
    style: {
      borderRadius: "50%",
      color: "white",
      background: "#2B7776",
      padding: "10px",
    },
  };

  const validatePublish = () => {
    if((!preTenderDetails?.assetsFormValues || !preTenderDetails?.assetsFormValues[0]?.asset_name) && (!uploadedFiles || uploadedFiles.length === 0)) {
        message.error(`${intl.formatMessage({id: 'please_add_assets_or_upload_files_to_publish_a_tender'})}`);
    }
    else{
      setShowConfirmationModal(true);
    }

  }
  const closePreview = () => {
    setShowPreview(false);
  }
  const publishTender = async() => {
    if(preTenderDetails.is_submitted !== 1){
      setLoading(true);
       let putResponse = await dispatch(submitPreTender({
        state: parseInt(preTenderDetails.state) > 7 ? preTenderDetails.state : '7',
        is_submitted: true,
      }, preTenderDetails?.pretender_id));
  
      let editedPreTender = putResponse.payload?.entities?.preTender[preTenderDetails?.pretender_id]
      if (editedPreTender?.pretender_id) {
        await dispatch(sendEmailCommunication('PRETENDER_SUBMISSION', {pretender_id: +preTenderDetails?.pretender_id}));
      }
      setLoading(false);
      move(1);
    }
    else {
      setShowConfirmationModal(false);
      modifyData();
    }
  }

  if (loading)
    return <Spin style={{ display: "flex", justifyContent: "center" }} />;
  return (
    <>
    <PreTenderDetails preTenderId={preTenderDetails.is_submitted != 1 ? preTenderDetails.pretender_id : undefined} preTenderDetails={preTenderDetails.is_submitted === 1 ? preTenderDetails : undefined} setIsLoading={setLoading} isSupplierSummary={true} allowEdit={true} move={move} showToolTip={true} getFileAvailability={(files) => setUploadedFiles(files)}/>

      <div className="buttonBlock">
      <div className="btn cancelBtn" onClick={() => setShowPreview(true)}><FormattedMessage id="preview"/></div>
        <div onClick={validatePublish} className="btn">
        <FormattedMessage id="submit"/>
        </div>
      </div>

      <Modal open={showConfirmationModal} closable={false} footer={null}>
        <h2 style={{ textAlign: "center" }}>
        <FormattedMessage id="publish_tender_conf"/>
        </h2>
        <div className="d-flex j-center f-col">
          <div className="btn" onClick={publishTender}>
          <FormattedMessage id="yes"/>
          </div>
          <div
            className="btn"
            onClick={() => {
              setShowConfirmationModal(false);
            }}
          >
           <FormattedMessage id="no"/>
          </div>
        </div>
      </Modal>
      {
          showPreview && 
          <PdfDisplayPreTender pretender_id={preTenderDetails.pretender_id} closePreview={closePreview}/>
        }
    </>
  );
};

export default Summary;
