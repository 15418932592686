/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import { useSelector, useDispatch } from "react-redux";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import get from 'lodash/get';
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Input, Button, Checkbox, Rate, Modal, Select, Upload, message, Space, DatePicker } from 'antd';
import { segKeyData } from '../../utils';

import { fetchServiceTypes } from '../../actions/services';
import { fetchCountries, fetchCities } from '../../actions/list';
import moment from "moment";
import FileUpload from "../FileUpload";
import FileDisplay from "../FileDisplay";
import { domainUrl } from "../../helper";
const FILE_POST_URL = `${domainUrl}/tender/v1/document`;

const useStyles = makeStyles((theme) => ({
    closeBtn: {
        float: "right",
        cursor: "pointer",
      },
      group: {
          flex: .92,
      },
    inputFieldGroup: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 20,
        justifyContent: 'space-between',
    },
    buttonBlock: {
       display: 'flex',
       justifyContent: 'space-between',
       width: '94%',
       marginRight: '3%',
       justifyContent: 'center',
    }
}));

const SupplierDetails = ({ readOnly, isOpen, data, onClose, onSubmit, onDelete,onDeactive, allowRating }) => {
    const classes = useStyles();
    const intl = useIntl();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});
    const [supplierData, setSupplierData] = useState();
    const [serviceTypeData, setServiceTypeData] = useState([]);
    const [countries, setCountries] = useState([]);
    //const [cities, setCities] = useState([]);
    const [imageUrl, setImageUrl] = useState("");
    const [selectedServices, setSelectedServices] = useState({});
    const [fileToUploadList, setFileToUploadList] = useState(supplierData?.files ?? []);
    const [fileList, setFileList] = useState(supplierData?.files ?? []);
    const [isUploading, setIsUploading] = useState(false);
 const {TextArea}=Input
    const handleInputChange = (event) => {
    const data = {
      ...supplierData,
      [event.target.name]: event.target.value,
    };
    setSupplierData(data);
    setErrorMessage({});
  };

  const cities = useSelector(state => segKeyData('city_name', 'city_id', Object.values(get(state, `entities.cities`) || {})));

  //const getAllcities = useSelector(state => get(state, `entities.cities`));

   const services = useSelector(state => segKeyData('service_type_name', 'service_type_id', Object.values(get(state, `entities.services`) || {})));

   const getCities = async() => {
       await dispatch(fetchCities(1));
   }

   const getServiceTypes = async() => {
       await dispatch(fetchServiceTypes());
   }

   useEffect(() => {
     if(data) {
       data.service_cities_values = data.service_cities?.map(city => {
          return !readOnly ? city.city_id.toString() : city.city_name;
       })
       data.service_types_values = data.service_types?.map(service => {
          return service.service_type_id.toString();
       });

       data.city_id = !readOnly ? data?.city_id?.toString() : data?.city_name;
       //data.contact = parseInt(data.contact);
       /* Have to get from backend for the city selected. For multiple cities and if different countries */
       data.country_name = 'Finland';
       data.country_id = 1;
       data.created_at = data.created_at ? moment(data.created_at) : '';
      //  data.created_at = data.created_at ? moment(data.created_at).format("YYYY-MM-DD") : '';
       setImageUrl(data?.branding_logo);
       setSupplierData(data);
     }
   }, [data])

  useEffect(() => {
        getServiceTypes();
        if(!readOnly) {
            getAllCities(1);
        }
    }, [dispatch]);

    const getAllCities = async (countryId) => {
      const response = await dispatch(fetchCities(countryId));
      const cities = Object.values(response?.payload?.entities?.cities || []);
      //setCities(cities);
    };

  const setServices = () =>{
      let obj = {};
      data.service_types.map(service => obj[service.service_type_id] = true);
      setSelectedServices(obj);
      setServiceTypeData(data.service_types);

  }

  const onSelectCountry = (e) => {
    const countryId = +e?.target?.value || null;
    setSupplierData({ ...supplierData, country_id: countryId });
    countryId && getAllCities(countryId);
  };

   const handleSubmit = async(values) => {
      // let services = Object.keys(selectedServices).filter(serv => !selectedServices[serv]);
     values.service_cities = values.service_cities_values.map(item => {
            return {
                'city_id': item
            }
        })

        values.service_types = values.service_types_values.map(item => {
            return {
                'service_type_id': item
            }
        })
      values.is_favourite = values.is_favourite ? 1: 0;
      values.contact = values.contact.toString();
      values.pincode = values.pincode.toString();
      values.created_at = values.created_at ? moment(values.created_at).format("YYYY-MM-DD") : '';
      values.city_id = +supplierData.city_id;
      values.country_id = 1;
      values.branding_logo=imageUrl;
      Modal.confirm({
        title: data?.supplier_id ? intl.formatMessage({ id:'edit_supplier'}):intl.formatMessage({ id:'add_supplier'}),
        onOk() {
          onSubmit(values);
        },
        okText:intl.formatMessage({ id:'ok'}),
        cancelText:intl.formatMessage({ id:'cancel'}),
      });
      
  }

  const handleMarkAsFavourite = () => {
      let data = supplierData;
    if(supplierData.supplier_id){
      data.is_favourite = data.is_favourite === 0 ? 1 : 0;
      setSupplierData(data);
      onSubmit(data);
    }
    else {
      setSupplierData({ ...supplierData, is_favourite: data.is_favourite === 1 ? 0 : 1 });
    }
  }

  const handleDelete = () => {
      onDelete(supplierData);
  }
  const handleDeactive = () => {

    onDeactive(supplierData);
  }

  const handleChange = (event) => {
    setSelectedServices({ ...selectedServices, [event.target.name]: event.target.checked });
    setErrorMessage({});
  };

  function getBase64(img, callback) {
  
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

const handleChangeupload=(filechange)=>{
   
  // if (filechange.file.status === 'uploading') {
  //   console.log("jbhbhjdsjbsb")
  //   setLoading(true)
  //   // return;
  // }

// if (filechange.file.status === 'done') {
          const file = filechange?.file?.originFileObj;

          getBase64(file,imageUrl =>{
            setImageUrl(imageUrl)
            ("imageUrl",imageUrl)
            setLoading(false)},
            
          );}

// }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}><FormattedMessage id={'UploadLogo'} /></div>
    </div>
  );

  const addFileList = (files) => {
    setFileList({
      ...fileList,
      ...files
    })
  }

  const onFilesStatusChange = (e) => {
    setFileToUploadList(e);
  };

  const onUploadStatusChange = (e) => {
    setIsUploading(e);
  };

  const onFilesUploadStatusChange = (e) => {
    setFileList(e);
  };
  // console.log(cities.sort((a, b) =>a.label.localeCompare(b.label, 'fi', {
  //   caseFirst: 'upper'
  // })))
  const handleCityChange = async (city_id, option) => {
    setSupplierData({ ...supplierData, city_id: +city_id, city_name:option?.label });
  }
    return (

      <Modal
          open={isOpen}
          onCancel={onClose}
          centered
          maskClosable={false}
          title={<FormattedMessage id={'supplier_details'} />}
          footer={null}
        >
        { 
          supplierData && 


          
           <Form
              name="supplier_form"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 14 }}
              initialValues={supplierData}
              onFinish={handleSubmit}
              className="manage_form"
            >
              <Form.Item style={{textAlign:"right"}}>

                <Upload
                  disabled={readOnly}
                  name="avatar"
                  // fileList={[imageUrl]}
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleChangeupload}
                >
                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />: uploadButton}
                </Upload>

              </Form.Item>
 
              <Form.Item style={{justifyContent:"space-between"}}
                label={<FormattedMessage id={'supplier_name'} />}
                name="supplier_name"
                rules={[{ required: true, message: <FormattedMessage id={'error.supplier_name'} /> }]}
              >
                <Input disabled={readOnly}/>
              </Form.Item>

              <Form.Item style={{justifyContent:"space-between"}}
                label={<FormattedMessage id={'company_name'} />}
                name="company_name"
                // rules={[{ required: true, message: <FormattedMessage id={'error.company_name'} /> }]}
              >
                <Input disabled={readOnly}/>
              </Form.Item>
              <Form.Item style={{justifyContent:"space-between"}}
                label={<FormattedMessage id={'business_id'} />}
                name="business_id"
              >
                <Input disabled={readOnly}/>
              </Form.Item>

              <Form.Item style={{justifyContent:"space-between"}}
                label={<FormattedMessage id={'address'} />}
                name="address"
                rules={[{ required: true, message: <FormattedMessage id={'error.address'} /> }]}
              >
                <Input disabled={readOnly}/>
              </Form.Item>
              <Form.Item style={{justifyContent:"space-between"}}
                label={<FormattedMessage id={'country'} />}
                name="country_name"
                >
                  <Input disabled/>
              </Form.Item>
              <Form.Item style={{justifyContent:"space-between"}}
                label={<FormattedMessage id={'city'} />}
                name="city_id"
                // rules={[
                //     { required: true, message: <FormattedMessage id={'error.city'} /> }]}
                >
                    {/* <Select
                        disabled={readOnly}
                        style={{ width: '100%' }}
                        placeholder={<FormattedMessage id={'select_city'} />}
                        notFoundContent={null}
                        optionFilterProp="city_name"
                        options={cities}
                    >
                    </Select> */}
                    <Space wrap>
                      <Select
                          // name="city_id"
                          disabled={readOnly}
                          showSearch
                          style={{width: '100%'}}
                          placeholder={<FormattedMessage id={'select_city'} />}
                          optionFilterProp="label"
                          getPopupContainer={trigger => trigger.parentElement}
                          value={supplierData?.city_name ?? ""}
                          onChange={(value,option)=>handleCityChange(value,option)}
                          // onSearch={handleCityChange}
                          filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          // filterSort={(optionA, optionB) =>
                          //     (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                          // }
                          options={
                              cities
                          }
                      />
                    </Space>
              </Form.Item>
              <Form.Item style={{justifyContent:"space-between"}}
                label={<FormattedMessage id={'CompanyDescription'} />}
                name="company_description"
                // rules={[{ required: true, message: <FormattedMessage id={'error.company_description'} /> }]}
              >
                <TextArea />
              </Form.Item>
              <Form.Item style={{justifyContent:"space-between"}}
                label={<FormattedMessage id={'pin_code'} />}
                name="pincode"
                rules={[
                    {
                    pattern: new RegExp(/\d+/g),
                    message: <FormattedMessage id={'error.invalid_pin_code'} />,
                    },
                    { required: true, message: <FormattedMessage id={'error.pin_code'} /> }]}
                >
                    <Input maxLength = "6" disabled={readOnly}/>
              </Form.Item>

              <Form.Item style={{justifyContent:"space-between"}}
                label={<FormattedMessage id={'cities_operating'} />}
                name="service_cities_values"
                rules={[
                    { required: true, message: <FormattedMessage id={'error.city'} /> }]}
                >
                    <Select
                        mode="multiple"
                        disabled={readOnly}
                        showSearch
                        style={{ width: '100%' }}
                        placeholder={<FormattedMessage id={'select_operating_city'} />}
                        notFoundContent={null}
                        optionFilterProp="city_name"
                        options={cities}
                        getPopupContainer={trigger => trigger.parentElement}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        // filterSort={(optionA, optionB) =>
                        //     (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        // }
                    >
                    </Select>
                </Form.Item>

                <Form.Item style={{justifyContent:"space-between"}}
                    label={<FormattedMessage id={'mobile_number'} />}
                    name="contact"
                    rules={[
                        {
                        pattern: new RegExp(/\d+/g),
                        message: <FormattedMessage id={'error.invalid_mobile_number'} />,
                        },
                        { required: true, message: <FormattedMessage id={'error.mobile_number'} /> }]}
                    >
                        <Input maxLength= "16" minLength="6"  disabled={readOnly} style={{width: '100%'}}/>
                  </Form.Item>

                  <Form.Item style={{justifyContent:"space-between"}}
                    label={<FormattedMessage id={'email'} />}
                    name="email"
                    rules={[ {
                        type: 'email',
                        message: <FormattedMessage id={'error.invalid_email'} />,
                        },
                        { required: true, 
                        message: <FormattedMessage id={'error.email'} /> 
                        } ]}
                    >
                        <Input disabled={readOnly}/>
                    </Form.Item>
                  <Form.Item style={{justifyContent:"space-between"}}
                    label={<FormattedMessage id={'website'} />}
                    name="website"
                    rules={[ {
                        type: 'url',
                        message: <FormattedMessage id={'error.invalid_website_url'} />,
                        },
                        // { required: true, 
                        // message: <FormattedMessage id={'error.email'} /> 
                        // } 
                      ]}
                    >
                      <Input disabled={readOnly}/>
                  </Form.Item>
                  <div className="inputFieldGroup">
                    {supplierData.supplier_id &&
                      <>
                        <label><FormattedMessage id="upload_files" />
                        </label>
                        <div className="inputText" style={{width: "100%"}}>
                          {!readOnly &&
                            <FileUpload
                              filePostURL={FILE_POST_URL}
                              entityType={"R"}
                              entityID={supplierData.supplier_id}
                              onFilesStatusChange={onFilesStatusChange}
                              addFileList={addFileList}
                              onUploadStatusChange={onUploadStatusChange}
                              onFilesUploadStatusChange={onFilesUploadStatusChange}
                            />
                          }
                          <FileDisplay entityType={'R'} fileList={fileList} entityId={supplierData.supplier_id} hideTitle={false} deleteFiles={true}/>
                        </div>
                      </>
                    }
                  </div>
                  <Form.Item style={{justifyContent:"space-between"}}
                    label={<FormattedMessage id={'registration_date'} />}
                    name="created_at"
                    // rules={[ {
                    //     type: 'website',
                    //     message: <FormattedMessage id={'error.invalid_email'} />,
                    //     },
                    //     { required: true, 
                    //     message: <FormattedMessage id={'error.email'} /> 
                    //     } ]}
                    >
                      <DatePicker locale={intl.messages?.datepickerLocale} format={intl.messages?.datepickerLocale?.dateFormat} style={{width:'100%'}} disabled/>
                        {/* <Input disabled={true}/> */}
                    </Form.Item>

                    <Form.Item style={{justifyContent:"space-between"}}
                    label={<FormattedMessage id={'industry_type'} />}
                    name="service_types_values"
                    rules={[
                        { required: true, message: <FormattedMessage id={'error.service_type'} /> }]}
                    >
                        <Checkbox.Group
                          disabled={readOnly}
                          options={services}
                          name="service_type_name"
                        />
                    </Form.Item>
                    {
                      allowRating && 
                        <Form.Item style={{justifyContent:"space-between"}}
                          label={<FormattedMessage id={'supplier_rating'} />}
                          name="rating"
                          >
                              <Rate allowHalf disabled={readOnly}/>
                          </Form.Item>
                    }
                    
                    {
                      !readOnly && 
                        <Form.Item style={{justifyContent:"space-between"}}
                        name="is_favourite"
                        valuePropName="checked"
                        wrapperCol={{
                          offset: 8,
                          span: 14,
                        }}
                        >
                            <Checkbox disabled={readOnly}>
                              <FormattedMessage id={'mark_favourite'} />
                            </Checkbox>
                        </Form.Item>
                    }

              <div className={classes.buttonBlock} >
                 
                    <Button className="btn cancelBtn" type="secondary" onClick={onClose}>
                        <FormattedMessage id={'cancel'} />
                    </Button>
                    {!readOnly && (
                        <>
                      <Button className="btn" type="primary" htmlType="submit">
                        <FormattedMessage id={'save'} />
                      </Button>

                      {/* <div className="btn" onClick={() => handleMarkAsFavourite()}>
                        {supplierData.is_favourite === 1 ? 'Unmark As Favourite' : 'Mark As Favourite' }
                      </div> */}
                      {
                         (!readOnly && supplierData.supplier_id) && 
                           <Button type="primary" className="btn" onClick={() => handleDeactive()}>
                            <FormattedMessage id={'deactivate'} />
                          </Button>
                      }
                      
                      </>
                    )}
                  </div>
          </Form>
          }
        </Modal>
       
    );
};

SupplierDetails.propTypes = {
  readOnly: PropTypes.bool,
  isOpen: PropTypes.bool,
  data: PropTypes.shape({}),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default SupplierDetails;