/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import Header from "../Header";
import Setting from "../Setting";
import SettingHeadingLabel from "../Common/SettingHeadingLabel";
import './PaymentGateway.css';
import { FormattedMessage, useIntl } from "react-intl";
const PaymentGateway = (props) => {
  const intl = useIntl();
  return (
    <>
      <Header />
      <div className="settingOuter">
        <Setting page="PaymentGateway" />
        <div className="tabContainer">
          <h2> <FormattedMessage id={'payment_gateway'} /></h2>
          <div className="tabContentBlock">
            <div className="contentBlock">
              <div className="formSettings">
                <div className="form-group">
                  <label className="form-label"> <FormattedMessage id={'provided_url'} /></label>
                  <input className="form-control" type="text" />
                </div>
                <div className="form-group">
                  <label className="form-label"> <FormattedMessage id={'Client_id'} /></label>
                  <input className="form-control" type="text" />
                </div>
                <div className="form-group">
                  <label className="form-label"><FormattedMessage id={'secret_key'} /></label>
                  <input className="form-control" type="text" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

PaymentGateway.propTypes = {};

export default PaymentGateway;
