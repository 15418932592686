import { mergeWith } from "lodash";

const customizer = (objValue, srcValue) => {
}
const entities = (state = {}, action) => {
    const { payload, type } = action;

    if(payload && payload.entities && type.includes('FETCH')) {
        switch (type) {
            case 'FETCH_USER_SUCCESS':
                return mergeWith(state, payload.entities, customizer);
            case 'FETCH_SUPPLIER_SUCCESS':
                return mergeWith(state, payload.entities, customizer);
            case 'FETCH_TENDERS_SUCCESS':
                return mergeWith(state, payload.entities, customizer);
            default:
                return {...state, ...payload.entities };
        }

    }

    return state;
}

export default entities;