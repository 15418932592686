import React, { useEffect, useState } from "react";
import axios from "axios";
// import { Link } from "react-router-dom";
import { message, Modal, Table, Button } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { domainUrl, awsDocumentUrl } from "../../helper";
// import { Row, Col } from "antd";
import Header from "../Header";
import "../../components/SupplierDashboard/SupplierDashboardnew.css";
// import TenderCard from "../__TenderCard";
// import { useHistory } from "react-router";
import MessagesCard from "../MessagesCard";
// import { bids, files } from "../../Locale/en";
// import moment from "moment";
import BidSummary from "../../pages/bid/Summary";
import { EyeFilled } from "@ant-design/icons";

const Dashboard = ({props}) => {
  const { tenderId } = props.match.params;
  // const [bid_id, setBidId] = useState();
  const [info, setInfo] = useState({});
  const [viewId, setViewId] = useState();
  // const history = useHistory();
  const intl = useIntl();
  const genHeaders = () => ({
    headers: {
      client_code: "ht_front",
      token: sessionStorage.getItem("token"),
    },
  });

  // useEffect(() => {
  //   const getInfo = async () => {
  //     const supplier_id = sessionStorage.getItem("entity_id");
  //   let bidResult = await axios.get(
  //     `/bid/v1/tender/${tenderId}/bid?supplier_id=${supplier_id}`,
  //     genHeaders()
  //   );
  //   const bidID = bidResult?.data?.data?.result[0]?.bid_id;
  //   setBidId(bidID);
  //   };
  //   getInfo();
  // }, []);

  useEffect(() => {
    const getInfo = async () => {
      const assetsCall = axios
        .get(`${domainUrl}/tender/v1/tender/${tenderId}/asset`, genHeaders())
        .catch((e) => {
          console.error(e);
          return null;
        });

      const generalInfoCall = axios
        .get(`${domainUrl}/tender/v1/tender/${tenderId}`, genHeaders())
        .catch((e) => {
          console.error(e);
          return null;
        });

      const filesCall = axios
        .get(
          `${awsDocumentUrl}/Prod/v1/document?entity_type=T&entity_id=${tenderId}`,
          { token: sessionStorage.getItem("token") }
        )
        .catch((e) => {
          console.error(e);
          return null;
        });

      const suppliersCall = axios
        .get(`${domainUrl}/tender/v2/tender/${tenderId}/supplier`, genHeaders())
        .catch((e) => {
          console.error(e);
          return null;
        });

      const companiesCall = axios
        .get(`${domainUrl}/tender/v2/tender/${tenderId}/company`, genHeaders())
        .catch((e) => {
          console.error(e);
          return null;
        });

      const bidsCall = axios
        .get(`${domainUrl}/bid/v1/tender/${tenderId}/bid?is_submitted=1`, genHeaders())
        .catch((e) => {
          console.error(e);
          return null;
        });

      try {
        let title,
          is_awarded,
          description,
          awarded_to,
          tender_type,
          additional_info,
          published_date,
          items,
          files,
          closing_date,
          suppliers,
          bids,
          companies;

        // const bids = await Promise.all([
        //   bidsCall
        // ]);
        const [
          assetsRes,
          generalInfoRes,
          filesRes,
          suppliersRes,
          companiesRes,
          bidsRes,
        ] = await Promise.all([
          assetsCall,
          generalInfoCall,
          filesCall,
          suppliersCall,
          companiesCall,
          bidsCall,
        ]);

        if (assetsRes) {
          const itemsRes = await Promise.all(
            assetsRes.data.data.assets.map((el) =>
              axios
                .get(
                  `${domainUrl}/tender/v2/tender/asset/${el.tender_line_id}/items`,
                  genHeaders()
                )
                .catch((e) => {
                  console.error(e);
                  return [];
                })
            )
          );

          items = itemsRes.map((el, index) => {
            const key =
              assetsRes?.data?.data?.assets[index]?.line_text ??
              assetsRes?.data?.data?.assets[index]?.asset_name;
            const value = el.data.data.items;
            return {
              key,
              value,
            };
          });
        }

        if (generalInfoRes) {
          ({
            title,
            is_awarded,
            description,
            additional_info,
            tender_type,
            published_date,
            closing_date,
          } = generalInfoRes.data.data);

          tender_type = tender_type === "r" ? "Repair" :tender_type === "m" ? "Maintenance" : "Others";
        }

        if (filesRes) {
          files = filesRes?.data?.data?.documents;
        }

        if (suppliersRes) {
          suppliers = suppliersRes?.data?.data?.suppliers.length;
        }

        if (companiesRes) {
          companies = companiesRes?.data?.data?.companies;
        }
        if (bidsRes) {
          bids = bidsRes?.data?.data?.result;
          // eslint-disable-next-line array-callback-return
          awarded_to = bids.filter((bid)=>{
            if(bid.is_awarded === 1){
              return bid.company_name;
            }
          })
        }

        
        setInfo({
          title,
          is_awarded,
          description,
          tender_type,
          closing_date,
          additional_info,
          published_date,
          items,
          files,
          suppliers,
          companies,
          awarded_to,
          bids,
        });
      } catch (e) {
        console.error(e);
        message.error(
          e?.message ?? "Unable to retrieve information from server."
        );
      } finally {
        // setLoading(false);
      }
    };

    getInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    
    {
      title: <FormattedMessage id={'supplier'}/>,
      dataIndex: "supplier_name",
      key: "supplier_id",
      render: (name) => <div>{name}</div>,
    },
    {
      title: <FormattedMessage id={'company'}/>,
      dataIndex: "company_name",
      key: "supplier_id",
      render: (name) => <div>{name}</div>,
    },
    {
      title: <FormattedMessage id={'documents_attached'}/>,
      dataIndex: "documents",
      key: "supplier_id",
      render: (documents) => <div>{documents && documents?.length > 0 ? <FormattedMessage id={'yes'}/> : <FormattedMessage id={'no'}/>}</div>,
    },
    {
      title: <FormattedMessage id={'date'}/>,
      dataIndex: "created_at",
      key: "bid_id",
      render: (datetime) => {
        return <div>{datetime?.split("T")[0]}</div>;
      },
    },
    {
      title: <FormattedMessage id={'time'}/>,
      dataIndex: "created_at",
      key: "bid_id",
      render: (time) => <div>{formatAMPM(new Date(time))}</div>,
    },
    {
      title: <FormattedMessage id={'total'}/>,
      key: "bid_id",
      dataIndex: "sub_total",
      render: (price) => <div>{price}&nbsp;&euro;</div>,
      sorter: {
        compare: (a, b) => a.sub_total - b.sub_total,
      },
    },
    {
      title: <FormattedMessage id={'view'}/>,
      render: (_, record) => {
        return (
          <Button
            type="link"
            style={{ marginTop: 0 }}
            shape="circle"
            icon={
              <EyeFilled
                onClick={() => setViewId(record?.bid_id)}
                style={{ fontSize: "30px" }}
              />
            }
          />
        );
      },
    },
  ];

  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  return ( 
    <>
      <Header />

      <div className="container2">
        <h1 className="remove-whitespace " >Tender dashboard</h1>
        <p className="pt-8">Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
      </div>
      <div className="container">
        <div className="cardnw mt-2">
          <div className="d-flex">        <span className="dot"></span>
            <h3 className="pl-4 " ><strong> <span>{info.title}</span></strong></h3></div>

          <div className="d-flex card-justify">
            <div className="card02 mt-2">
              <div className="searchForm">
                <div className="formGroup">
                  <label>Tender Type:</label>
                  <span>{info.tender_type}</span>
                </div>

                <div className="formGroup">
                  <label><FormattedMessage id={'company'}/>:</label>
                  <span>{info?.companies && info?.companies[0]?.company_name}</span>
                </div>

                <div className="formGroup">
                  <label>Published Date:</label>
                  <span>{info.published_date}</span>
                </div>

                <div className="formGroup">
                  <label>Bid End Date:</label>
                  <span>{info.closing_date}</span>
                </div>
              </div>
            </div>

            <div className="card02 mt-2">
              <div className="searchForm">
                <div className="formGroup">
                  <label>Invited Suppliers:</label>
                  <span>{info.suppliers}</span>

                </div>
                <div className="formGroup">
                  <label><FormattedMessage id={'bid_received'}/>:</label>
                  <span>{info?.bids && Object.keys(info.bids).length}</span>

                </div>
                <div className="formGroup">
                  <label>Awarded To:</label>
                  <span>{info?.bids && info.bids[0].supplier_name}</span>

                </div>
                <div className="formGroup">
                  <label>Bid Amount:</label>
                  <span>{info?.bids ? info?.bids.reduce((a, b) => a + (b['total'] || 0), 0) : 0}</span>
                </div>
              </div>
            </div>
            <div className="card02 mt-2">
              <div className="searchForm">
                <div className="formGroup">
                  <label><FormattedMessage id={'desc'}/>:</label>

                </div>
                <p dangerouslySetInnerHTML={{ __html: info?.description }}></p>
              </div>
            </div>
          </div>
          <div className="d-flex card-justify">

            <div className="card02 mt-2">
              <div className="searchForm">
                <div className="formGroup">
                  <label>Items:</label>
                </div>
                <div className="formGroup d-flex" style={{'backgroundColor': '#dbdce0', 'padding': '0.3rem', 'borderRadius': '5px'}}>
                  <span>Item Name:</span>
                  <span>Qty:</span>
                </div>
                {info.items && info.items.map((item)=>
                  <div className="formGroup d-flex">
                    <span>{item.key}</span>
                    <span>{item.value.length}</span>
                  </div>
                )}
              </div>
            </div>

            <div className="card02 mt-2">
              <div className="searchForm">
                <div className="formGroup">
                  <label><FormattedMessage id={'files'}/>:</label>
                </div>

                <div className="formGroup">
                  <label>File Name:</label>
                </div>
                {/* {files && files.length && files.map(()=>{})} */}
              </div>
            </div>
            <div className="card02 mt-2">
              <div className="searchForm">
                <div className="formGroup">
                  <label>Additional Info:</label>

                </div>
                <p dangerouslySetInnerHTML={{ __html: info?.additional_info }}></p>
              </div>
            </div>


          </div>
        </div>



      </div>

      {/* second card */}
      <div className="container">
        <div className="cardnw mt-2">
          <div className="d-flex">
            <h3 className="m-5 " ><strong> <span>Bid Details</span></strong></h3></div>
          <hr></hr>

         
            {/* <div className="card02 mt-2">
              <div className="searchForm">
        
                <div className="formGroup pt-8">
                  <label>Supplier:</label>

                </div>
                <div className="formGroup pt-8">
                  <label>Bid date:</label>

                </div>

                <div className="formGroup pt-8">
                  <label>Bid Price:</label>
                </div>
              </div>
              <div className="float-right"><a>view&gt;</a></div>
            </div> */}

            {/* <div className="card03 mt-2">
              <div className="searchForm">
        
                <div className="formGroup pt-8">
                  <label className="text-white">Supplier:</label>

                </div>
                <div className="formGroup pt-8">
                  <label  className="text-white">Bid date:</label>

                </div>

                <div className="formGroup pt-8">
                  <label  className="text-white">Bid Price:</label>
                </div>
              </div>
              <div className="float-right " ><a className="text-white">view&gt;</a></div>
            </div> */}

           
            <div className=" row containern">
            <Table 
              columns={columns}
              dataSource={info?.bids}
              pagination={false} 
              locale={{emptyText: intl.formatMessage({ id:'no_data'})}}
            />
          </div>
          {/* <div className="d-flex card-justify">
            <div className="card02 mt-2">
              <div className="searchForm">
                <div className="d-flex">        <span className="dot"></span>
                  <h3 className="pl-4 " ><strong> <span>Build Pro.</span></strong></h3></div>
                <div className="formGroup pt-8">
                  <label>Bid date:</label>

                </div>

                <div className="formGroup pt-8">
                  <label>Bid Price:</label>
                </div>
              </div>
              <div className="float-right"><a>view&gt;</a></div>
            </div>

            <div className="card02 mt-2">
              <div className="searchForm">
                <div className="d-flex">        <span className="dot"></span>
                  <h3 className="pl-4 " ><strong> <span>Vision Inc.</span></strong></h3></div>
                <div className="formGroup pt-8">
                  <label>Bid date:</label>

                </div>

                <div className="formGroup pt-8">
                  <label>Bid Price:</label>
                </div>
              </div>
              <div className="float-right"><a>view&gt;</a></div>
            </div>
            <div className="card02 mt-2">
              <div className="searchForm">
                <div className="d-flex">        <span className="dot"></span>
                  <h3 className="pl-4 " ><strong> <span>Propex Inc.</span></strong></h3></div>
                <div className="formGroup pt-8">
                  <label>Bid date:</label>

                </div>

                <div className="formGroup pt-8">
                  <label>Bid Price:</label>
                </div>
              </div>
              <div className="float-right"><a>view&gt;</a></div>
            </div>



          </div> */}
        </div>



      </div>
      <div className="container">
        <div className="cardnw mt-2">
            <div className=" row containern">
            <>
              <MessagesCard />
            </>
          </div>
        </div>



      </div>
    <Modal
      destroyOnClose
      centered
      footer={null}
      onCancel={() => setViewId(null)}
      open={viewId}
      >
      <BidSummary showEdit={false} bidId={viewId} />
    </Modal>
      </>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
