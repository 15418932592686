import React from "react";
import PropTypes from "prop-types";
// import { useHistory } from 'react-router-dom';
import { Row, Col } from 'antd';
import InspectionLine from '../InspectionLine'
import { FormattedMessage } from "react-intl";

const InspectionCategory = ({ inspectionLines,categoryData, isReadOnly, indexKey, form, handleRemarksSearch, remarksOptions }) => {
	// console.log(inspectionLines,"=====inspectionLinesinspectionLines")
	// console.log(categoryData.inspection_category_name,"=====categoryData.inspection_category_namecategoryData.inspection_category_namecategoryData.inspection_category_name")
	// const history = useHistory();

	return (
		<>
				<Row className="category_row" key={'c'+indexKey} style={{textAlign:"left"}}>
					<Col md={6} lg={8} xl={8}>
						{}
						<FormattedMessage id={`${categoryData.inspection_category_name}`} />
					</Col>
						<Col md={10} lg={4} xl={5}>
							<FormattedMessage id="Condition rating"/>
						</Col>
						
						<Col md={10} lg={6} xl={11}>
							<FormattedMessage id={'comments'}/>
						</Col>
				</Row>
				<Row className="category_row" key={'r'+indexKey}>
					<Col md={6} lg={8} xl={8}>
					</Col>
					<Col md={6} lg={4} xl={5}>
						<Row  className="d-flex justify-content-between" style={{margin: '0 32px 0 3px'}}>	
							<Col span={1}>
								1
							</Col>
							<Col span={1}>
								2
							</Col>
							<Col span={1}>
								3
							</Col>
							<Col span={1}>
								4
							</Col>
							<Col span={1}>
								5
							</Col>
							<Col span={1}>
								OK
							</Col>
						</Row>
					</Col>
						{/* <Col span={1}>
						</Col>	 */}
					<Col span={8}>
					</Col>
				</Row>
				{
					!isReadOnly && 
					categoryData?.inspection_items.map((item, index) => (
						<InspectionLine inspectionLineItem={(inspectionLines?.length && inspectionLines[0]?.inspection_lines?.length )  && inspectionLines[0]?.inspection_lines?.filter((line)=>line?.inspection_item_name === item?.inspection_item_name) } key={index} remarksOptions={remarksOptions} lineData={item} isReadOnly={isReadOnly} handleRemarksSearch={handleRemarksSearch}/>
					))
				}
				{
					isReadOnly && 
					categoryData?.inspection_lines.map((item, index) => (
						<InspectionLine key={index} lineData={item} isReadOnly={isReadOnly}/>
					))
				}
		</>
	);
};

InspectionCategory.propTypes = {
	categoryData: PropTypes.shape({}),
	isReadOnly: PropTypes.bool,
};

export default InspectionCategory;