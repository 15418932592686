/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { message } from "antd";
import axios from "axios";

import { domainUrl } from "../../../helper";
import "../../Tender/ChooseHousingCompany/ChooseHousingCompany.css";
import { genHeaders, useTender } from "../Tender";
import { FormattedMessage, useIntl } from "react-intl";

const ChooseHousingCompany = () => {
  const { tenderDetails, onStepChange: move } = useTender();

  const [companies, setCompanies] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);

  const [allSelected, setAllSelected] = useState(false);

  const companyRef = useRef();
  const addressRef = useRef();
  const bidRef = useRef();
  const orgRef = useRef();
  const intl = useIntl();

  const [selectedCompanies, setSelectedCompanies] = useState([]);

  useEffect(() => {
    const getInfo = async () => {
      try {
        let companies = await axios.get(
          domainUrl + "/masterservicenew/master/company",
          genHeaders()
        );
        companies = companies.data.data.result;

        const orgInfo = companies?.map((el) =>
          axios.get(
            `/masterservicenew/master/organization/${el?.organization_id}`,
            genHeaders()
          )
        );

        let company_types = await Promise.all(orgInfo);

        company_types =
          company_types?.map((el) => el.data.data.company_type_id) ?? [];

        companies =
          companies?.filter(
            (_, index) => tenderDetails?.company_type === company_types[index]
          ) ?? [];

        if (companies?.length) {
          setCompanies(companies);
          setFilteredCompanies(companies);
          setSelectedCompanies(tenderDetails?.selectedCompanies ?? []);
          setAllSelected(companies.length === tenderDetails?.selectedCompanies.length ? true : false);

          const orgs = companies?.map((el) => el?.organization_name) ?? [];
          setOrganizations(Array?.from(new Set(orgs)));
        }
      } catch (e) {
        console.error(e);
        message.error(e?.data?.message ?? "Unable to fetch data from server");
      }
    };

    getInfo();
  }, []);

  const onFilterChange = () => {
    const company = companyRef.current.value?.toLowerCase() ?? "";
    const addr = addressRef.current.value?.toLowerCase() ?? "";
    const bid = bidRef.current.value?.toLowerCase() ?? "";
    const org = orgRef.current.value?.toLowerCase() ?? "";

    const filtered = companies?.filter(
      ({ company_name, address, business_id, organization_name }) => {
        const includesCompany = company_name.toLowerCase().includes(company);
        const includesAddress = address.toLowerCase().includes(addr);
        const includesBid = business_id.toLowerCase().includes(bid);
        const includesOrg = organization_name.toLowerCase().includes(org);
        // return true;
        return includesCompany && includesAddress && includesBid && includesOrg;
      }
    );

    setFilteredCompanies(filtered);
  };

  const onCompanySelect = ({ target }) => {
    const id = +target.value;
    setSelectedCompanies((c) => {
      const isPresent = c.indexOf(id) > -1;
      if (!isPresent) {
        const newCompanies = selectedCompanies.slice();
        newCompanies.push(id);
        return newCompanies;
      } else if (isPresent) {
        return c.filter((el) => el !== id);
      }
    });
  };

  const onSelectAll = () => {
    const companyIds = !allSelected ? filteredCompanies?.map((el) => +el.company_id) ?? [] : [];
    setSelectedCompanies(companyIds);
    setAllSelected(!allSelected);
  };

  const onBack = () => move(-1, { selectedCompanies });

  const onNext = async () => {
    try {
      const getExistingCompaniesForTender = await axios.get(
        `${domainUrl}/tender/v2/tender/${tenderDetails?.tender_id}/company`,
        genHeaders()
      );
      await Promise.all(
        getExistingCompaniesForTender.data.data.companies.map((el) =>
          axios.delete(
            `${domainUrl}/tender/v2/tender/${tenderDetails?.tender_id}/company/${el.company_id}`,
            genHeaders()
          )
        )
      );

      await axios.post(
        `${domainUrl}/tender/v2/tender/${tenderDetails?.tender_id}/companies`,
        selectedCompanies?.map((el) => ({ company_id: el })),
        genHeaders()
      );

      move(1, { selectedCompanies });
    } catch (e) {
      console.error(e);
      message.error(e?.message ?? "Unable to reach server.");
    }
  };

  return (
    <>
      <div>
        <div className="hedding">
          <h2><FormattedMessage id={'choose_company'}/></h2>
        </div>
        <div className="flexContainer">
          <div>
            <div className="formGroup">
              <h3 className="subheading"><FormattedMessage id={'filters'}/></h3>
            </div>
            <div className="formGroup">
              <label className="leftCol"><FormattedMessage id={'company'}/></label>
              <div className="selectDate">
                <input
                  ref={companyRef}
                  onChange={onFilterChange}
                  type="text"
                  id="company_name"
                  className="formFiled"
                />
                <div className="searchIcon"></div>
              </div>
            </div>
            <div className="formGroup">
              <label className="leftCol">Address</label>
              <div className="selectDate">
                <input
                  ref={addressRef}
                  onChange={onFilterChange}
                  type="text"
                  className="formFiled"
                  id="address"
                />
                <div className="searchIcon"></div>
              </div>
            </div>
            <div className="formGroup">
              <label className="leftCol">Bussiness ID</label>
              <div className="selectDate">
                <input
                  ref={bidRef}
                  onChange={onFilterChange}
                  type="text"
                  id="business_id"
                  className="formFiled"
                />
                <div className="searchIcon"></div>
              </div>
            </div>
            <div className="formGroup">
              <label className="leftCol">Choose an Organization</label>
              <div className="selectDate">
                <div className="costumSelect">
                  <select
                    ref={orgRef}
                    name="country"
                    onChange={onFilterChange}
                    id="organization_name"
                    className="inputFiled"
                  >
                    <option value="">{intl.formatMessage({id: 'all'})}</option>
                    {organizations?.map((el, index) => (
                      <option key={`${el}${index}`} value={el}>
                        {el}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="formGroup">
              <h3 className="subheading">Choose Company</h3>
            </div>
            <div className="cardBlock">
              <table className="tableGrid" width="100%" border="0">
                <tbody>
                  <tr>
                    <th align="left" valign="top">
                      Choose Company
                    </th>
                    <th align="left" valign="top">
                      Select
                    </th>
                  </tr>
                  {filteredCompanies?.map((el, index) => (
                    <tr key={`${el.created_at}${el.business_id}${index}`}>
                      <td align="left" valign="top">
                        {el.company_name}
                      </td>
                      <td align="left" valign="top">
                        <div className="radioBtn">
                          <input
                            type="checkbox"
                            id={`${el.business_id}${el.company_name}${index}`}
                            value={el.company_id}
                            checked={
                              selectedCompanies?.includes(+el.company_id)
                                ? "checked"
                                : null
                            }
                            onChange={onCompanySelect}
                          />
                          <label
                            htmlFor={`${el.business_id}${el.company_name}${index}`}
                          ></label>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="btn housingbtn" onClick={onSelectAll}>
                {!allSelected ? 'Select All' : 'Unselect All'}
              </div>
            </div>
          </div>
        </div>
        <div className="buttonBlock">
          <div onClick={onBack} className="btn cancelBtn">
            <FormattedMessage id="bakc"/>
          </div>
          <div onClick={onNext} className="btn">
          <FormattedMessage id="next"/>
          </div>
        </div>
      </div>
    </>
  );
};

// Tender.propTypes = {};

export default ChooseHousingCompany;
