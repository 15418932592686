import React, { useState, useEffect } from "react";
import { 
  useDispatch, 
  // useSelector 
} from "react-redux";
import PropTypes from "prop-types";
import { DeleteOutlined } from '@ant-design/icons';
import { Row, Spin, message } from "antd";

import { fetchFiles, fetchDocument, deleteDocument } from "../../actions/documents";
import { FormattedMessage, useIntl } from "react-intl";



const FileDisplay = ({copiedFiles, entityType, setFileList, fileList, entityId, hideTitle, deleteFiles, getFileAvailability, updateFileList, isReadOnly = false}) => {

    const dispatch = useDispatch();
    const intl = useIntl();
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // const [deletedId, setDeletingId] = useState('');
    // useEffect(()=>{
    //   if(copiedFiles){
    //     setUploadedFiles(copiedFiles);
    //   }
    // },[copiedFiles])
    const getDocuments = async() => {
      // let entities = null;
		  const documents = await dispatch(fetchFiles({entity_type: entityType, entity_id: entityId}));
      if(Object.values(documents.payload?.entities?.files || [])?.length){
        await Promise.all(
          // entities = 
          Object.values(documents.payload?.entities?.files).map(async(file)=>{
            file.src = await onFileLoad(file)
            return file;
          })
        )
      }
      let uploadedFilesN = Object.values(documents.payload?.entities?.files || []);
      if(getFileAvailability) {
        getFileAvailability(uploadedFilesN);
      }
		  // setUploadedFiles([...uploadedFiles, ...Object.values(documents.payload?.entities?.files || [])]);
		  setUploadedFiles(Object.values(documents.payload?.entities?.files || []));
      setFileList(Object.values(documents.payload?.entities?.files || []));
      if(updateFileList) {
        updateFileList(Object.values(documents.payload?.entities?.files || []));
      }
		}
    // console.log(uploadedFiles,"=====uploadedFilesuploadedFiles")
    useEffect(() => {
      // if(fileList?.length > 0){
        getDocuments();
        
      // }
		// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [fileList]);

		const onFileClick = async (e, file) => {
      if(e.target.nodeName.toLowerCase() === 'path' || e.target.nodeName.toLowerCase() === 'svg') {
        e.stopPropagation();
        return;
      }
			const response = await dispatch(fetchDocument(file.document_id));
      let link =  response.payload?.entities?.files[file.document_id]?.signed_link || ''
      const win = window.open(link, "_blank");
      win.focus();
    };
		const onFileLoad = async (file) => {
			const response = await dispatch(fetchDocument(file?.document_id));
      let link =  response.payload?.entities?.files[file?.document_id]?.signed_link || '';
      let base64 = await fetch(`${link}`)
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise((res) => {
          reader.onloadend = () => {
          res(reader.result);
        }})
      })
      // console.log(base64,"=====base64base64")

      base64 = base64.replace('application/json','image/jpg');
      base64 = base64.replace('application/octet-stream','image/jpg');
      return base64;
    };

    const onFileDelete = async (file) => {
      // setDeletingId(file.document_id);
      setIsLoading(true);
      const response = await dispatch(deleteDocument(file.document_id));
      if(response.payload.success) {
        message.success(`${intl.formatMessage({ id:'document_deleted_successfully'})}`);
      }
      else {
        message.error(response.payload.message || 'Document not deleted');
      }
      await getDocuments();
      setIsLoading(false);
    };

	return (
		<>
        <Spin spinning={isLoading}>

    {
      !hideTitle && 
      <h2><FormattedMessage id="files_uploaded" /></h2>
    }
      {
        !isReadOnly ?
              <div style={{ maxHeight: '40vh', overflowY: 'auto'}}>  
                {uploadedFiles?.map((file,fileIndex) =>(
                    <div
                      onClick={(e) => onFileClick(e, file)}
                      key={fileIndex}
                      style={{ cursor: "pointer", position: 'relative' }}
                      className="filesNames mt-2"
                    >
                      <div className="filesDes">
                        <span><FormattedMessage id="file_desc" /></span>
                        <span>{file.file_description}</span>
                      </div>
                      <div className="fileFormat">
                        <div className="fileType">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="51.467"
                            height="68.622"
                            viewBox="0 0 51.467 68.622"
                          >
                            <path
                              id="Icon_awesome-file-image"
                              data-name="Icon awesome-file-image"
                              d="M51.467,16.344v.812H34.311V0h.812A3.217,3.217,0,0,1,37.4.942L50.525,14.069A3.217,3.217,0,0,1,51.467,16.344Zm-18.228,5.1a3.226,3.226,0,0,1-3.217-3.217V0H3.217A3.217,3.217,0,0,0,0,3.217V65.406a3.217,3.217,0,0,0,3.217,3.217H48.25a3.217,3.217,0,0,0,3.217-3.217V21.444ZM15.084,23.589a6.433,6.433,0,1,1-6.433,6.433A6.433,6.433,0,0,1,15.084,23.589ZM42.962,55.756H8.651l.065-6.5,5.3-5.3a1.532,1.532,0,0,1,2.209.065l5.3,5.3L35.391,35.448a1.608,1.608,0,0,1,2.275,0l5.3,5.3Z"
                              fill="#2B7776"
                            />
                          </svg>
                        </div>
                        <div className="fileInfo">
                          <div className="text">{file.file_name}</div>
                          {/* <div className="fileSize">200kb</div> */}
                        </div>
                        {
                          deleteFiles && 
                            <DeleteOutlined
                              onClick={() => onFileDelete(file)}
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "30px",
                                color: "red",
                                fontSize: '12px',
                              }}
                            />
                        }
                        
                      </div>
                    </div>
                ))}
              </div>
            :
            // <div className='page-break' style={{display: 'flex',flexDirection: 'column',alignItems: 'center', marginTop:'10px'}}>  
            //   {uploadedFiles.map((file,fileIndex) =>(
            //     <div style={{display: "block",height: '90mm', borderRadius:'10px',marginRight: "5px",border: "1px solid lightgrey",padding: "2px", textAlign: "center",textOverflow: 'ellipsis',/*width: '500px',height: '500px',*/overflow: 'scroll', width:'50%'}}>
            //       <span>{file?.file_description}</span>
            //       <br/>
            //       <img src={`${file?.src}`} onClick={(e) => onFileClick(e, file)}  alt={file?.file_name} style={{borderRadius:'10px',objectFit: 'cover',width: '110mm',height: '75mm'}} />
            //     </div>
            //   )) }     
            // </div>
            uploadedFiles?.map((file,fileIndex) =>(
              <div className="page-break">
                <Row style={{justifyContent: 'end', display: 'none'}} className="pdf_header">
                    <div className="pdf-header"></div>
                </Row>
                <div className={''} style={{display: 'flex',flexDirection: 'column',alignItems: 'center', marginTop:'10px'}}>  
                  <div style={{display: "block",height: '160mm', borderRadius:'10px',marginRight: "5px",border: "1px solid lightgrey",padding: "2px", textAlign: "center",textOverflow: 'ellipsis',/*width: '500px',height: '500px',*/overflow: 'scroll', width:'100%'}}>
                    <span>{file?.file_description}</span>
                    <br/>
                    <img src={`${file?.src}`} onClick={(e) => onFileClick(e, file)}  alt={file?.file_name} style={{borderRadius:'10px',objectFit: 'cover',width: '220mm',height: '150mm'}} />
                  </div>
                </div>
              </div>
            ))      
      }
			{
				uploadedFiles.length === 0 && 
				<p><FormattedMessage id="no_files_uploaded" /></p>
			}
          </Spin>

		</>
	);
};

FileDisplay.propTypes = {
	entityType: PropTypes.string,
  entityId: PropTypes.string,
};

export default FileDisplay;