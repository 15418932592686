/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import FileUpload from "../../components/FileUpload";
import EuroInput from "../../components/EuroInput";
import { Link } from "react-router-dom";
import { Space, Checkbox, message, Spin, Modal } from "antd";
import BidSummary from "./Summary";
import {
  // EditOutlined,
  CheckOutlined,
  // PlusCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { useHistory } from "react-router";
import { domainUrl } from "../../helper";
import { genHeaders } from "../../components/Tender/Tender";
import ConfirmationBox from '../../components/ConfirmationBox';
import FileDisplay from "../../components/FileDisplay";

import "./bid.less";
import { FormattedMessage, useIntl } from "react-intl";

const FULL = "full";
const PARTIAL = "partial";
const TOTAL = "total";
const FILE_POST_URL = `${domainUrl}/tender/v1/document`;

export default function Bid(props) {
  
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [tenderTitle, setTenderTitle] = useState("");
  const [bidType, setBidType] = useState(TOTAL);
  const [bidData, setBidData] = useState({});
  const [loading, setLoading] = useState(true);
  const [assets, setAssets] = useState([]);
  const { tenderId } = props.match.params;
  const [total, setTotal] = useState(0);
  const [Hourlyrate,setHourlyrate] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [fileToUploadList, setFileToUploadList] = useState([]);
  const [bidID, setBidID] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const[Tenderlist,setTenderlist]=useState({});
  const [showUploadConfirmationModal, setShowUploadConfirmationModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const intl=useIntl()
  const verifyBidPlacement = () => {
    let isInvalid = false;

    if (isNaN(total) || total <= 0) {
      message.error( intl.formatMessage({id: 'error.Total'}) );
      return true;
    }

    const isFalsy = (value) =>
      value.__bid_price__ === undefined ||
      isNaN(value.__bid_price__) ||
      value.__bid_price__ <= 0;

    o_loop: for (let asset of assets) {
      const predicate =
        bidType === FULL || (bidType === PARTIAL && asset.__bid_partial__);
      if (predicate && isFalsy(asset)) {
        message.error("Placed asset bid is not acceptable.");
        isInvalid = true;
        break;
      }

      if (predicate && asset?.__item_lvl_pricing__ && asset?.items?.length) {
        let sum = 0;
        for (let item of asset.items) {
          if (isFalsy(item)) {
            message.error(`Placed item bid value is not acceptable`);
            isInvalid = true;
            break o_loop;
          } else {
            sum += item.__bid_price__;
          }
        }
        if (sum !== asset.__bid_price__) {
          message.error("Item sum should be equal to asset bid value");
          isInvalid = true;
          break;
        }
      }
    }
    // return true;
    return isInvalid;
  };

  const placeBid = async () => {
    if (verifyBidPlacement()) return;

    try {
      const user_id = sessionStorage.getItem("user_id");
      const supplier_id = sessionStorage.getItem("entity_id");
      // let userData = await axios.get(`/users/${user_id}`, genHeaders());
      // userData = userData?.data?.data;
      //if (userData?.type === "S") {
        // const supplier_id = userData?.entities?.filter(
        //   (entity) => entity?.entity_type === "supplier"
        // )[0]?.entity_id;

        //check for preexisting bids...and if present...delete them.
        //better alternative would be to patch preexisting bids...instead of deleting and creating a new one.
        // let bidRes = await axios.get(
        //   `/bid/v1/tender/${tenderId}/bid?supplier_id=${supplier_id}`,
        //   genHeaders()
        // );

        // bidRes = bidRes?.data?.data?.result;

        // // have this condn just in case 'supplier_id' query param above returns all results;
        // bidRes = bidRes?.filter((el) => el.supplier_id === supplier_id);

        // if (bidRes?.length) {
        //   const bidIds = bidRes?.map((el) =>
        //     axios.delete(`/bid/v1/bid/${el.bid_id}`, genHeaders())
        //   );
        //   await Promise.all(bidIds);
        // }
        let bid_ID;
        if(!bidData?.bid_id && !bidID) {
        const createBid = await axios.post(
          `/bid/v1/bid/`,
          {
            tender_id: +tenderId,
            is_partial_bid: bidType === PARTIAL ? true : false,
            sub_total: +total,
            hourly_rate:parseInt(Hourlyrate)
          },
          genHeaders()
        );
        bid_ID = createBid?.data?.data?.bid_id;

        setBidID(bid_ID);
        }
        else {
          await axios.patch(
            `/bid/v1/bid/${bidData?.bid_id || bidID}`,
            {
              tender_id: +tenderId,
              is_partial_bid: bidType === PARTIAL ? true : false,
              sub_total: +total,
              hourly_rate:parseInt(Hourlyrate) 
            },
            genHeaders()
          );
        }

        const assetsToConsider = assets?.filter((asset) => {
          if (bidType === FULL) return true;

          if (bidType === PARTIAL) {
            return asset.__bid_partial__ === true;
          }

          return false;
        });
        const assetsToCreate = [];
        const itemsToCreate = [];
        if (assetsToConsider?.length) {
          assetsToConsider?.map(async(asset, index) => {
            if(asset?.bid_line_id){
              await axios.patch(
                `/bid/v2/bid/assets/${asset?.bid_line_id}`,
                {amount: +asset.__bid_price__},
                genHeaders()
              );
              asset?.items?.map(async(item) => {
                if(item?.bid_item_id){
                  await axios.patch(
                    `/bid/v2/bid/assets/items/${item?.bid_item_id}`,
                    {amount: +item.__bid_price__},
                    genHeaders()
                  );
                }
                else {
                  itemsToCreate[index]?.push(item);
                }
                if(itemsToCreate[index]?.length > 0) {
                axios.post(
                  `/bid/v1/bid/assets/${asset?.bid_line_id}/items/bulk`,
                  itemsToCreate[index]?.map((item) => ({
                    tender_line_item_id: item.tender_line_item_id,
                    amount: +item.__bid_price__,
                  })),
                  genHeaders()
                );
                }
              });

            }
            else assetsToCreate.push(asset);
            //amount: +asset.__bid_price__,
          })
          if(assetsToCreate.length > 0) {
            const assetsBid = await axios.post(
              `/bid/v1/bid/${bidData?.bid_id || bidID || bid_ID}/assets/bulk`,
              assetsToCreate?.map((asset) => ({
                tender_line_id: asset.tender_line_id,
                amount: +asset.__bid_price__,
              })),
              genHeaders()
            );

            const assetsBidIds = assetsBid?.data?.data?.map(
              (bid) => bid.bid_line_id
            );
  
            await Promise.all(
              assetsToCreate?.map((asset, index) => {
                if (asset?.items?.length && asset?.__item_lvl_pricing__) {
                  return axios.post(
                    `/bid/v1/bid/assets/${assetsBidIds[index]}/items/bulk`,
                    asset.items.map((item) => ({
                      tender_line_item_id: item.tender_line_item_id,
                      amount: +item.__bid_price__,
                    })),
                    genHeaders()
                  );
                }
                return null;
              })
            );
          }

          
        }

        setStep(step + 1);
      // } else {
      //   throw new Error("User is not a part of supplier");
      // }
    } catch (e) {
      console.error(e);
      message.error("Could not create bid.");
    }
  };

  const confirmBid = async() => {
    try {
      await axios.patch(
        `/bid/v1/bid/${bidID}`,
        {
          is_partial_bid: bidType === PARTIAL ? true : false,
          is_submitted: 1
        },
        genHeaders()
      );
      setShowConfirmationModal(false);
      setShowModal(true);
    }catch (e) {
      console.error(e);
      message.error("Could not place bid.");
    }
   
  }

  const onAssetBlur = async (value, index) => {
    setAssets((c) => {
      const newAssets = c.slice();
      newAssets[index].__bid_price__ = +value;
      return newAssets;
    });
  };

  const onItemBlur = (value, assetIndex, itemIndex) => {
      const newAssets = assets.slice();
      newAssets[assetIndex].items[itemIndex].__bid_price__ = +value;
      newAssets[assetIndex].__bid_price__=0;
      newAssets[assetIndex].items?.map(item => {
        return newAssets[assetIndex].__bid_price__ += +item.__bid_price__;
      });
      setAssets([]);
      setTimeout(() => {
        setAssets(newAssets);
      });
  };

  const onPartial = (assetIndex, checked) => {
    const newAssets = assets.slice().map((el) => ({ ...el }));
    newAssets[assetIndex].__bid_partial__ = checked;
    setAssets(newAssets);
  };

  const onItemLvlPricing = (assetIndex) => {
    assets[assetIndex].items?.map(item => {
      return item.__bid_price__ = 0;
    })
    const newAssets = assets?.slice()?.map((el) => ({ ...el }));
    newAssets[assetIndex].__bid_price__ = 0;
    newAssets[assetIndex].__item_lvl_pricing__ = !newAssets[assetIndex]
      .__item_lvl_pricing__;
      setAssets([]);
    setTimeout(() => {
      setAssets(newAssets);
    });
  };

  const onConfirmation = () => {
   confirmBid();
  };

  const onUploadNext = () => {
    const filesToUpload = fileList?.filter(doc => doc?.documentId === null);
    if(filesToUpload.length > 0) {
       setShowUploadConfirmationModal(true);
    }
    else{
      setStep((c) => c + 1);
    }
  };

  const confirmSubmit = (e) => {
    setShowUploadConfirmationModal(false);
    setStep((c) => c + 1);
  }

  const declineSubmit = (e) => {
    setShowUploadConfirmationModal(false);
  }

  useEffect(() => {
    const newTotal = assets
      ?.filter((el) => {
        if (bidType === FULL) {
          return true;
        } else if (bidType === PARTIAL && el.__bid_partial__) {
          return true;
        } else {
          return false;
        }
      })
      ?.reduce((acc, asset) => acc + +asset.__bid_price__, 0);

    setTotal(newTotal ?? 0);
  }, [bidType, assets]);

  useEffect(() => {
    async function init() {
      const supplier_id = sessionStorage.getItem("entity_id");
      try {
        //verify whether supplier has tender;
        const res = await axios.get(
          `${domainUrl}/tender/v2/tender/supplier?tender_id=${tenderId}`,
          genHeaders()
        );
        const tenderList = res.data.data.result.map((el) => el.tender_id);
        const list=res.data.data.result
        setTenderlist(list)

        const tenderIndex = tenderList.indexOf(+tenderId);
        if (res.data.data.result.length === 0) {
          history.goBack();
          return;
        }
        let bidResult = await axios.get(
          `/bid/v1/tender/${tenderId}/bid?supplier_id=${supplier_id}`,
          genHeaders()
        );

        setBidData(bidResult?.data?.data?.result[0]);
        const bidID = bidResult?.data?.data?.result[0]?.bid_id;

        setBidID(bidID);

        const bidRes = bidResult?.data?.data?.result[0];
        const tenderTitle = res.data.data.result[tenderIndex].title;
        const assetInfo = await axios.get(
          `/tender/v1/tender/${tenderId}/asset`,
          genHeaders()
        );
        setLoading(false);
        if (assetInfo.data.success) {
          const addBidInfo = assetInfo.data.data.assets.map((asset, index) => ({

            __bid_partial__: bidRes?.is_partial_bid === 1 ? true : false,
            bid_line_id: bidRes?.lines && bidRes?.lines[index]?.bid_line_id,
            __bid_price__: bidRes?.lines && bidRes?.lines[index]?.line_amount || 0,
            __item_lvl_pricing__: false,
            ...asset,
            items: asset?.items?.map((item, itemIndex) => ({
              bid_item_id: bidRes?.lines && [index]?.items && bidRes?.lines[index]?.items[itemIndex]?.bid_item_id,
              __bid_price__: bidRes?.lines && [index]?.items && bidRes?.lines[index]?.items[itemIndex]?.amount || 0,
              ...item,
            })),
          }));
          setAssets(addBidInfo);
          setTenderTitle(tenderTitle);
        } else {
          setLoading(false);
          message.error(assetInfo.data.message);
        }
      } catch (e) {
        console.error(e);
        setLoading(false);
        message.error(e?.message);
      }
    }

    init();
  }, []);

  const addFileList = (files) => {
    setFileList([
      ...fileList,
      ...files
    ])
  }


  return (
    <div className="__Bid__">
      <Header />
      <div className="mainContainer">
        <div className="innerContainer">
          {loading ? (
            <div style={{ height: 500 }} className="d-flex j-center">
              <Spin style={{ margin: "auto" }}></Spin>
            </div>
          ) : (
            <>
              {tenderTitle && (
                <div className="hedding">
                  <h2>{tenderTitle}</h2>
                </div>
              )}
              {step !== 3 && (
                <ul className="tenderHeader">
                  <li className={step >= 1 ? "active" : null}>{<FormattedMessage id="place_bids"/>}</li>
                  <li className={step >= 2 ? "active" : null}>{<FormattedMessage id="upload_files"/>}</li>
                </ul>
              )}
              {step === 1 && (
                <>
                  <div className="price-root">
                    {assets?.map((o, key) => {
                      return (
                        <div key={key} style={{ margin: "20px 0" }}>
                          <div className="fieldcontainer">
                            <h3>{o.asset_name || o.line_text}</h3>
                            <div className="fieldcontainer__inputcontainer">
                              {/* <EuroInput
                                disabled={bidType === TOTAL || o?.__item_lvl_pricing__ || o?.items?.filter(item => item.__bid_price__ > 0).length > 0}
                                initialValue={o.__bid_price__}
                                onBlur={(e) => onAssetBlur(e.target.value, key)}
                                hide={ bidType === TOTAL }
                              /> */}
                              {bidType === PARTIAL && (
                                <Checkbox
                                  style={{
                                    position: "absolute",
                                    right: "-25px",
                                  }}
                                  checked={o.__bid_partial__}
                                  onChange={(e) =>
                                    onPartial(key, e.target.checked)
                                  }
                                />
                              )}
                            </div>
                          </div>

                          {o?.__item_lvl_pricing__ && bidType !== TOTAL ? (
                            <>
                              <Space onClick={() => onItemLvlPricing(key)}>
                                <MinusCircleOutlined />
                                Enter asset level pricing alone
                              </Space>
                              {o?.items?.map((item, itemIndex) => (
                                <div
                                  key={itemIndex}
                                  style={{ margin: "10px 0" }}
                                >
                                  <div className="fieldcontainer">
                                    <h3 className="item">{item?.item_text}</h3>
                                    <EuroInput
                                      disabled={bidType === TOTAL}
                                      initialValue={item.__bid_price__}
                                      onBlur={(e) =>
                                        onItemBlur(
                                          e.target.value,
                                          key,
                                          itemIndex
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              ))}
                            </>
                          ) : (
                            o?.items?.length && (
                              <Space onClick={() => bidType !== TOTAL && onItemLvlPricing(key)}>
                                {/* <PlusCircleOutlined />
                                Enter item level pricing. */}
                              </Space>
                            )
                          )}
                        </div>
                      );
                    })}

                    <div className="fieldcontainer">
                      <h3><FormattedMessage id={'total'}/></h3>
                      <EuroInput
                        value={total}
                        onChange={(e) => setTotal(e.target.value)}
                        disabled={bidType !== TOTAL}
                      />
                    </div>
                    
                    {Tenderlist[0]?.tender_type==='m'?
                      
                      
                          <div style={{marginTop:"5%"}} className="fieldcontainer">
                      <h3>Hourly Rate</h3>
                      <EuroInput
                        value={Hourlyrate}
                        onChange={(e) => setHourlyrate(e.target.value)}
                        // disabled={bidType !== TOTAL}
                      />
                    </div>:''
                    
                  }
                  </div>
                  {/* Uncomment this for full,total, patial bid option */}
                  {/* <div class="d-flex justify-content-center form-group">
                    <div className="radioBtn">
                      <label htmlFor={"full"} style={{fontSize: "1.2rem"}}>
                        <input
                          type="radio"
                          value="full"
                          checked={bidType == "full" ? true : false}
                          id={"full"}
                          onChange={() => setBidType("full")}
                          className="custom-radio"
                        />&nbsp;&nbsp;
                      {<FormattedMessage id="full_bid"/>}
                      </label>
                    </div>
                    &emsp;&emsp;
                    <div className="radioBtn">
                      
                      <label htmlFor={"partial"} style={{fontSize: "1.2rem"}}>
                        <input
                          type="radio"
                          value="partial"
                          checked={bidType == "partial" ? true : false}
                          id={"partial"}
                          onChange={() => setBidType("partial")}
                          className="custom-radio"
                        />&nbsp;&nbsp;
                        {<FormattedMessage id="partial_bid"/>}
                      </label>
                    </div>
                    &emsp;&emsp;
                    <div className="radioBtn">
                      <label htmlFor={"total"} style={{fontSize: "1.2rem"}}>
                        <input
                          type="radio"
                          value="total"
                          checked={bidType == "total" ? true : false}
                          id={"total"}
                          onChange={() => setBidType("total")}
                          className="custom-radio"
                        />&nbsp;&nbsp;
                        {<FormattedMessage id="total_bid"/>}
                      </label>
                    </div> 
                  </div> */}
                  {/* Uncomment this for full,total, patial bid option */}

                  <div className="d-flex j-space">
                    <div className="btn" onClick={() => history.goBack()}>
                    <FormattedMessage id="back"/>
                    </div>
                    <div onClick={placeBid} className="btn">
                    <FormattedMessage id="next"/>
                    </div>
                  </div>
                </>
              )}
              {step === 2 && (
                <>
                  <FileUpload
                    fileList={fileList}
                    filePostURL={FILE_POST_URL}
                    showTitle={false}
                    entityType={"B"}
                    entityID={bidID}
                    onFilesStatusChange={(e) => setFileToUploadList(e)}
                    onFilesUploadStatusChange={(e) => setFileList(e)}
                    addFileList={addFileList}
                  />

                  <FileDisplay entityType={'B'} fileList={fileList} entityId={bidID} hideTitle={false} deleteFiles={true}/>

                  <div className="d-flex j-space">
                    <div
                      className="btn"
                      onClick={() => {
                        setStep((c) => c - 1);
                      }}
                    >
                      <FormattedMessage id="back"/>
                    </div>
                    <div onClick={onUploadNext} className="btn">
                    <FormattedMessage id="next"/>
                    </div>
                  </div>
                  <ConfirmationBox showConfirmationBox={showUploadConfirmationModal} message={<FormattedMessage id="you_have_added_files_but_not_uploaded_proceed_without_uploading"/> } confirmSubmit={confirmSubmit} declineSubmit={declineSubmit} />
                </>
              )}
              {step === 3 && (
                <>
                  <BidSummary
                    showEdit
                    bidId={bidID}
                    onFileEdit={() => setStep(step - 1)}
                    onBidPricingEdit={() => setStep(step - 2)}
                  />
                  <div className="d-flex j-space">
                    <div
                      className="btn"
                      onClick={() => {
                        setStep(step - 1);
                      }}
                    >
                      <FormattedMessage id={'back'}/>
                    </div>
                    <div
                      className="btn"
                      type="primary"
                      onClick={() => setShowConfirmationModal(true)}
                    >
                      <FormattedMessage id={'submit_bid'}/>
                    </div>
                  </div>
                </>
              )}
              <Modal
                open={showConfirmationModal}
                closable={false}
                footer={null}
              >
                <h2 style={{ textAlign: "center" }}>
                  <FormattedMessage id={'want_to_submit_this_bid'}/>
                </h2>
                <div className="d-flex j-center f-col">
                  <div className="btn" onClick={onConfirmation}>
                    <FormattedMessage id="yes" />
                  </div>
                  <div
                    className="btn"
                    onClick={() => {
                      setShowConfirmationModal(false);
                    }}
                  >
                    <FormattedMessage id="no" />
                  </div>
                </div>
              </Modal>
              <Modal open={showModal} closable={false} footer={null}>
                <div className="d-flex j-center f-col">
                  <div className="m-auto">
                    <CheckOutlined style={{ fontSize: 50, color: "#4caf50" }} />
                  </div>
                  <p className="m-auto" style={{ fontSize: 18 }}>
                    <FormattedMessage id="bid_placed_successfully" />
                  </p>
                  <div className="d-flex j-center">
                    <Link className="btn" to="/dashboard">
                      <FormattedMessage id="go_to_dashboard" />
                    </Link>
                  </div>
                </div>
              </Modal>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
