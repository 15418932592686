import { schema } from "normalizr";
import { createAction } from "redux-api-middleware";
import get from 'lodash/get';
import {
	handleResponse,
	handleError,
    handleReducerResponse,
    handleQuery,
} from "../utils";
import { domainUrl } from "../helper";
import apiUrls  from "../utils/apiUrls";

export const FETCH_LOGIN_REQUEST = "FETCH_LOGIN_REQUEST";
export const FETCH_LOGIN_SUCCESS = "FETCH_LOGIN_SUCCESS";
export const FETCH_LOGIN_FAILURE = "FETCH_LOGIN_FAILURE";

export const FETCH_REMINDER_REQUEST = "FETCH_REMINDER_REQUEST";
export const FETCH_REMINDER_SUCCESS = "FETCH_REMINDER_SUCCESS";
export const FETCH_REMINDER_FAILURE = "FETCH_REMINDER_FAILURE";

export const VALIDATE_TOKEN_REQUEST = "VALIDATE_TOKEN_REQUEST";
export const VALIDATE_TOKEN_SUCCESS = "VALIDATE_TOKEN_SUCCESS";
export const VALIDATE_TOKEN_FAILURE = "VALIDATE_TOKEN_FAILURE";

export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const RESET_PWD_REQUEST = "RESET_PWD_REQUEST";
export const RESET_PWD_SUCCESS = "RESET_PWD_SUCCESS";
export const RESET_PWD_FAILURE = "RESET_PWD_FAILURE";

export const CHANGE_PWD_REQUEST = "CHANGE_PWD_REQUEST";
export const CHANGE_PWD_SUCCESS = "CHANGE_PWD_SUCCESS";
export const CHANGE_PWD_FAILURE = "CHANGE_PWD_FAILURE";

export const ADD_COMPANY_REQUEST = "UPDATE_USER_REQUEST";
export const ADD_COMPANY_SUCCESS = "UPDATE_USER_SUCCESS";
export const ADD_COMPANY_FAILURE = "UPDATE_USER_FAILURE";


export const authSchema = new schema.Entity("auth", {}, {
	idAttribute: "user_id",
});

export const userSchema = new schema.Entity("users", {}, {
	idAttribute: "id",
});

export const login = (data) => 
    createAction({
        endpoint: `${domainUrl}/auth/login`,
		method: "POST",
        body: JSON.stringify(data),
        headers: {
            client_code: "ht_front",
            "Content-Type": "application/json",
          },
		types: [
			{ type: FETCH_LOGIN_REQUEST },
			{
				type: FETCH_LOGIN_SUCCESS,
				payload: handleReducerResponse,
			},
			{ type: FETCH_LOGIN_FAILURE, payload: handleError },
		],
    });

export const closingDateReminder = (params) => createAction({
    endpoint: `${domainUrl}/tender/v1/reminder?${handleQuery(params)}`,
		method: "GET",
    headers: {
      client_code: "ht_front",
      token:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb2RlIjoiVTYwNWEyYTZjbHg4cXF5IiwiaWF0IjoxNjIxMTQ2MDEyLCJleHAiOjE2MjEyMzI0MTJ9.9MHYjOf0ycbi8iAExzKo3My-MYOXU6NyIFwPMocP3g0",
      "Content-Type": "application/json",
    },
    types: [
      { type: FETCH_REMINDER_REQUEST },
      {
          type: FETCH_REMINDER_SUCCESS,
          payload: (action, state, res) =>
          handleReducerResponse(action, state, res),
      },
      { type: FETCH_REMINDER_FAILURE, payload: handleError },
    ],
});

/**
 * Returns a redux api middleware action that makes a request to validate token.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const validateToken = () =>
	createAction({
		endpoint: `${domainUrl}/auth/token`,
		method: "POST",
        body: JSON.stringify({token: sessionStorage.getItem('token')}),
        headers: {
            client_code: "ht_front",
            "Content-Type": "application/json",
          },
		types: [
			{ type: VALIDATE_TOKEN_REQUEST },
			{
				type: VALIDATE_TOKEN_SUCCESS,
				payload: (action, state, res) =>
					handleResponse([userSchema], action, state, res),
			},
			{ type: VALIDATE_TOKEN_FAILURE, payload: handleError },
		],
	});

/**
 * Returns a redux api middleware action that makes a request to get user details.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const getUser = (userId, deferBailOut) =>
 createAction({
     endpoint: `${domainUrl}/users/${userId}`,
     method: "GET",
     headers: {
         client_code: "ht_front",
         token: sessionStorage.getItem("token"),
         "Content-Type": "application/json",
       },
     bailout: !deferBailOut && (store => get(store, `entities.users.${userId}`, false)),
     types: [
         { type: FETCH_USER_REQUEST },
         {
             type: FETCH_USER_SUCCESS,
             payload: (action, state, res) =>
                 handleResponse(userSchema, action, state, res),
         },
         { type: FETCH_USER_FAILURE, payload: handleError },
     ],
 });


 export const getallUser = (params) =>
 createAction({
     endpoint: `${domainUrl}/users?${handleQuery(params)}`,
     method: "GET",
     headers: {
         client_code: "ht_front",
         token: sessionStorage.getItem("token"),
         "Content-Type": "application/json",
       },
     
     types: [
         { type: FETCH_USER_REQUEST },
         {
             type: FETCH_USER_SUCCESS,
             payload: (action, state, res) =>
             handleReducerResponse(action, state, res),
         },
         { type: FETCH_USER_FAILURE, payload: handleError },
     ],
 });

 /**
 * Returns a redux api middleware action that makes a request to update user details.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
  export const updateUser = (userId, data) =>
  createAction({
      endpoint: `${domainUrl}/users/${userId}`,
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
          client_code: "ht_front",
          token: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      types: [
          { type: UPDATE_USER_REQUEST },
          {
              type: UPDATE_USER_SUCCESS,
              payload: (action, state, res) =>
                  handleResponse(userSchema, action, state, res),
          },
          { type: UPDATE_USER_FAILURE, payload: handleError },
      ],
  });

/**
 * Returns a redux api middleware action that makes a request to change password.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
    export const changePasword = (data) =>
    createAction({
        endpoint: apiUrls.changePassword,
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            client_code: "ht_front",
            token: sessionStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        types: [
            { type: CHANGE_PWD_REQUEST },
            {
                type: CHANGE_PWD_SUCCESS,
                payload: (action, state, res) =>
                    handleReducerResponse(action, state, res),
            },
            { type: CHANGE_PWD_FAILURE, payload: handleError },
        ],
    });

    /**
 * Returns a redux api middleware action that makes a request to update user details.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
     export const resetPassword = (data) =>
     createAction({
         endpoint: apiUrls.forgotPassword,
         method: "POST",
         body: JSON.stringify(data),
         headers: {
             client_code: "ht_front",
             "Content-Type": "application/json",
           },
         types: [
             { type: RESET_PWD_REQUEST },
             {
                 type: RESET_PWD_SUCCESS,
                 payload: (action, state, res) =>
                    handleReducerResponse(action, state, res),
             },
             { type: RESET_PWD_FAILURE, payload: handleError },
         ],
     });
     export const addUser = (data) =>
     createAction({
       endpoint: `${domainUrl}/users/`,
       method: "POST",
       body: JSON.stringify(data),
       headers: {
         client_code: "ht_front",
         token: sessionStorage.getItem("token"),
         "Content-Type": "application/json",
       },
       types: [
         { type: UPDATE_USER_REQUEST },
         {
           type: UPDATE_USER_SUCCESS,
           payload: (action, state, res) =>
             handleResponse(userSchema, action, state, res),
         },
         { type: UPDATE_USER_FAILURE, payload: handleError },
       ],
     });

     export const addCompany = (data,Id) =>
     createAction({
       endpoint: `${domainUrl}/masterservicenew/master/organization/${Id}`,
       method: "PUT",
       body: JSON.stringify(data),
       headers: {
         client_code: "ht_front",
         token: sessionStorage.getItem("token"),
         "Content-Type": "application/json",
       },
       types: [
         { type: ADD_COMPANY_REQUEST },
         {
           type: ADD_COMPANY_SUCCESS,
           payload: (action, state, res) =>
           handleReducerResponse(action, state, res),
         },
         { type:ADD_COMPANY_FAILURE, payload: handleError },
       ],
     });