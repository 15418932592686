import { schema } from "normalizr";
import { createAction } from "redux-api-middleware";
import {
	handleResponse,
	handleError,
} from "../utils";
import { domainUrl } from "../helper";

export const FETCH_COUNTRY_REQUEST = "FETCH_COUNTRY_REQUEST";
export const FETCH_COUNTRY_SUCCESS = "FETCH_COUNTRY_SUCCESS";
export const FETCH_COUNTRY_FAILURE = "FETCH_COUNTRY_FAILURE";

export const FETCH_CITY_REQUEST = "FETCH_CITY_REQUEST";
export const FETCH_CITY_SUCCESS = "FETCH_CITY_SUCCESS";
export const FETCH_CITY_FAILURE = "FETCH_CITY_FAILURE";

export const countrySchema = new schema.Entity("countries", [], {
	idAttribute: "country_id",
});

export const citySchema = new schema.Entity("cities", [], {
	idAttribute: "city_id",
});

/**
 * Returns a redux api middleware action that makes a request for a collection of countries.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const fetchCountries = () =>
	createAction({
		endpoint: `${domainUrl}/masterservicenew/master/country?limit=100&offset=0&order_by=created_at&sort_by=ASC`,
		method: "GET",
		headers: {
			client_code: "ht_front",
			"Content-Type": "application/json",
		},
		types: [
			{ type: FETCH_COUNTRY_REQUEST },
			{
				type: FETCH_COUNTRY_SUCCESS,
				payload: (action, state, res) =>
					handleResponse([countrySchema], action, state, res),
			},
			{ type: FETCH_COUNTRY_FAILURE, payload: handleError },
		],
	});

/**
 * Returns a redux api middleware action that makes a request for a collection of cities with country id.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const fetchCities = (countryId) =>
 createAction({
	 endpoint: `${domainUrl}/masterservicenew/master/city?limit=100&country_id=${countryId}`,
	 method: "GET",
	 headers: {
		 client_code: "ht_front",
		 "Content-Type": "application/json",
	 },
	 types: [
		 { type: FETCH_CITY_REQUEST },
		 {
			 type: FETCH_CITY_SUCCESS,
			 payload: (action, state, res) =>
				 handleResponse([citySchema], action, state, res),
		 },
		 { type: FETCH_CITY_FAILURE, payload: handleError },
	 ],
 });