import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

const SettingNavigationLabel = (props) => {
  const history = useHistory();

  const goToUrl = (url) => {
    history.push(url);
  };

  return (
    <li
      className={`${props.active ? "active" : ""} list-setting-navigation-label`}
      onClick={() => goToUrl(props.url)}
    >
      {props.label}
    </li>
  );
};

SettingNavigationLabel.propTypes = {
  label: PropTypes.string,
  active: PropTypes.bool,
};

export default React.memo(SettingNavigationLabel);
