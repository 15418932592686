import { schema } from "normalizr";
import { CUSTOM_AUTHORIZED_REQUEST } from '../middleware/apiAuthorization';
import {
	handleResponse,
	handleError,
} from "../utils";
import apiUrls  from "../utils/apiUrls";

export const FETCH_BIDS_REQUEST = "FETCH_BIDS_REQUEST";
export const FETCH_BIDS_SUCCESS = "FETCH_BIDS_SUCCESS";
export const FETCH_BIDS_FAILURE = "FETCH_BIDS_FAILURE";

export const bidSchema = new schema.Entity("tenderBids", [], {
	idAttribute: "bid_id",
});

/**
 * Returns a redux api middleware action that makes a request to send emails.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const getBidForTender = (params, tender_id) => {
	return {
		[CUSTOM_AUTHORIZED_REQUEST]: {
			endpoint: apiUrls.tenderBidsRequest(params, tender_id),
			method: "GET",
			types: [
				{ type: FETCH_BIDS_REQUEST },
				{
					type: FETCH_BIDS_SUCCESS,
					payload: (action, state, res) =>
                    handleResponse([bidSchema], action, state, res),
				},
				{ type: FETCH_BIDS_FAILURE, payload: handleError },
			],
		}
	}
}
