/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { 
  Form, 
  Input, 
  Button, 
  Spin, 
  message, 
  // Divider, 
  // Row, 
  // Col, 
  // Upload, 
  // Avatar, 
  // Pagination, 
  Modal, 
  Space, 
  Table 
} from 'antd';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
// import get from 'lodash/get';
import { FormattedMessage, useIntl } from "react-intl";
import { 
  // LoadingOutlined, 
  // PlusOutlined,
  SearchOutlined 
} from '@ant-design/icons';
import { 
  // getUser, 
  // updateUser, 
  // changePasword, 
  getallUser,
  addUser
} from '../../actions/auth';
// import { fetchOrganization } from "../../actions/organization";
// import { fetchSupplier } from "../../actions/suppliers";
// import { fetchFiles, fetchDocument } from "../../actions/documents";
// import moment from "moment";
import Header from "../../components/Header";
import { domainUrl } from "../../helper";
import Highlighter from "react-highlight-words";

// function getBase64(img, callback) {
//   const reader = new FileReader();
//   reader.addEventListener('load', () => callback(reader.result));
//   reader.readAsDataURL(img);
// }


// function beforeUpload(file) {
//   const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
//   if (!isJpgOrPng) {
//     message.error('You can only upload JPG/PNG file!');
//   }
//   const isLt2M = file.size / 1024 / 1024 < 2;
//   if (!isLt2M) {
//     message.error('Image must smaller than 2MB!');
//   }
//   return isJpgOrPng && isLt2M;
// }

export const genHeaders = () => ({
  headers: {
    client_code: "ht_front",
    token: sessionStorage.getItem("token"),
  },
});

const PropertyUserDetails = (props) => {
  const [errorMessage, setErrorMessage] = useState();

  const roleId = sessionStorage.getItem('role_id');
  const dispatch = useDispatch();
  const intl = useIntl();
  const[Users,setUsers]=useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const userId = sessionStorage.getItem('user_id');
  // const user = useSelector(state => get(state, `entities.users.${userId}`));
  const entity_id = sessionStorage.getItem('entity_id');
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  // console.log("organisation",organisation)
  useEffect(() => {
    getuserdetails(1, pageSize)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    const getInfo = async () => {
      try {
        let companies = await axios.get(
          domainUrl + `/masterservicenew/master/company?organization_id=${entity_id}&limit=1000`,
          genHeaders()
        );
        companies = companies.data.data.result;
        if (companies?.length) {
          // setCompanies(companies);
          setFilteredCompanies(companies);
        }
      } catch (e) {
        console.error(e);
        message.error(e?.data?.message ?? "Unable to fetch data from server");
      }
    };
  
    getInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCompanySelect = ({ target }) => {
    setErrorMessage('')
    const id = +target.value;
    setSelectedCompanies((c) => {
      const isPresent = c.indexOf(id) > -1;
      if (!isPresent) {
        const newCompanies = selectedCompanies.slice();
        newCompanies.push(id);
        return newCompanies;
      } else if (isPresent) {
        return c.filter((el) => el !== id);
      }
    });
  };

  const onPaginationChange = (pageDetails) => {
    setPageSize(pageDetails.pageSize);
    getuserdetails(pageDetails.current, pageDetails.pageSize);
  }

  const getuserdetails = async(page, pageSize) => {
    // setConfirmLoading(true);
    const response = await dispatch(getallUser({limit: pageSize, offset: pageSize*(page-1), order_by: 'created_at', sort_by: 'DESC',created_by:userId }));
    const count = response?.payload?.data?.meta?.count;
    const result = response?.payload?.data?.result;
    
    const filterUserResponse=await result.filter((user)=>user?.entities?.[0]?.entity_id==entity_id
      
    )
    filterUserResponse.map(obj => {
      obj['company'] = (obj['entities'].filter((item) => item.Company != null));
      obj['company'] = (obj['company'].map((item) => {return item.Company.company_name;})).join(',');
    })

    // setConfirmLoading(false);
    setTotalCount(count);
    setUsers(filterUserResponse);
  }

  const onAddUser = async (values) => {
    if(!selectedCompanies || selectedCompanies.length === 0) {
      setErrorMessage(
        intl.formatMessage({id: "Please select atleast one company."})
      );
      return;
    }
    setIsLoading(true);
    delete values.confirm;
    values["type"] = "O";
    values["created_by"]=parseInt(userId)
    values["is_verified"]=1
    values["role_id"] =parseInt(roleId)
    values["entities"] = [
      {
        entity_id:parseInt(entity_id),
        entity_type:"organization",
      },
    ];

    selectedCompanies.map(
      comp =>{
        values["entities"].push(
          {
            entity_id:parseInt(comp),
            entity_type:"company",
          }
        )
      }
    )
    const response = await dispatch(addUser(values));
    // console.log("response",response);
    if(response?.error===true){
      message.error(response?.payload?.body?.error?.message)
    } else {
      message.success(intl.formatMessage({ id: "User_success" }));
      setIsLoading(false);
      getuserdetails(1, pageSize)
      form.resetFields()
      setVisible(false)
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = clearFilters => {
    clearFilters();
    setState({ searchText: '' });
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={intl.formatMessage({ id:"search"})+` `+intl.formatMessage({ id:dataIndex})}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            <FormattedMessage id="search" />
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            <FormattedMessage id="reset" />
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            <FormattedMessage id="filter" />
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const [state, setState] =  useState({
    searchText: '',
    searchedColumn: '',
  });

  const columns = [
    {
    title: intl.formatMessage({ id:'label_name'}),
    dataIndex: 'name',
    key: 'name', //api response
    sorter: (a, b) => a.name?.length - b.name?.length,
    defaultSortOrder: 'descend',
    ...getColumnSearchProps('name'),
    width: 50,
    },
    {
    title: intl.formatMessage({ id:'email'}),
    dataIndex: 'email',
    key: 'email',
    width: 45,
    },
    {
    title:intl.formatMessage({ id:'contact'}),
      dataIndex: 'contact',
    key: 'button',
    
    ellipsis: true,
    width: 45,
    },
    {
      title: intl.formatMessage({ id:'company'}),
      
      dataIndex: 'company',
      key: 'company',
      width: 110,
    },
  //    {
  //     title: intl.formatMessage({ id:'organisation'}),
  //     render: function render(val) {return (
  //       <>
  //         {val?.entities?.[0]?.Organization?.organization_name}
  //       </>
  // //     

      
  //   )},
  //     key: 'editicon',
  //     width: 45,
  //    },
  ];

  const propertyUserdetails=()=>{
    return(
      <>
      <Spin spinning={isLoading} >
      <h1 className="profile">{<FormattedMessage id={'propertymanagerdetails'} />}</h1>

      <Button
                    type="primary"
                    style={{ marginLeft: "5px" }}
                    className="btn"
                    onClick={() => setVisible(true)}
                  >
                    {/* <FormattedMessage id={'edit'} /> */}
                    <FormattedMessage id="add"/>
                  </Button>
      

      
      <h3>
        {/* <FormattedMessage id={'propertymanagerdetails'} /> */}
      </h3>
      <Table
      pagination={{ defaultPageSize:pageSize, showSizeChanger: false, total: totalCount }}
    //  current:currentPage,
      columns={columns}
    //  scroll={{ y: 240 }} 
      onChange={onPaginationChange}
      dataSource={Users}
      locale={{emptyText: intl.formatMessage({ id:'no_data'})}}
    />
      {/* <div className="dataBlock card msgCard">
      
            <ul className="dataTitle list2">
              <li className="w-5"><FormattedMessage id="user" /></li>
              <li><FormattedMessage id="email"/></li>
              <li><FormattedMessage id="contact"/></li>
              <li><FormattedMessage id="company"/></li>
              <li><FormattedMessage id="organisation"/></li>
            </ul>
            {
                Users?.map((user,index) => (
                    <div key={index} className="list-items">
                          <div className="profilePic">
                            <Avatar className="msgAvatar">{user?.name.substring(0,1)}</Avatar>
                        </div>
                        <ul className={'dataContent list2 bold'}>
                        
                            <li className="d-flex w-3">
                            <span>{user?.name}</span>
                            </li>
                            <li>
                            <span>{user?.email}</span>
                            </li>
                            <li>
                            <span>{user?.contact}</span>
                            </li>
                            <li>
                            <span>{user?.company}</span>
                            </li>
                              <li>
                            <span>{user?.entities?.[0]?.Organization?.organization_name}</span>
                            </li>
                          
                        </ul>
                        </div>
                ))
            }
            
          
            <Pagination
                className="msgsPagination"
                size="small"
                showSizeChanger
                onChange={onPaginationChange}
                defaultCurrent={1}
                total={totalCount}
                showTotal={(total, range) => `${range[0]}-${range[1]} ${intl.formatMessage({ id: 'of' })} ${total} ${intl.formatMessage({ id: 'items' })}  `}
                />
        </div>
      */}
    </Spin>
    <Modal
          footer={null}
          open={visible}
          title={intl.formatMessage({id:"AddUser"})}
          // onOk={this.handleOk}
          onCancel={() => {
            setVisible(false);
            form.resetFields();
          }}
        >
          <Form
            form={form}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            onFinish={onAddUser}
          >
            <Form.Item
              label={<FormattedMessage id={"label_name"} />}
              name="name"
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id={"error.name"} />,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<FormattedMessage id={"email"} />}
              name="email"
              autoComplete="new-email"
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id={"error.email"} />,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={<FormattedMessage id={"mobile_number"} />}
              name="contact"
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id={"error.mobile_number"} />,
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              label={<FormattedMessage id={"password"} />}
              name="password"
              autoComplete="new-password"
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id={"error.password"} />,
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label={<FormattedMessage id={"cnfrmPassword"} />}
              name="confirm"
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id={"error.password"} />,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(intl.formatMessage({ id: "match_pwd" }))
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <div className="cardBlock">
              <table className="tableGrid" width="100%" border="0">
                <tbody>
                  <tr>
                    <th align="left" valign="top">
                    <FormattedMessage id="choose_company"/>
                    </th>
                    <th align="left" valign="top">
                    <FormattedMessage id="select"/>
                    </th>
                  </tr>
                  {filteredCompanies?.map((el, index) => (
                    <tr key={`${el.created_at}${el.business_id}${index}`}>
                      <td align="left" valign="top">
                        {el.company_name}
                      </td>
                      <td align="left" valign="top">
                        <div className="radioBtn">
                          <input
                            type="checkbox"
                            id={`${el.business_id}${el.company_name}${index}`}
                            value={el.company_id}
                            checked={
                              selectedCompanies?.includes(+el.company_id)
                                ? "checked"
                                : null
                            }
                            onChange={onCompanySelect}
                          />
                          <label
                            htmlFor={`${el.business_id}${el.company_name}${index}`}
                          ></label>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* <div className="btn housingbtn" onClick={onSelectAll}>
                {!allSelected ? 'Select All' : 'Unselect All'}
              </div> */}
                {errorMessage && (
            <p style={{ color: "red", fontWeight: "bold", textAlign: "center" }}>{errorMessage}</p>
        )}
            </div>
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >

              <Button type="primary" htmlType="submit" className="btn">
                {/* <FormattedMessage id={"change_pwd"} /> */}
                <FormattedMessage id="submit"/>
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  }
  
  return (
    <>
      {(props.tabview)? propertyUserdetails() :
        <>
          <Header />
          <div className="mainContainer profileContainer">
              <div className="innerContainer">
                <div className="profile-wrapper">
                  {propertyUserdetails()}
                </div>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default PropertyUserDetails;