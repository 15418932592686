/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Select, message, Spin } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import "../AdditionalInfo/AdditionalInfo.css";
import "./EnterTenderDetails.less";
import { useTender } from "../TenderCreationSteps";

import TextEditor from '../../TextEditor';
// import { updatePreTender } from "../../../actions/pre-tender";
import { useDispatch } from "react-redux";
import ToolTip from '../../ToolTip';
import axios from "axios";
import { domainUrl } from "../../../helper";
// import { segKeyData } from "../../../utils";
export const genHeaders = () => ({
  headers: {
    client_code: "ht_front",
    token: sessionStorage.getItem("token"),
  },
});
const EnterTenderDetails = ({updateTenderData}) => {
  // const dispatch = useDispatch();
  const intl = useIntl();
  const { tenderDetails, onStepChange: move, modifyData, tenderModifiedDetails } = useTender();
  const [tenderNames, setTenderNames] = useState([]);
  const [title, setTitle] = useState(tenderModifiedDetails?.title || tenderDetails?.title || "");
  const [tenderTitle, setTenderTitle] = useState((tenderModifiedDetails?.title || tenderDetails?.title != null) ? [{label:tenderModifiedDetails?.title || tenderDetails?.title}] : []);
  const [description, setDescription] = useState(
    tenderModifiedDetails?.description || tenderDetails?.description || ""
  );

  const [loading, setLoading] = useState(false);
  // const [visibility, setVisibility] = useState(
  //   tenderDetails?.visibility ?? false
  // );
  const [error, setError] = useState(false);

  const onBack = () => {
    move(-1, { title, description });
  };

  const getTenders = async(value) => {
    let tenders = await axios.get(
      domainUrl + `/tender/v1/tender?limit=100&sort_by=DESC&title=${value}`,
      genHeaders()
    );
    tenders = tenders.data?.data?.result;
    return tenders;
  }

  const segKeyData = (key,valueKey, data) => {
    let keyArray = data.map(obj => { return {label: obj[key], value: String(obj[valueKey])}});
    
    return keyArray.filter((value, index, self) => self.map(x => x.value).indexOf(value.value) == index);
  }

  const handleSearch = async (value) => {
    if(value) {
      const response = await getTenders(value);
      const tenderData = Object.values(response || []);
      let tenderData1 = [...new Map(tenderData.map(item => [item['tender_id'], item])).values()];
      setTenderNames(segKeyData('title','tender_id',tenderData1));
    }
    else {
      setTenderNames([]);
    }
  }

  const onNext = async () => {
    if (!title?.trim()) {
      setError(true);
      return;
    }

    setLoading(true); // Added to Handle Next Click Disable

    try {
      if(tenderDetails.is_published !== 1) {
        const status = await updateTenderData({
          state: '3',
          title,
          description,
        });
        if (status) {
          move(1, { title, description });
        }
        else {
          message.error("Unable to reach server.");
        }
      }
      else {
        move(6, { title, description }, true);
      }
    } catch (e) {
      message.error(e?.message ?? "An error occurred. Please try again.");
    } finally {
      setLoading(false); // Reset loading state after API call completes
    }

    
  };

  const saveDescription = (data) => {
    setDescription(data);
  }

  const publishData = () => {
    if (!title?.trim()) {
      setError(true);
      return;
    }
    move(0, {
      title, description,
    },true);
    modifyData();
  }
  return (
    <div className="__TenderCreate_TenderDetails__">
      <div className="hedding">
        <h2><FormattedMessage id="enter_tender_details" /> <ToolTip text_id="tender.tender_details.info" placement="top" /></h2>
      </div>
      <div className="formGroup">
        <label className="leftCol"><FormattedMessage id="title" /></label>
        {/* <input
          value={title}
          onChange={({ target }) => {
            setError(false);
            setTitle(target.value);
          }}
          type="text"
          className="formFiled"
        /> */}
        <Select
          showSearch
          mode="tags"
          style={{ width: 400 }}
          className="preTender_asset"
          placeholder={intl.formatMessage({id: 'title'})}
          showArrow={false}
          onSearch={handleSearch}
          notFoundContent={null}
          optionFilterProp="label"
          // name='title'
          options={tenderNames}
          value={tenderTitle}
          onChange={(value,option) => {
            if (value?.length > 1) {
              // if you want only one element :).
              value.pop();
            }
            else {
              setError(false);
              setTitle(option[0]?.label ? option[0]?.label : value[0]);
              setTenderTitle(option[0]?.label ? option[0] : value[0])
            }
            
          }}
        >
        </Select>
      </div>
      {/* <Form.Item
        label={intl.formatMessage({id: 'title'})}
        labelAlign={'left'} 
        labelCol={{span: 10}}
        wrapperCol={{span: 15}}
        colon={false}
        // name={}
        // fieldKey={}
      >
        <Select
          showSearch
          mode="tags"
          className="preTender_asset"
          placeholder={intl.formatMessage({id: 'title'})}
          showArrow={false}
          onSearch={handleSearch}
          notFoundContent={null}
          optionFilterProp="label"
          name='title'
          options={tenderNames}
          value={title}
          onChange={(value,option) => {
            if (value?.length > 1) {
              // if you want only one element :).
              value.pop();
            }
            else {
              setError(false);
              setTitle(option[0]?.label ? option[0] : value[0]);
            }
            
          }}
        >
        </Select>
      </Form.Item> */}
      <div className="formGroup">
        <label className="leftCol"><FormattedMessage id="desc" /></label>
        <TextEditor textData={description} onChangeData={saveDescription} />

      </div>
      {/* <div className="formGroup">
        <label className="leftCol"></label>
        <div className="visibility">
          <input
            type="checkbox"
            id="visibility"
            checked={visibility}
            onChange={(e) => setVisibility(e.target.checked)}
            title="Only visible to me"
          />
          <label htmlFor="visibility">Only visible to me</label>
        </div>
      </div> */}
      {error && (
        <p style={{ color: "red", fontWeight: "bold", textAlign: "center" }}>
          <FormattedMessage id="error.tender_title" />
          
        </p>
      )}
      <div className="buttonBlock">
        <div onClick={onBack} className="btn cancelBtn">
          <FormattedMessage id='bakc' /> </div>
        {
          tenderDetails?.is_published === 1 && 
          <div className="btn" onClick={publishData}>
            <FormattedMessage id="publish" />
          </div>
        }
          <div onClick={!loading ? onNext : null} className={`btn ${loading ? 'disabled' : ''}`} disabled={loading} >
          {loading ? <Spin style={{ marginRight: '10px' }} /> : <FormattedMessage id="next" />}
          </div>
      </div>
    </div>
  );
};




// Tender.propTypes = {};

export default EnterTenderDetails;
