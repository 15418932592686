import { schema } from "normalizr";
import { createAction } from "redux-api-middleware";
import { handleResponse, handleError, handleQuery, handleResponseNew } from "../utils";
import { domainUrl } from "../helper";

export const FETCH_COMPANY_REQUEST = "FETCH_COMPANY_REQUEST";
export const FETCH_COMPANY_SUCCESS = "FETCH_COMPANY_SUCCESS";
export const FETCH_COMPANY_FAILURE = "FETCH_COMPANY_FAILURE";

export const FETCH_COMPANIES_REQUEST = "FETCH_COMPANIES_REQUEST";
export const FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS";
export const FETCH_COMPANIES_FAILURE = "FETCH_COMPANIES_FAILURE";

export const FETCH_COMPANYTYPES_REQUEST = "FETCH_COMPANYTYPES_REQUEST";
export const FETCH_COMPANYTYPES_SUCCESS = "FETCH_COMPANYTYPES_SUCCESS";
export const FETCH_COMPANYTYPES_FAILURE = "FETCH_COMPANYTYPES_FAILURE";

export const companySchema = new schema.Entity("companies", [], {
  idAttribute: "company_id",
});

export const companyTypeSchema = new schema.Entity("companyTypes", [], {
  idAttribute: "company_type_id",
});

/**
 * Returns a redux api middleware action that makes a request for a Company.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
 export const fetchCompany = (companyId) =>
 createAction({
   endpoint: `${domainUrl}/masterservicenew/master/company/${companyId}`,
   method: "GET",
   headers: {
     client_code: "ht_front",
     token: sessionStorage.getItem("token"),
     "Content-Type": "application/json",
   },
   types: [
     { type: FETCH_COMPANY_REQUEST },
     {
       type: FETCH_COMPANY_SUCCESS,
       payload: (action, state, res) =>
         handleResponse(companySchema, action, state, res),
     },
     { type: FETCH_COMPANY_FAILURE, payload: handleError },
   ],
 });

/**
 * Returns a redux api middleware action that makes a request for a collection of Companies.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const fetchCompanies = (params = {}, check) =>
  createAction({
    endpoint: `${domainUrl}/masterservicenew/master/company?${handleQuery(params)}`,
    method: "GET",
    headers: {
      client_code: "ht_front",
      token: sessionStorage.getItem("token"),
      "Content-Type": "application/json",
    },
    types: [
      { type: FETCH_COMPANIES_REQUEST },
      {
        type: FETCH_COMPANIES_SUCCESS,
        payload: (action, state, res) =>
        check ? handleResponseNew([companySchema], action, state, res) : handleResponse([companySchema], action, state, res),
      },
      { type: FETCH_COMPANIES_FAILURE, payload: handleError },
    ],
  });

  /**
 * Returns a redux api middleware action that makes a request for Company Types.
 *
 * @return {object} - Redux api middleware action that triggers & handles the request.
 */
export const fetchCompanyTypes = (params = {}) =>
createAction({
  endpoint: `${domainUrl}/masterservicenew/master/company_type`,
  method: "GET",
  headers: {
    client_code: "ht_front",
    token: sessionStorage.getItem("token"),
    "Content-Type": "application/json",
  },
  types: [
    { type: FETCH_COMPANYTYPES_REQUEST },
    {
      type: FETCH_COMPANYTYPES_SUCCESS,
      payload: (action, state, res) =>
        handleResponse([companyTypeSchema], action, state, res),
    },
    { type: FETCH_COMPANYTYPES_FAILURE, payload: handleError },
  ],
});