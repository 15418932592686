import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MUIDataTable from "mui-datatables";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Button from "@material-ui/core/Button";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

import { fetchOrganizations } from "../../../actions/organization";
import { FormattedMessage, useIntl } from "react-intl";

const myTheme = createTheme({
  overrides: {
    MUIDataTable: {
      responsiveBase: {
        maxHeight: "50vh",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  closeBtn: {
    float: "right",
    cursor: "pointer",
  },
  helpText: {
    width: "80%",
  },
  btn: {
    padding: "4px 10px 3px 10px",
    borderRadius: 36,
    backgroundColor: "#f99600",
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 600,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff",
    width: "auto",
    display: "inline-block",
    cursor: "pointer",
  },
}));

const AssetCompanySelect = ({
  companyList,
  onCompanySelect,
  isOpen,
  onClose,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [organization, setOrganization] = useState([]);
  // const [orgCompany, setOrgCompany] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    async function getOrgData() {
      const response = await dispatch(fetchOrganizations({limit: 1000}));
      const orgData = Object.values(response.payload.entities.organizations);
      mapCompanyToOrg(orgData);
    }
    getOrgData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const mapCompanyToOrg = (orgData) => {
    // eslint-disable-next-line array-callback-return
    orgData.map((org) => {
      let companies = companyList.filter(
        (company) => company.organization_id === org.organization_id
      );
      if (companies.length > 0) org.companies = companies;

      org.selectedCompanyId =
        companies.length > 0 ? companies[0].company_id : "";
    });
    setOrganization(orgData);
  };

  const getSelectedCompany = (companyValues) => {
    let orgData = organization.find(
      (org) => org.organization_id === companyValues[0].organization_id
    );
    return orgData.selectedCompanyId;
  };
  const handleChange = (event) => {
    let companies = companyList.find(
      (company) => company.company_id === event.target.value
    );
    let orgData = organization;
    // eslint-disable-next-line array-callback-return
    orgData.map((org) => {
      if (org.organization_id === companies.organization_id) {
        org.selectedCompanyId = event.target.value;
      }
    });

    setOrganization([]);
    setTimeout(() => {
      setOrganization(orgData);
    }, 0);
  };

  const columns = [
    {
      name: "organization_name",
      label: <FormattedMessage id="organization"/>,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "companies",
      label: <FormattedMessage id="company"/>,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            {value ? (
              <FormControl>
                <Select
                  value={getSelectedCompany(value)}
                  onChange={handleChange}
                >
                  {value.map((comp) => (
                    <MenuItem value={comp.company_id}>
                      {comp.company_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <p className={classes.helpText}>
                <FormattedMessage id="add_company_assets"/>
              </p>
            )}
          </>
        ),
      },
    },
    {
      name: "selectedCompanyId",
      label: <FormattedMessage id="business_id"/>,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div className="">
            {value !== "" &&
              companyList.find((company) => company.company_id === value)
                .business_id}
          </div>
        ),
      },
    },
    {
      name: "selectedCompanyId",
      label: <FormattedMessage id="Action"/>,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <Button
            variant="contained"
            classes={{
              contained: classes.btn,
            }}
            disabled={value === ""}
            onClick={() => goToAssets(value)}
          >
           <FormattedMessage id="add_asset"/>
          </Button>
        ),
      },
    },
  ];

  const options = {
    filterType: "dropdown",
    selectableRows: "none",
    fixedHeader: true,
    download: false,
    print: false,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: intl.formatMessage({ id:'sorry_no_matching_records_found'}),
        toolTip: intl.formatMessage({ id:'sort'}),
        columnHeaderTooltip: column => `${intl.formatMessage({ id:'sort_for'})} ${column.label}`
      },
      pagination: {
        next: intl.formatMessage({ id:'next_page'}),
        previous: intl.formatMessage({ id:'previous_page'}),
        rowsPerPage: intl.formatMessage({ id:'rows_per_page'}),
        displayRows: intl.formatMessage({ id:'of2'}),
      },
      toolbar: {
        search: intl.formatMessage({ id:'search'}),
        downloadCsv: intl.formatMessage({ id:'download_csv'}),
        print: intl.formatMessage({ id:'print'}),
        viewColumns: intl.formatMessage({ id:'view_columns'}),
        filterTable: intl.formatMessage({ id:'filter_table'}),
      },
      filter: {
        all: intl.formatMessage({ id:'all'}),
        title: intl.formatMessage({ id:'filters'}),
        reset: intl.formatMessage({ id:'reset'}),
      },
      viewColumns: {
        title: intl.formatMessage({ id:'show_columns'}),
        titleAria: intl.formatMessage({ id:'show_hide_table_columns'}),
      },
      selectedRows: {
        text: intl.formatMessage({ id:'rows_selected'}),
        delete: intl.formatMessage({ id:'delete'}),
        deleteAria: intl.formatMessage({ id:'delete_selected_rows'}),
      },
    }
  };

  const goToAssets = (company_id) => {
    let selectedCompany = companyList.find(
      (comp) => comp.company_id === company_id
    );
    onCompanySelect({
      company_id: company_id,
      company_name: selectedCompany.company_name,
    });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle>
      <FormattedMessage id="selected_company"/>
        <HighlightOffIcon className={classes.closeBtn} onClick={onClose} />
      </DialogTitle>
      <DialogContent dividers={true}>
        <MuiThemeProvider theme={myTheme}>
          <MUIDataTable
            title={""}
            data={organization}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </DialogContent>
    </Dialog>
  );
};

AssetCompanySelect.propTypes = {
  companyList: PropTypes.array,
  onCompanySelect: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AssetCompanySelect;
