import React, { useState } from "react";
import PropTypes from "prop-types";
// import { useHistory } from 'react-router-dom';
import {  
	Radio, 
	Form, 
	// Input, 
	Row, 
	Col, 
	Select 
} from 'antd';
import { FormattedMessage } from "react-intl";
import _ from "lodash";

const InspectionLine = ({ inspectionLineItem, lineData, isReadOnly, handleRemarksSearch, remarksOptions}) => {
	// console.log(inspectionLineItem,"=======inspectionLineItem")
	// console.log(lineData.inspection_item_name,"=======lineData.inspection_item_name")
	const [idChekedFromRequest,setIdChekedFromRequest] = useState([]);
	return (
		<>
			<Row className="line_row" key={lineData.inspection_item_id} style={{marginBottom: '-25px'}}>
				<Col  md={8} lg={8} xl={8}>
					<span className="display_name"><FormattedMessage id={lineData.inspection_item_name}/></span>
				</Col>
				<Col md={5} lg={5} xl={5}>
					{
						!isReadOnly ? 
						(
							<Form.Item
								label=""
								name={['lineItems',lineData.inspection_item_id,'inspection_amc_cost']}
							>
								{/* <Radio.Group name={lineData.inspection_item_id+'radioGroup'} initialValues={-1}  disabled={isReadOnly}  style={{width: '90%'}}> */}
									<Row className="d-flex justify-content-between" style={{margin: '0 32px 0 0'}}>
										{lineData.inspection_charges.map(charges => 
											(
												<Col key={charges.inspection_amc_id} style={{paddingLeft: '0px'}}  span={1}>
													<Radio 
														value={[charges.display_name, charges.inspection_amc_cost, charges.inspection_amc_id]} 
														onChange={()=>setIdChekedFromRequest([charges.display_name, charges.inspection_amc_cost, charges.inspection_amc_id])} 
														checked={idChekedFromRequest.length ? _.isEqual([charges.display_name, charges.inspection_amc_cost, charges.inspection_amc_id],idChekedFromRequest) :(inspectionLineItem?.length ? (inspectionLineItem[0]?.display_name?.toLowerCase() ===  charges?.display_name?.toLowerCase()) : (charges?.display_name?.toLowerCase() === 'ok'))}
													></Radio>
													{/* <Radio value={[charges.display_name, charges.inspection_amc_cost, charges.inspection_amc_id]}></Radio> */}
												</Col>
											)
										)}
									</Row>
								{/* </Radio.Group> */}
							</Form.Item>
						)
						:
						(
							<Form.Item label="">
								<Radio.Group name={lineData.inspection_item_id+'radioGroup'} defaultValue={lineData.display_name}  disabled={isReadOnly}  style={{width: '100%',position:'inherit'}}>
									<Row className="d-flex justify-content-between"  style={{margin: '0 32px 0 0'}}>
										<Col span={1}>
										<Radio value={'1'} name={'1'} className="radio-view"></Radio>
										</Col>
										<Col span={1}>
										<Radio value={'2'} name={'2'} className="radio-view"></Radio>
										</Col>
										<Col span={1}>
										<Radio value={'3'} name={'3'} className="radio-view"></Radio>
										</Col>
										<Col span={1}>
										<Radio value={'4'} name={'4'} className="radio-view"></Radio>
										</Col>
										<Col span={1}>
										<Radio value={'5'} name={'5'} className="radio-view"></Radio>
										</Col>
										<Col span={1}>
										<Radio value={'Ok'} name={'Ok'} className="radio-view"></Radio>
										</Col>
									</Row>
								</Radio.Group>
							</Form.Item>
						)
					}
								
								
				</Col>
				{/* <Col span={2} style={{display:'none'}}>
					{
						!isReadOnly ? (
							<Form.Item
						label=""
						name={['lineItems',lineData.inspection_item_id,'inspection_item_value']}>
							<Input />
					</Form.Item>
						) : (
							<Form.Item
								label="">
									<Input value={lineData.inspection_item_value} disabled={isReadOnly}/>
							</Form.Item>
						)
					}
					
				</Col> */}

				<Col  md={11} lg={11} xl={11} style={{textAlign:"left"}}>
					{
						!isReadOnly ? (
							<Form.Item
								label=""
								name={['lineItems',lineData.inspection_item_id,'inspection_remarks']}
								labelCol={{lg:{span: 9, offset: 0}, md:{span: 24, offset: 0}}}
								wrapperCol={{span: 20, offset: 0}}
							>
								{/* <Input.TextArea disabled={isReadOnly}/> */}
								<Select
									showSearch
									mode="tags"
									size="large"
									className="preTender_asset"
									showArrow={false}
									onSearch={(e)=>handleRemarksSearch(e, 'inspection_remarks')}
									notFoundContent={null}
									optionFilterProp="label"
									options={remarksOptions}
									// value={summaryAnswerOne}
									onChange={(value,option) => {
										if (value?.length > 1) {
											// if you want only one element :).
											value.pop();
										}
										// else {
										// 	form.setFieldsValue({ 'summary_answer_one': option[0]?.label ? option[0]?.label : value[0] })
										// 	setSummaryAnswerOne(option[0]?.label ? option[0] : value[0])
										// }
									}}
									// onChange={(e)=>console.log(e,"=====e")}
									placeholder=""
									optionLabelProp="label"
									disabled={isReadOnly} 
								>
								</Select>
								</Form.Item>
						) : (
							// <Form.Item
							// 	label=""
							// >
							// <Input.TextArea value={lineData.inspection_remarks} disabled={isReadOnly}/>
							// </Form.Item>
							<span className="display_remarks">{lineData.inspection_remarks}</span>
						)
					}
				
				</Col>
			</Row>
		</>
	);
};

InspectionLine.propTypes = {
	lineData: PropTypes.shape({}),
	isReadOnly: PropTypes.bool,
};

export default InspectionLine;