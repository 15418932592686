/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from 'lodash/get';
import { Spin, Modal, Form, Input, Button, message, Row, Col, Avatar } from 'antd';
import { FormattedMessage, useIntl } from "react-intl";
import moment from 'moment'; 
import { fetchMessageCommunicationSendbox,
         fetchMessageCommunicationInbox,
         createMessageCommunication, 
         updateMessageCommunication} from "../../actions/messages";
import { awsDocumentUrl, domainUrl } from "../../helper";
import axios from "axios";
import { CheckCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { deleteDocument, fetchDocument } from "../../actions/documents";
import { changeDateFormat } from '../../utils/common';
const DEFAULT_DOCUMENTID = null;
const FILE_POST_URL = `${domainUrl}/tender/v1/document`;
export const genHeaders = () => ({
  headers: {
    client_code: "ht_front",
    token: sessionStorage.getItem("token"),
  },
});
const MessageReplyCard = ({hideModal, modalOpen, tenderId, tenderTitle, receiverId, messageId, markAsReplied, suppliers=[]}) => {
  // console.log(suppliers,"=====supplierssuppliers")
  const dispatch = useDispatch();

  const intl = useIntl();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [prevMsgs, setPrevMsgs] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [fileList, setFileList] = useState();
  const showModal = () => {
    setVisible(true);
  };

  useEffect(() => {
      setVisible(modalOpen);
  }, [modalOpen])

  useEffect(() => {
      if(tenderId)
        getTenderMsgs();
  }, [tenderId]);

  const userId = sessionStorage.getItem('user_id');
  //const user = useSelector(state => get(state, `entities.users.${userId}`));

  const getTenderMsgs = async() => {
      const receivedResponse = await dispatch(fetchMessageCommunicationInbox({limit: 100, order_by: 'created_at', sort_by: 'ASC', entity_id: tenderId }));
      const sendResponse = await dispatch(fetchMessageCommunicationSendbox({limit: 100, order_by: 'created_at', sort_by: 'ASC', entity_id: tenderId }));
      const msgsReceived = receivedResponse?.payload?.data?.result;
      const msgsSent = sendResponse?.payload?.data?.result;
      const allMsgs = msgsReceived.concat(msgsSent ?? []);
      let sortedArray = allMsgs.length > 1 ? await sortMsgs(allMsgs) : allMsgs;
      setPrevMsgs(sortedArray);
  }

  const sortMsgs = async(array) => {
      const sortedArray = array.sort(function(a,b){
        return new Date(a.created_at) - new Date(b.created_at);
      });
      return sortedArray;
  }

  const onFinish = async(values) => {
    // console.log(values,"===values")
    if(values.msg == undefined && !filesToUpload.length){
      message.error(`${intl.formatMessage({ id:'error.msg'})}`);
      return;
    }
    let documents = [];
    if(filesToUpload.length){
      const allDocs = await onFileUploadClick();
      if(allDocs.length){
        for (let index = 0; index < allDocs.length; index++) {
          documents.push({doc_id:allDocs[index]?.documentId, doc_name:allDocs[index]?.documentName})
        }
      }
    }
    setConfirmLoading(true);
    if(suppliers.length > 0){
      for (let index in suppliers) {
        const data = {
          subject: tenderTitle,
          body: values.msg,
          entity_type: "tenders",
          entity_id: +tenderId,
          receiver_id: +suppliers[index].user_id,
          documents: documents,
          meta: {},
        };
        const response = await dispatch(createMessageCommunication(data));
        if(response.error) {
          message.error(intl.formatMessage({ id: 'msg_error' }))
        }
        else {
          message.success(intl.formatMessage({ id: 'msg_send' }));
        }
      }
    }else{
      const data = {
        subject: tenderTitle,
        body: values.msg,
        entity_type: "tenders",
        entity_id: +tenderId,
        receiver_id: +receiverId,
        documents: documents,
        meta: {},
      };
      const response = await dispatch(createMessageCommunication(data));
      if(response.error) {
        message.error(intl.formatMessage({ id: 'msg_error' }))
      }
      else {
        message.success(intl.formatMessage({ id: 'msg_send' }));
      }
    }
    form.resetFields();
    setConfirmLoading(false);
    setVisible(false);
    markAsReplied();
    hideModal();
  };

  const handleCancel = () => {
    setVisible(false);
    hideModal();
  };

  const onFile = (e) => {
    if (e.target.files && e.target.files.length) {
      setFilesToUpload((c) => {
        const newFiles = [
          ...c,
          ...[...e.target.files].map((file) => ({
            documentId: DEFAULT_DOCUMENTID,
            file,
          })),
        ];

        // if (onFilesStatusChange) onFilesStatusChange(newFiles);
        return newFiles;
      });
    }
  };
  const onFileUploadClick = async () => {
    if (filesToUpload && filesToUpload.length) {
      setIsUploading(true);
      // if (onUploadStatusChange) onUploadStatusChange(true);

      for (let index in filesToUpload) {
        if (filesToUpload[index].documentId === DEFAULT_DOCUMENTID) {
          const fd = new FormData();
          const file = filesToUpload[index].file;
          fd.append("entity_type", 'IB');
          fd.append("entity_id", +tenderId);
          fd.append("file_description",  file.name);
          fd.append("file", file);
          try {
            const res = await axios.post(FILE_POST_URL, fd, genHeaders());
            filesToUpload[index].documentId = res?.data?.data?.document_id;
            filesToUpload[index].documentName = file.name;
            setFileList({
              ...fileList,
              ...filesToUpload
            })
            // if(emptyFilelist){
            //   setFilesToUpload([]);
            // };
            message.success(`${file.name} ${intl.formatMessage({ id:'files_uploaded_success'})}`);
          } catch (e) {
            console.error(e);
            message.error(`Unable to upload ${file.name}`);
          }
        }
      }
      // if (onFilesStatusChange) {
      //   setFilesToUpload([]);
      // }
      setIsUploading(false);
      return filesToUpload;
      // if (onUploadStatusChange) onUploadStatusChange(false);
    } else {
      message.error(`${intl.formatMessage({ id:'document_deleted_successfully'})}`);
    }
  };

  const onFileClick = async (e, file) => {
    if(e.target.nodeName.toLowerCase() === 'path' || e.target.nodeName.toLowerCase() === 'svg') {
      e.stopPropagation();
      return;
    }
    const response = await dispatch(fetchDocument(file.doc_id));
    let link =  response.payload?.entities?.files[file.doc_id]?.signed_link || ''
    const win = window.open(link, "_blank");
    win.focus();
  };

  const onFileDeleteBefore = async (index) => {
    try {
      if (filesToUpload[index] && filesToUpload[index].documentId) {
        setIsUploading(true);
        await axios.delete(
          `${awsDocumentUrl}/Prod/v1/document/${filesToUpload[index].documentId}`,
          { token: sessionStorage.getItem("token") }
        );
      }
      setFilesToUpload((c) => {
        const newList = [...c];
        newList.splice(index, 1);
        // if (onFilesStatusChange) onFilesStatusChange(newList);
        return newList;
      });
    } catch (e) {
      message.error("Unable to delete file");
    } finally {
      setIsUploading(false);
      // if (onUploadStatusChange) onUploadStatusChange(false);
    }
  };

  const onFileDelete = async (file, msg) => {
    setIsUploading(true);
    let documents = msg?.documents?.filter((doc)=>{
      if(doc?.doc_id != file.doc_id){
        return doc;
      }
    })
    const response = await dispatch(deleteDocument(file.doc_id));
    const msgResponse = await dispatch(updateMessageCommunication(msg?.message_id, {documents: documents,is_replied: true}));
    if(response.payload.success) {
      message.success(`${intl.formatMessage({ id:'document_deleted_successfully'})}`);
    }
    else {
      message.error(response.payload.message || 'Document not deleted');
    }
    form.resetFields();
    setVisible(false);
    hideModal();
    setIsUploading(false);
  };

  return (
      <Modal
        title={tenderTitle}
        open={visible}
        footer={null}
        onCancel={handleCancel}
        className="msgModal"
      >
        <Spin spinning={isUploading} >
          {
              prevMsgs?.map((msg,index) => (
                  <Col key={index} sm={18} offset={ msg.sender_id !== +userId ? 0 : 6} className="msgBorder">
                    {
                        msg.sender_id !== +userId ? (
                            <>
                              {msg?.documents?.length > 0 && 
                                msg?.documents?.map((doc,key)=>{
                                  return (
                                    <Row className="msgWrapper rcvrMsg" key={key}  style={{marginBottom: "2px"}}>
                                        <Col>
                                          <div className="profilePic" style={{marginLeft: "2px"}}>
                                              <Avatar className="msgAvatar">{msg?.sender_meta?.name.substring(0,1)}</Avatar>
                                          </div>
                                        </Col>
                                        <Col style={{display: "flex", cursor:"pointer", alignItems: "center", border: "1px solid #fff",backgroundColor: "#fff",borderRadius: "10px",padding: "10px 11px",color: "#000000",boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)"}} onClick={(e) => onFileClick(e, doc)}>
                                            <span style={{display: "flex",flexDirection: "column",alignItems: "center",fontSize: "10px"}}>
                                              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIEAAACBCAMAAADQfiliAAAAG1BMVEXh6vH////19/nk7fTx9fj7+/32+vrq8PXe6PGqdtAJAAABCElEQVR4nO3X4Q6CIBRAYSP03vd/4pxKAtIWytVNz/evVeNESdeuAwAAAAAAAID78K8jfIMCd6jAUXCrgqFe44Idb6TgeQWqFxeIG+TSAl9cy7og2neZD5/8T8i6wLmQoMup2Z9aoOPGe01ecXLB9M2HhGWCcNn1YFqwrBkSyl+UYYF+x6bwsX3fb4chuwKN5jaf7nxyKNgVJINjkiDJFWlWkE2uPj0XogSrgs3oHH4LOh9Ma4JNgRZm92UXJN8Um4LizcO0pqwPLQt+3L2MCRI/tCuQwurzOukzcn5BhgIKKHhAQf8fu4I6FNywQOs1LvD1GhfsRQEFrQrexzQoAAAAAAAAAIDZB+kpDhZwx343AAAAAElFTkSuQmCC" width="50" height="50" />
                                              {doc.doc_name}
                                            </span>
                                        </Col>
                                        <DeleteOutlined
                                          onClick={() => (isUploading ? null : onFileDelete(doc, msg))}
                                          style={{
                                            cursor: "pointer",
                                            right: "10px",
                                            top: "30px",
                                            color: "red",
                                            marginLeft: "10px"
                                          }}
                                        />
                                    </Row>
                                  );
                                })
                              }
                              {msg?.body &&
                                <>
                                <Row className="msgWrapper rcvrMsg">
                                    <Col>
                                        <div className="profilePic">
                                            <Avatar className="msgAvatar">{msg?.sender_meta?.name.substring(0,1)}</Avatar>
                                        </div>
                                    </Col>
                                    <Col>
                                        <p>{msg.body}</p>
                                    </Col>
                                </Row>
                                <Row className="timeWrapper rcvrMsg">
                                    <p>{changeDateFormat(msg.created_at,intl?.locale)}{' '}{moment(msg.created_at).format('HH:mm')}</p>
                                </Row>
                                </>
                              }
                            </>
                        ) : (
                            <>
                                {msg?.documents?.length > 0 && 
                                  msg?.documents?.map((doc,key)=>{
                                    return (
                                      <Row className="msgWrapper userMsg" style={{marginBottom: "2px"}}>
                                        <DeleteOutlined
                                          onClick={() => (isUploading ? null : onFileDelete(doc, msg))}
                                          style={{
                                            cursor: "pointer",
                                            right: "10px",
                                            top: "30px",
                                            color: "red",
                                            marginLeft: "10px"
                                          }}
                                        />
                                        <Col style={{display: "flex", margin: "0 10px", cursor:"pointer", alignItems: "center", border: "1px solid #2B7776",backgroundColor: "#2B7776",borderRadius: "10px",padding: "10px 11px",color: "#fff",boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)"}} onClick={(e) => onFileClick(e, doc)}>
                                            <span style={{display: "flex",flexDirection: "column",alignItems: "center",fontSize: "10px"}}>
                                              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIEAAACBCAMAAADQfiliAAAAG1BMVEXh6vH////19/nk7fTx9fj7+/32+vrq8PXe6PGqdtAJAAABCElEQVR4nO3X4Q6CIBRAYSP03vd/4pxKAtIWytVNz/evVeNESdeuAwAAAAAAAID78K8jfIMCd6jAUXCrgqFe44Idb6TgeQWqFxeIG+TSAl9cy7og2neZD5/8T8i6wLmQoMup2Z9aoOPGe01ecXLB9M2HhGWCcNn1YFqwrBkSyl+UYYF+x6bwsX3fb4chuwKN5jaf7nxyKNgVJINjkiDJFWlWkE2uPj0XogSrgs3oHH4LOh9Ma4JNgRZm92UXJN8Um4LizcO0pqwPLQt+3L2MCRI/tCuQwurzOukzcn5BhgIKKHhAQf8fu4I6FNywQOs1LvD1GhfsRQEFrQrexzQoAAAAAAAAAIDZB+kpDhZwx343AAAAAElFTkSuQmCC" width="50" height="50" />
                                              {doc.doc_name}
                                            </span>
                                        </Col>
                                        <Col>
                                            <div className="profilePic">
                                                <Avatar className="msgAvatar">{msg?.sender_meta?.name.substring(0,1)}</Avatar>
                                            </div>
                                        </Col>
                                      </Row>
                                    );
                                  })
                                }
                                {msg?.body &&
                                  <>
                                    <Row className="msgWrapper userMsg">
                                      <Col>
                                          <p>{msg?.body}</p>
                                      </Col>
                                      <Col>
                                          <div className="profilePic">
                                              <Avatar className="msgAvatar">{msg?.sender_meta?.name.substring(0,1)}</Avatar>
                                          </div>
                                      </Col>
                                    </Row>
                                  </>
                                }
                                <Row className="timeWrapper userMsg">
                                    <p>{changeDateFormat(msg.created_at,intl?.locale)}{' '}{moment(msg.created_at).format('HH.mm')}</p>
                                </Row>
                            </>
                        
                        )
                    }
                </Col>
              ))
          }
          <Form
            className="msgForm"
            form={form}
            name="basic"
            layout="vertical"
            onFinish={onFinish}
          >
          <Row>
            <Col sm={19}>
              <Form.Item
                name="msg"
                rules={[
                  {
                    // required: true,
                    message: <FormattedMessage id='error.msg' />,
                  },
                ]}
              >
                <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} showCount maxLength={3000}/>
              </Form.Item>
            </Col>
            <Col sm={4}>
              <div className="manage_form" >
                <div className="formGroup" style={{ flexDirection: "column" }}>
                  <div className="file-drop-area" style={{width: "50%", padding: "0px", border: "0px", cursor: "pointer"}}>
                    <img src="/attachment.svg" />

                    <span className="file-msg"><FormattedMessage id="attachment" /></span>
                    <input
                      onChange={onFile}
                      className="file-input"
                      type="file"
                      style={{width: "100%", height: "40px", cursor: "pointer"}}
                      multiple
                    />
                  </div>
                </div>
                <Button className="btn" type="primary" htmlType="submit">
                  <FormattedMessage id={'send'} />
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={15}>
              {filesToUpload?.map((el, index) => (
                <div key={index} style={{ position: "relative" }}>
                  <div className="filecard" style={{fontSize: "10px"}}>
                    <h3><FormattedMessage id="name" /> : {el.file.name}</h3>
                    <h3><FormattedMessage id="size" /> : {Math.round(el.file.size / 1024)} KB</h3>
                  </div>
                  <DeleteOutlined
                    onClick={() => (isUploading ? null : onFileDeleteBefore(index))}
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "30px",
                      color: "red",
                    }}
                  />
                  {el?.documentId && (
                    <CheckCircleOutlined
                      style={{
                        position: "absolute",
                        right: "40px",
                        top: "30px",
                        color: "green",
                      }}
                    />
                  )}
                </div>
              ))}
            </Col>
          </Row>
          </Form>
        </Spin>
      </Modal>
  )
}

export default MessageReplyCard;