/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { 
  // Button, 
  Input, 
  message, 
  // Space, 
  Table 
} from 'antd';
// import Header from "../Header";
import "./Organization.css";
import axios from "axios";
import AddOrgModal from "./AddModal";
import ConfirmationBox from '../ConfirmationBox';
import { domainUrl } from "../../helper";
import { FormattedMessage, useIntl } from "react-intl";
import {
	fetchOrganizations,
} from "../../actions/organization";
import { 
  // Link, 
  useHistory 
} from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";


const Organization = (props) => {
  const intl = useIntl();
  const [openOrganizationFormModel, setOpenOrganizationFormModel] = useState({
    isOpen: false,
    isReadOnly: false,
  });
  const [organization, setOrganization] = useState();
  const [organizations, setOrganizations] = useState([]);
  const [orgName, setOrgName] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(1);
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  const [deleteOrg, setDeleteOrg] = useState({});
  const dispatch = useDispatch();
  const history=useHistory()

  const getOrgNames = () => {
    let orgs = organizations.map(org => {
      return {
        text: org.organization_name,
        value: org.organization_name
        };
    })
    return orgs;
  }

  const CompanyRoute=(val)=>{
    
    history.push({
      pathname:`/organization/${val.organization_id}`,
      
    })
  }
  useEffect(()=>{
    // if(orgName){
      getAllOrganizations(1, pageSize);
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[orgName]);
  
  useEffect(() => {
    if(props.value === 1)
      getAllOrganizations(1, pageSize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  // const handleSearch = (selectedKeys, confirm, dataIndex) => {
  //   confirm();
  //   setState({
  //     searchText: selectedKeys[0],
  //     searchedColumn: dataIndex,
  //   });
  // };
  
  // const handleReset = clearFilters => {
  //   clearFilters();
  //   setState({ searchText: '' });
  // };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={intl.formatMessage({ id:"search"})+` `+intl.formatMessage({ id:dataIndex})}
          value={selectedKeys[0]}
          onChange={e => setOrgName(e.target.value ? e.target.value : '')}
          onPressEnter={() => setOrgName(selectedKeys.length ? selectedKeys[0] : '')}
          // onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          // onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        {/* <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            <FormattedMessage id="search" />
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            <FormattedMessage id="reset" />
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            <FormattedMessage id="filter" />
          </Button>
        </Space> */}
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const [state, setState] =  useState({
    searchText: '',
    searchedColumn: '',
  });

  const columns = [
    {
      // dataIndex: "organization_name",
      // key: "organization_name",
      title: intl.formatMessage({ id:'organisation'}),
      filters: getOrgNames(),
      ...getColumnSearchProps('organization_name'),
      render:(obj)=>{
      return(
        <span onClick={()=>CompanyRoute(obj)}>{obj.organization_name}</span>
      )
      },
     
      onFilter: (value, record) => record?.organization_name?.indexOf(value) === 0,
      sorter: {
        compare: (a, b) => {
          var nameA = a.organization_name.toUpperCase(); // ignore upper and lowercase
          var nameB = b.organization_name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        },
      },
    },
    {
      dataIndex: "organization_id",
      title: intl.formatMessage({ id:'view'}),
      key: "organization_id",
      render: (value) => {
        return (
          <div className="viewIcon" onClick={() => openOrganizationForm(value, { readOnly: true })}>View</div>
          );
     },
    },
    {
      dataIndex: "organization_id",
      key: "organization_id",
      title:intl.formatMessage({ id:'modify'}),
      render: (value) => {
        return (
          <div
          className="btn-primary"
          onClick={() => openOrganizationForm(value, { readOnly: false })}
        >
           <FormattedMessage id={'modify'} />
        </div>
          );
     },
    },
    {
      dataIndex: "organization_id",
      key: "organization_id",
      title: intl.formatMessage({ id:'delete'}),
      render: (value) => {
        return (
          <div className="deleteIcon" onClick={() => deleteOrganization(value)}>
           <FormattedMessage id={'delete'} />
          </div>
          );
     },
    },
  ];

  const getAllOrganizations = async (page, pageSize) => {
    const params = {limit: pageSize, offset: pageSize*(page-1) };
    if(orgName){
      params.organization_name = orgName;
    }
    const response = await dispatch(fetchOrganizations(params, true));
    const count = response?.payload?.meta?.count;
    setTotalCount(count);
    setOrganizations(Object.values(response.payload.result));
  };

  const getSelectedOrg = (org_id) => {
    return organizations.find((ele) => ele.organization_id === org_id);
  };

  const openOrganizationForm = (org_id, opts) => {
    let selected = getSelectedOrg(org_id);
    setOpenOrganizationFormModel({ isOpen: true, isReadOnly: opts?.readOnly });
    setOrganization(selected);
  };

  const closeOrganizationForm = () => {
    setOpenOrganizationFormModel({ isOpen: false });
    setOrganization();
  };

  const addOrganization = async (data) => {
    const headers = {
      client_code: "ht_front",
      token: sessionStorage.getItem("token"),
      "Content-Type": "application/json",
    };

    if (data?.organization_id) {
      //modify current organization;
      const { organization_id, ...rest } = data;
      rest.company_type_id = +rest.company_type_id;

      const res = await axios.put(
        `${domainUrl}/masterservicenew/master/organization/${organization_id}`,
        { ...rest },
        { headers }
      );

      getAllOrganizations(1, pageSize);
      closeOrganizationForm();
      return;
    }

    //create new organization
    const body = {
      ...data,
      company_type_id: +data.company_type_id,
      country_id: +data.country_id,
      city_id: +data.city_id,
    };
    // console.log(headers);
    const res = await axios.post(
      `${domainUrl}/masterservicenew/master/organization`,
      body,
      { headers }
    )?.then(res => {

    })?.catch(err => {
      // console.log(err?.response?.data);
      if(err?.response?.data?.message === "Bad request: Invalid request User already exists!") {
        message.error(intl.formatMessage({id: 'org_dup_err'}));
      }
    });

    getAllOrganizations(1, pageSize);
    closeOrganizationForm();
  };

  const deleteOrganization = (id) => {
    setDeleteOrg(id);
    setShowConfirmationBox(true);
  };

  const declineSubmit = () => {
    setShowConfirmationBox(false);
    setDeleteOrg({});
  }

  const onPaginationChange = (pageDetails) => {
    setPageSize(pageDetails.pageSize);
    getAllOrganizations(pageDetails.current, pageDetails.pageSize);
  }

  const confirmDelete = async () => {
    const res = await axios.delete(
      `${domainUrl}/masterservicenew/master/organization/${deleteOrg}`,
      {
        headers: {
          client_code: "ht_front",
          token: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    );
    setShowConfirmationBox(false);
    setDeleteOrg({});
    getAllOrganizations(1, pageSize);
  }

  return (
    <>
      {/* <h2 className="title"> <FormattedMessage id={'organisation'} /></h2> */}
      <Table
        className="ant-table-root"
        // scroll={{ x: 0, y: '40vh' }}
        columns={columns}
        dataSource={organizations}
        pagination={{ defaultPageSize: pageSize, showSizeChanger: false, total: totalCount }}
        onChange={onPaginationChange}
        // style={{height: '80vh'}}
        bordered
        rowKey='organization_id'
        locale={{emptyText: intl.formatMessage({ id:'no_data'})}}
      />
      <div className="button1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="29.701"
          height="29.701"
          viewBox="0 0 29.701 29.701"
        >
          <path
            fill="#fff"
            d="M15.85 1A14.85 14.85 0 1 0 30.7 15.85 14.852 14.852 0 0 0 15.85 1zm6.75 16.2h-5.4v5.4h-2.7v-5.4H9.1v-2.7h5.4V9.1h2.7v5.4h5.4z"
            transform="translate(-1 -1)"
          />
        </svg>
        <span
          onClick={() => openOrganizationForm(null, { readOnly: false })}
        >
          <FormattedMessage id={'addnew'} />
        </span>
      </div>
      {openOrganizationFormModel?.isOpen && (
        <AddOrgModal
          showPopUpModal={openOrganizationFormModel?.isOpen}
          readOnly={openOrganizationFormModel?.isReadOnly}
          data={organization}
          onClose={closeOrganizationForm}
          onSubmit={addOrganization}
        />
      )}
      <ConfirmationBox showConfirmationBox={showConfirmationBox} message={<FormattedMessage id={'sure_to_delete_organization'} /> } confirmSubmit={confirmDelete} declineSubmit={declineSubmit} />
    </>
  );
};

export default Organization;
