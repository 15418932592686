/* eslint-disable no-unused-vars */
import React, { useState, useEffect, forwardRef } from "react";

// import get from 'lodash/get';
import { 
    // useSelector, 
    useDispatch } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import { Table, Rate } from 'antd';
import { 
    // FormattedMessage, 
    useIntl 
} from "react-intl";

import {
    fetchSupplierRatings
} from "../../actions/suppliersRating";
import { compNames, getFilterValues } from '../../utils';

const useStyles = makeStyles((theme) => ({
    favIcon: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    fav: {
        marginRight: 5,
    }
}));

const SupplierRatingList = forwardRef(({ value }, ref) => {
    const intl = useIntl();
        // const supplierRatings = useSelector(state => Object.values(get(state, `entities.supplier_ratings`) || {}).sort(function(a,b){
        //     return new Date(b.created_at) - new Date(a.created_at);
        //   }));

        const classes = useStyles();
        const dispatch = useDispatch();

        const [pageSize, setPageSize] = useState(10);
        const [totalCount, setTotalCount] = useState(1);
        const [supplierRatings, setSupplierRatings] = useState([]);

        useEffect(() => {
            if(value === 2)
                getSupplierRating(1, pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [value]);

        // const mapCompanyIdNames = (assetData, companyData) => {
        // 	assetData.map((asset) => {
        // 		const assetCompany = companyData.find(
        // 			(company) => company.company_id === asset.company_id
        // 		);
        // 		asset.company_name = assetCompany.company_name;
        // 		asset.organization_name = assetCompany.organization_name;
        // 	});
        // 	setAssets(assetData);
        // };

        let columns = [{
                dataIndex: "supplier_name",
                title: intl.formatMessage({ id:'supplier_name'}),
                key: "supplier_name",
                filters: getFilterValues("supplier_name", supplierRatings),
                onFilter: (value, record) => record.supplier_name.indexOf(value) === 0,
                sorter: {
                    compare: (a, b) => {
                    return compNames(a.supplier_name, b.supplier_name);
                    },
                },
            },
            {
                dataIndex:"tender_name",
                title: intl.formatMessage({ id:'tender'}),
                key: "tender_name",
                filters: getFilterValues("tender_name", supplierRatings),
                onFilter: (value, record) => record.tender_name.indexOf(value) === 0,
                sorter: {
                    compare: (a, b) => {
                    return compNames(a.tender_name, b.tender_name);
                    },
                },
            },
            {
                dataIndex:"organization_name",
                title: intl.formatMessage({ id:'organization'}),
                key: "organization_name",
                filters: getFilterValues("organization_name", supplierRatings),
                onFilter: (value, record) => record.organization_name.indexOf(value) === 0,
                sorter: {
                    compare: (a, b) => {
                    return compNames(a.organization_name, b.organization_name);
                    },
                },
            },
            {
                dataIndex:"rating",
                title: intl.formatMessage({ id:'rating'}),
                key: "rating",
                sorter: {
                    compare: (a, b) => {
                    return a.rating - b.rating;
                    },
                },
                render: (value, tableMeta, updateValue) => (
                    <Rate style={{fontSize: '14px'}} allowHalf value={value} disabled/>
                ),
            },
            {
                dataIndex:"comment",
                title: intl.formatMessage({id:'comments'}),
                key: "comment"
            },
            
        ];
        if(sessionStorage.getItem('role_id') === '3'){
            columns = [
            {
                dataIndex:"tender_name",
                title: intl.formatMessage({ id:'tender'}),
                key: "tender_name",
                filters: getFilterValues("tender_name", supplierRatings),
                onFilter: (value, record) => record.tender_name.indexOf(value) === 0,
                sorter: {
                    compare: (a, b) => {
                    return compNames(a.tender_name, b.tender_name);
                    },
                },
            },
            {
                dataIndex:"organization_name",
                title: intl.formatMessage({ id:'organization'}),
                key: "organization_name",
                filters: getFilterValues("organization_name", supplierRatings),
                onFilter: (value, record) => record.organization_name.indexOf(value) === 0,
                sorter: {
                    compare: (a, b) => {
                    return compNames(a.organization_name, b.organization_name);
                    },
                },
            },
            {
                dataIndex:"rating",
                title: intl.formatMessage({ id:'rating'}),
                key: "rating",
                sorter: {
                    compare: (a, b) => {
                    return a.rating - b.rating;
                    },
                },
                render: (value, tableMeta, updateValue) => (
                    <Rate style={{fontSize: '14px'}} allowHalf value={value} disabled/>
                ),
            },
            {
                dataIndex:"comment",
                title: intl.formatMessage({id:'comments'}),
                key: "comment"
            },
            
        ];
        }

        const getSupplierRating = async (page, pageSize) => {
            setSupplierRatings([]);
            const params = {limit: pageSize, offset: pageSize*(page-1), order_by: 'created_at', sort_by: 'DESC'}

            const response = await dispatch(fetchSupplierRatings(params, true));
            const count = response?.payload?.meta?.count;
            setTotalCount(count);
            const supplierData = Object.values(response?.payload?.result);
            setSupplierRatings(supplierData);
        }

        const onPaginationChange = (pageDetails) => {
            setPageSize(pageDetails.pageSize);
            getSupplierRating(pageDetails.current, pageDetails.pageSize);
        }
	return (
		<>
			<Table
                className="ant-table-root"
                // scroll={{ x: 0, y: '40vh' }}
                columns={columns}
                dataSource={supplierRatings}
                pagination={{ defaultPageSize: pageSize, showSizeChanger: false, total: totalCount }}
                onChange={onPaginationChange}
                bordered
                rowKey='supplier_id'
                locale={{emptyText: intl.formatMessage({ id:'no_data'})}}
            />

		</>
	);
});

export default SupplierRatingList;