const domainUrl = "https://app.tenderin.fi";
//const domainUrl = "http://localhost:8001";
const domainUrl2 = "http://localhost:8002";
const domainUrl3 = "http://localhost:8001";
const apiUrl = domainUrl + "/master";
const selfUrl = "http://localhost:3000";

// New Lamda for Prod and QA
const awsDocumentUrl = "https://nhci8qcwy5.execute-api.eu-central-1.amazonaws.com";
const awsAPIUrl = "https://1p0ppjnr3b.execute-api.eu-central-1.amazonaws.com";

// Prod (app.tenderIn.fi)
// const awsDocumentUrl = "https://lskzd5y9fg.execute-api.eu-central-1.amazonaws.com";
// const awsAPIUrl = "https://tkqrdi8iqh.execute-api.eu-central-1.amazonaws.com";

// Dev (3.70.9.132)
// const awsDocumentUrl = "https://2lbpdsdgzk.execute-api.eu-central-1.amazonaws.com";
// const awsAPIUrl = "https://52gm8kbed7.execute-api.eu-central-1.amazonaws.com";
export { domainUrl, apiUrl, selfUrl, awsDocumentUrl, awsAPIUrl,domainUrl2,domainUrl3 };
