/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useRef} from 'react';
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {ArrowLeftOutlined} from '@ant-design/icons';
import { Spin } from 'antd';
import Header from "../../components/Header";
// import InspectionReport from '../../components/InspectionReport';
import { FormattedMessage, useIntl } from "react-intl";
import {
	fetchAsset,
  fetchAssetProperties,
} from "../../actions/assets";

import { fetchCompany } from "../../actions/company";
import { fetchInspectionReport } from "../../actions/inspection-report";
// import { useReactToPrint } from 'react-to-print';
import InspectionReportView from '../../components/InspectionReport/InspectionReportView';


const ViewInspectionReport = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { inspectionId } = useParams(); 
  const [isLoading, setIsLoading] = useState(true);
  const [inspectionReportId, setInspectionReportId ] = useState();
  const [inspectionReportDetails, setInspectionReportDetails] = useState({});
  const [reportDetails, setReportDetails] = useState({});
  const [assetId, setAssetId] = useState();
  const intl = useIntl();
  const reportRef = useRef();
  
  useEffect(() => {
    const fetchReport = async() => {
        const response = await dispatch(fetchInspectionReport(inspectionId));
        const reportData = response.payload?.entities?.inspections[inspectionId] || {};
        setInspectionReportDetails(reportData)
        setAssetId(reportData.asset_id);
        setInspectionReportId(inspectionId);
    }
    fetchReport();

  },[inspectionId]);

  useEffect(() => {
    const getReportDetails = async() => {
      const asset = await dispatch(fetchAsset(assetId));
      const companyId = asset.payload.entities?.assets[assetId].company_id;
      const company = await dispatch(fetchCompany(companyId));
      const report  = (Object.assign(asset.payload.entities.assets[assetId], company.payload.entities.companies[companyId]) );
      setIsLoading(false);
      const response = await dispatch(fetchAssetProperties(1));
      const propertyData = Object.values(
        response.payload.entities.assetProperty
      );
      mapPropertyIdNames(propertyData, asset.payload.entities.assets[assetId], report);
    }
    if(assetId)
		  getReportDetails();
	}, [assetId]);

  const mapPropertyIdNames = (propertyData, asset, report) => {
    let propertiesData = [];
    propertyData.map((prop) => {
      let obj = {};
      obj.property_id = prop.property_id;
      const propSelected = asset.asset_values.find(
        (ele) => ele.property_id === prop.property_id
      );
      if(propSelected ) {
        obj.property_name = prop.property_name;
        obj.property_value = propSelected ? propSelected.property_value : "";
        propertiesData.push(obj);
      }
    });
    const data = {
      ...report,
      ...inspectionReportDetails,
      asset_properties: propertiesData,
      admin_description:intl.formatMessage({id:"Hissitaito Oy on puolueeton hissikonsultointiin erikoistunut yritys, joka tuottaa asiakkailleen valvontapalveluja ja erilaisia yksilöllisiä palveluja asiakkaiden tarpeiden mukaan. Tämä raportti liitteineen on Hissitaito Oy:n näkemys taloyhtiön hissien kunnosta ja arvio seuraavien vuosien kunnostustarpeista. Raporttia ei saa käyttää tarjouspyyntönä. Raportti on tarkoitettu ohjeelliseksi ja avuksi laadittaessa vuosittaisia kunnossapitobudjetteja. Hissien kuntokartoitus olisi hyvä päivittää n. viiden vuoden välein, jotta se pysyy ajan tasalla."}),
    };
    setReportDetails(data);
    setIsLoading(false);

  };
  // const handlePrint = useReactToPrint({
  //   content: () => reportRef.current,
  // });
  

	return (
    <>
      <Header />
      <div className="mainContainer" style={{padding: '20px 0'}}>
      	<div className="innerContainer">
        <ArrowLeftOutlined className="step-back" onClick={() => history.goBack()}/>
        <Spin spinning={isLoading}>
          <h2 className="title"><FormattedMessage id={"Elevator's inspection report"} /></h2>
              {
                Object.values(reportDetails).length > 0 &&
                  <>
                    <InspectionReportView ref={reportRef} reportDetails={reportDetails} inspectionReportId={inspectionReportId} isReadOnly = {true}/>
                  </>
              }
           </Spin>   
        </div>
      </div>
    </>
  );
};
 export default ViewInspectionReport;
