import { RSAA } from 'redux-api-middleware'

export const CUSTOM_AUTHORIZED_REQUEST = "@@authorized_request"

export const apiAuthorizationMiddleware = (store) => (next) => (action) => {
    if (!action[CUSTOM_AUTHORIZED_REQUEST]) {
        return next(action)
    }

    const { [CUSTOM_AUTHORIZED_REQUEST]: request, ...newAction} = action

    const headers = request.headers ? {...request.headers} : {}

    headers['client_code'] = 'ht_front';
    headers['token'] = sessionStorage.getItem("token");
	headers['Content-Type'] = "application/json";

    request.headers = headers

    newAction[RSAA] = request
    return next(newAction)
}

export default CUSTOM_AUTHORIZED_REQUEST